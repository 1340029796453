const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const audioGet = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });
    const resp = await DataService.get(API.soundMaster.audioList + queryString);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const CreateAudio = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.soundMaster.audioAdd, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const SoundDeleteById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.soundMaster.AudioDelete + "/" + id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const updateSound = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.soundMaster.updateSound + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
