import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Modal,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getItem } from "../../../utility/localStorageControl";

import {
  GetGlobal,
  UpdateGlobals,
} from "../../../redux/globalsetting/actionCreator";
import { format } from "date-fns";

const { API } = require("../../../config/api/index");
const GlobalSetting = () => {
  let userdetails = getItem("userDetails");
  const dispatch = useDispatch();
  const [GlobalData, setGlobalData] = useState({});
  const [GlobalId, setGlobalId] = useState({});
  const [form] = Form.useForm();
  useEffect(() => {
    UpdateGlobal();
  }, []);

  const UpdateGlobal = async () => {
    const List = await dispatch(GetGlobal());
    if (List) {
      form.setFieldsValue(List);
      setGlobalData(List);
      setGlobalId(List._id);
    }
  };

  const GlobalSubmit = useCallback((value) => {
    value.id = GlobalId;
    dispatch(UpdateGlobals(value));
  });

  return (
    <>
      <PageHeader ghost title="" />
      <Main>
        <Cards title="Global Setting">
          <Form form={form} onFinish={GlobalSubmit} layout="vertical">
            <Row gutter={10}>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <Form.Item
                  // name="default_time_timer"
                  label="Default Time Timer"
                  name="default_time_timer"
                >
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <Form.Item name="break_time" label="Break Time">
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <Form.Item name="exercise_duration" label="Exercise Duration">
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <Form.Item name="contact_details" label="Contact Details">
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <Form.Item
                  name="referred_user_points"
                  label="Referred User Points"
                >
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <Form.Item
                  name="referrer_user_points"
                  label="Referrer User Points"
                >
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              {userdetails?.permission.resource.globalSetting == "view_all" ? (
                ""
              ) : (
                <Col xxl={12} lg={12} md={12} xs={24}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="default"
                      type="primary"
                      key="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Cards>
      </Main>
    </>
  );
};

export default GlobalSetting;
