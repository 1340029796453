import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Collapse,
  Input,
  Form,
  Popconfirm,
  message,
  List,
  Modal,
} from "antd";

import FeatherIcon from "feather-icons-react";
import {
  Badge,
  FaqCategoryBox,
  FaqSupportBox,
  FaqWrapper,
} from "../../pages/style.js";
import { useDispatch } from "react-redux";

import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main } from "../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Button } from "../../../components/buttons/buttons";

import {
  Faqlist,
  Addfaq,
  Deletefaq,
  getByid,
  Updatedetails,
} from "../../../redux/faq/actionCreator";

const faqList = () => {
  const [isupdateQuestion, setUpdateQuestion] = useState(false);
  let [details, setDetails] = useState();
  const dispatch = useDispatch();
  const [faqStore, setFaqStore] = useState([]);
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const customPanelStyle = {
    background: "#ffffff",
    borderRadius: 5,
    marginBottom: 5,
    border: "1px solid #F1F2F6",
  };
  const handleChange = (e) => {
    e.preventDefault();
    e.target
      .closest("ul")
      .querySelector("a.active")
      .classList.remove("active");
    e.target.classList.add("active");
  };

  const [question, setQuestion] = useState({
    question: "",
  });
  const [answer, setanswer] = useState({
    answer: "",
  });

  function cancel(e) {
    console.log(e);
  }

  // faq list api
  useEffect(() => {
    listfaq();
  }, []);
  const listfaq = async () => {
    // alert("hello");
    const List = await dispatch(Faqlist());
    if (List) {
      setFaqStore(List);
    }
  };
  // get faq api
  const getByIdtoDetails = async (id) => {
    const updatedata = await dispatch(getByid(id));
    if (updatedata) {
      setDetails(updatedata);
      setQuestion({ question: updatedata.question });
      setanswer({ setanswer: updatedata.answer });
      form1.setFieldsValue({
        question: updatedata.question,
        answer: updatedata.answer,
      });
      // setFaqStore(List);
    }
  };
  // update faq api
  const onUpdatefaq = async (value) => {
    const updatesucces = await dispatch(Updatedetails(details._id, value));
    if (updatesucces) {
      // setFaqStore(List);
      listfaq();
      handleCancel();
    }
  };
  const showModal = (id) => {
    getByIdtoDetails(id);
    setUpdateQuestion(true);
  };

  const handleCancel = () => {
    setUpdateQuestion(false);
  };

  // add question answer api
  const onChangeQuestion = (e) => {
    setQuestion(e.target.value);
  };

  const onChangeAnswer = (e) => {
    setanswer(e.target.value);
  };

  const onSubmit = async (value) => {
    const create = await dispatch(Addfaq(value));
    if (create) {
      // setFaqStore(List);
      listfaq();
      form.resetFields();
    }
  };

  // Deletelist api
  const Deletelist = async (id) => {
    const deleted = await dispatch(Deletefaq(id));
    if (deleted) {
      listfaq();
    }
  };

  return (
    <>
      <PageHeader ghost title="FAQ" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} md={24}>
            <Cards title="Add Your Question">
              <Form onFinish={onSubmit} form={form}>
                <Form.Item
                  name="question"
                  value={question.question}
                  onChange={onChangeQuestion}
                  rules={[
                    { required: true, message: "Please input your Question" },
                  ]}
                >
                  <Input placeholder="Question" />
                </Form.Item>
                <Form.Item
                  name="answer"
                  value={answer}
                  onChange={onChangeAnswer}
                  rules={[
                    { required: true, message: "Please input your Answer" },
                  ]}
                >
                  <TextArea rows={4} placeholder="Answer" />
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Cards>
          </Col>
        </Row>

        <Row gutter={25}>
          <Col xxl={24} lg={24} md={24}>
            <FaqWrapper>
              <Cards headless>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <FeatherIcon icon={isActive ? "minus" : "plus"} size={14} />
                  )}
                >
                  {faqStore && faqStore.length > 0
                    ? faqStore.map((value) => {
                        return (
                          <Panel
                            header={value.question}
                            key={value._id}
                            style={customPanelStyle}
                          >
                            <p>{value.answer}</p>

                            <div className="panel-actions">
                              <Button
                                outlined
                                type="success"
                                onClick={() => showModal(value._id)}
                              >
                                <FeatherIcon size={14} icon="edit" />
                                Edit
                              </Button>

                              <Popconfirm
                                outlined
                                type="warning"
                                title="Are you sure to delete this task?"
                                // onConfirm={confirm}
                                onCancel={cancel}
                                onConfirm={() => Deletelist(value._id)}
                                okText="Yes"
                                cancelText="No"
                                className="faq_delete"
                              >
                                <a href="#">
                                  {" "}
                                  <FeatherIcon size={14} icon="trash-2" />{" "}
                                  Delete
                                </a>
                              </Popconfirm>
                            </div>
                          </Panel>
                        );
                      })
                    : ""}
                </Collapse>
              </Cards>
            </FaqWrapper>
          </Col>
        </Row>
      </Main>
      <Modal
        title="Edit Question"
        visible={isupdateQuestion}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form onFinish={onUpdatefaq} form={form1}>
          <Form.Item
            name="question"
            onChange={onChangeQuestion}
            rules={[{ required: true, message: "Please input your Question" }]}
          >
            <Input placeholder="Question" />
          </Form.Item>
          <Form.Item
            name="answer"
            onChange={onChangeAnswer}
            rules={[{ required: true, message: "Please input your Question" }]}
          >
            <TextArea rows={4} placeholder="Answer" />
          </Form.Item>

          <Form.Item>
            <div className="setting-form-actions">
              <Button
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                htmlType="submit"
                size="default"
                type="dashed"
                key="submit"
                onClick={handleCancel}
              >
                cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default faqList;
