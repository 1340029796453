const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const pointlist = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.rewardsmaster.rewardslist);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const rewardaddpoint = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.rewardsmaster.rewardsadd, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const Update = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.rewardsmaster.updatereward + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const deleterewardsdata = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.rewardsmaster.deleterewards + "/" + id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};