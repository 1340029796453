import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Select,
  Input,
  message,
  Table,
  Modal,
  Button,
  Form,
  Popconfirm,
} from "antd";
import {
  List,
  nonactiveAdd,
  userDeleteId,
  userUpdateId,
} from "../../../../redux/ContentManagment/actionCreator";
import { Cards } from "../../../../components/cards/frame/cards-frame";
import { Main } from "../../../AdminRole/styled";
import { PageHeader } from "antd";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import useStateRef from "react-usestateref";
import { getItem } from "../../../../utility/localStorageControl";
import ReactAudioPlayer from "react-audio-player";
//main function call
const nonActivity = () => {
  let userdetails = getItem("userDetails");
  //define states
  const [Duration, setDuration] = useState();
  const [userList, setUserList] = useState([]);
  const [previewMedia, setPreviewMedia] = useState("");
  const [previewEditMedia, setPreviewEditMedia] = useState("");
  const [type, setType] = useState();
  const [content, setContent] = useState();
  const [updateId, setUpdateId] = useState("");
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [storeMenu, setMenu] = useState({});
  const [selectedFile, setSelectedFile, setSelectedFileRef] = useStateRef("");
  const [
    selectedEditFile,
    setSelectedEditFile,
    setSelectedEditFileRef,
  ] = useStateRef("");
  const [visible, setVisible] = useState(false);
  const { Option } = Select;
  const dispatch = useDispatch();

  const [updateData, setUpdateData] = useState("");
  //useEffect Table data show
  useEffect(() => {
    tableData();
  }, []);

  //table api call
  const tableData = async () => {
    const tableList = await dispatch(List({ type: "nonActivity" }));
    if (tableList) {
      setUserList(tableList);
    }
  };

  //Delete api Call
  const deleteApiData = async (id) => {
    const tableList = await dispatch(userDeleteId(id));
    if (tableList) {
      tableData();
    }
  };

  //open popup det api and data
  const update = (e) => {
    form1.resetFields();
    setType(e.type);
    setContent(e.content);
    setUpdateId(e._id);
    setVisible(true);
    form1.setFieldsValue({
      type: e.type,
    });
    setUpdateData(e);
  };

  //delete confirmation cancel function
  const cancel = (e) => {
    form.resetFields();
  };

  const computeLength = (file) => {
    return new Promise((resolve) => {
      var objectURL = URL.createObjectURL(file);
      var mySound = new Audio([objectURL]);
      mySound.addEventListener(
        "canplaythrough",
        () => {
          URL.revokeObjectURL(objectURL);
          resolve({
            file,
            duration: mySound.duration,
          });
        },
        false
      );
    });
  };
  const imageChange = async (event) => {
    event.persist();
    let checkLength = await computeLength(event.target.files[0]);
    if (checkLength.duration > 60) {
      message.error("Upload Audio size max 1 min");
      form.resetFields();
      setSelectedFile("");
    } else {
      message.success("Audio Upload");
      setSelectedFile(event.target.files[0]);
    }
  };

  //image Edit change function
  const imageEditChange = (event) => {
    if (event.target.files[0].type.includes("audio/mpeg")) {
      if (updateData.type === "Sos") {
        computeLength(event.target.files[0]).then((val) => {
          if (val.duration >= 60) {
            form1.setFieldsValue({ content: "" });
            // setVisible(false)
            form.resetFields();
            message.error("Upload Audio size max 1 min");
          } else {
            message.success("Audio Upload");
          }
        });
      }
      setPreviewEditMedia(
        <audio style={{ width: "100%" }} controls>
          <source src={URL.createObjectURL(event.target.files[0])} />{" "}
        </audio>
      );
      setSelectedEditFile(event.target.files[0]);
    } else {
      form1.setFieldsValue({ audio: "" });
      message.error("You can only upload Audio file!");
    }
  };
  //form submit function
  const submitForm = async (values) => {
    let foundValue = false;
    if (values.type === "Introduction") {
      foundValue = userList.find((obj) => obj.type === "Introduction");
    }
    if (foundValue) {
      message.error(`Introduction already stored please choice other`);
      setPreviewEditMedia("");
      setVisible(false);
      form.resetFields();
    } else {
      if (values.content) {
        delete values.content;
      }
      var form_data = new FormData();
      if (setSelectedFileRef.current != "") {
        form_data.append("content", setSelectedFileRef.current);
      }
      for (var key in values) {
        form_data.append(key, values[key]);
      }
      for (var pair of form_data.entries()) {
      }
      let create = await dispatch(nonactiveAdd(form_data));
      if (create) {
        setPreviewMedia("");
        tableData();
      }
    }

    form.resetFields();
  };

  //menu change data show
  const changeStoreMenu = (value) => {
    if (value == "Sos") {
      const duration = 60;
      setDuration(duration);
    }
  };
  //form submit function
  const submitEditForm = async (values) => {
    let foundValue = false;
    if (values.type === "Introduction") {
      foundValue = userList.find((obj) => obj.type === "Introduction");
    }
    if (foundValue) {
      message.error(`Introduction already stored please choice other`);
      setPreviewEditMedia("");
      setVisible(false);
      form.resetFields();
    } else {
      if (values.content) {
        delete values.content;
      }
      var form_data = new FormData();
      if (setSelectedEditFileRef.current != "") {
        form_data.append("content", setSelectedEditFileRef.current);
      }
      for (var key in values) {
        form_data.append(key, values[key]);
      }
      let create = await dispatch(userUpdateId(form_data, updateId));
      if (create) {
        setPreviewEditMedia("");
        tableData();
      }
      setVisible(false);
    }
  };

  //table column define
  const columns = [
    {
      title: "Title",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Audio",
      key: "content",
      // dataIndex: "content",
      render: (key, value) => (
        <>
          <div>
            {value && value.content !== undefined ? (
              // <audio controls crossOrigin="anonymous">
              //   <source src={value.content} />
              // </audio>

              <ReactAudioPlayer src={value.content} controls />
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    userdetails?.permission.resource.contentManagement == "view_all"
      ? {}
      : {
        title: "Action",
        key: "action",
        render: (key) => (
          <div className="table-actions">
            <>
              <a
                className="btn-icon"
                to="#"
                shape="circle"
                onClick={() => update(key)}
              >
                <FeatherIcon icon="edit" size={16} />
              </a>
              <Popconfirm
                outlined
                type="warning"
                title="Are you sure to delete this List ?"
                onCancel={cancel}
                onConfirm={() => deleteApiData(key._id)}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">
                  {" "}
                  <FeatherIcon size={14} icon="trash-2" />
                </a>
              </Popconfirm>
            </>
          </div>
        ),
      },
  ];

  //main function return
  return (
    <>
      {/* edit popup */}
      <Modal
        title="Update Content"
        centered
        visible={visible}
        onCancel={() => {
          form1.resetFields();
          setVisible(false);
        }}
        footer={false}
      >
        {/* edit form */}
        <Form
          layout="vertical"
          initialValues={{ type: type }}
          onFinish={submitEditForm}
          form={form1}
          autoComplete="off"
        >
          <Row gutter={25}>
            <Col xl={12} lg={12} xs={24}>
            </Col>
            <Col xl={24} lg={24} xs={24}>
              <Form.Item
                label="Select Audio"
                rules={[
                  { required: true, message: "Please select your Audio!" },
                ]}
                name="content"
              >
                <Input
                  accept="audio/*"
                  name="file"
                  type="file"
                  placeholder="Image"
                  title=""
                  id="content"
                  onChange={imageEditChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={25}>
            <Col xl={12} lg={12} xs={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
            {previewEditMedia != "" && (
              <Col xl={12} lg={12} xs={24}>
                <Form.Item>
                  <div className="img_prev_game">{previewEditMedia}</div>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      <PageHeader title="Non Activity Content " />
      <Main>
        <Row gutter={25}>
          {userdetails?.permission.resource.contentManagement == "view_all" ? (
            ""
          ) : (
            <Col xl={24} lg={24} xs={24}>
              <Cards>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={submitForm}
                  autoComplete="off"
                >
                  <Row gutter={25}>
                    <Col xl={12} lg={12} xs={24}>
                      <Form.Item
                        name="type"
                        label="Select"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your country!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select a Content"
                          onChange={changeStoreMenu}
                        >
                          <Option value="Sos">Sos</Option>
                          <Option value="Introduction">Introduction</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} xs={24}>
                      <Form.Item
                        label="Select Audio"
                        name="content"
                        rules={[
                          {
                            required: true,
                            message: "Please select your Audio!",
                          },
                        ]}
                      >
                        <Input
                          id="content"
                          type="file"
                          accept="audio/*"
                          name="file"
                          onChange={(e) => imageChange(e)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={25}>
                    <Col xl={12} lg={12} xs={24}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                    {previewMedia != "" && (
                      <Col xl={12} lg={12} xs={24}>
                        <Form.Item>
                          <div className="img_prev_game">{previewMedia}</div>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Form>
              </Cards>
            </Col>
          )}
        </Row>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards title="Non Activity List">
              <div className="table-order table-responsive">
                <Table
                  dataSource={userList}
                  columns={columns}
                  pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "50", "100", "500", "1000"],
                  }}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default nonActivity;
