import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Modal,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import {
  interestGet,
  createInterest,
  interestDelete,
  updateInterest,
} from "../../../redux/interest/actionCreator";
import { format } from "date-fns";

const { API } = require("../../../config/api/index");
const interest = () => {
  const dispatch = useDispatch();
  const [interestDatalist, setSkillDatalist] = useState([]);
  const [interestId, setSkillId] = useState();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [interestEditModel, setInterestEditModel] = useState(false);

  useEffect(() => {
    interestTaBelList();
  }, []);
  const showSkillModal = (id, value) => {
    setSkillId(id);
    setInterestEditModel(true);
    form1.setFieldsValue({
      interestName: value,
    });
  };

  const handleOk = async (value) => {
    const update = await dispatch(updateInterest(interestId, value));
    if (update) {
      interestTaBelList();
    }
    setInterestEditModel(false);
  };
  const handleCancel = () => {
    setInterestEditModel(false);
  };

  // Get Url api

  const interestTaBelList = async () => {
    const List = await dispatch(interestGet());
    if (List) {
      setSkillDatalist(List);
    }
  };

  const onsubmitSkill = async (value) => {
    let create = await dispatch(createInterest(value));
    if (create) {
      interestTaBelList();
    }
    form.resetFields();
  };

  // DeleteList api
  const DeleteList = async (id) => {
    const deleted = await dispatch(interestDelete(id));
    if (deleted) {
      interestTaBelList();
    }
  };

  const columns = [
    {
      title: "Interest Name",
      dataIndex: "interestName",
      key: "interestName",
      width: "80%",
    },
    {
      title: "Action",
      key: "action",
      render: (value) => (
        <div className="table-actions">
          <>
            <a
              className="btn-icon"
              to="#"
              shape="circle"
              onClick={() => showSkillModal(value._id, value.interestName)}
            >
              <FeatherIcon icon="edit" size={16} />
            </a>
            <Popconfirm
              outlined
              type="warning"
              title="Are you sure to delete this List ?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => DeleteList(value._id)}
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ];
  return (
    <>
      <PageHeader ghost title="Interest List" />
      <Main>
        <Row gutter={10}>
          <Col xxl={24} lg={24} md={24} xs={24}>
            <Cards title="Add Interest List">
              <Form onFinish={onsubmitSkill} form={form}>
                <Form.Item
                  name="interestName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Interest List",
                    },
                  ]}
                >
                  <Input placeholder="Add Interest List" />
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Add
                  </Button>
                </Form.Item>
              </Form>
            </Cards>
          </Col>
        </Row>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Table
                  dataSource={interestDatalist}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          title="Interest Update"
          visible={interestEditModel}
          onCancel={handleCancel}
          okText="Update"
          onOk={form1.submit}
        >
          <Form form={form1} onFinish={handleOk}>
            <Form.Item name="interestName">
              <Input placeholder="Add Links" />
            </Form.Item>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default interest;
