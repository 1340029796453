import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  DatePicker,
  Modal,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { referralCodeList } from "../../../redux/usershours/actionCreator";
import { format } from "date-fns";
import { Excel } from "antd-table-saveas-excel";
import moment from "moment";

const { API } = require("../../../config/api/index");
const referralCode = () => {
  const dispatch = useDispatch();
  const [usersHoursList, setUsersHours] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { RangePicker } = DatePicker;
  useEffect(() => {
    userDataHours();
  }, []);

  // Get Url api

  const renderContent = (value, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index === 4) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const userDataHours = async () => {
    const query = {
      startDate: startDate,
      endDate: endDate,
    };
    const List = await dispatch(referralCodeList(query));
    if (List) {
      setUsersHours(List);
    }
  };

  const startDateFilter = async (date) => {
    let abc = moment(date).format("MM.DD.YYYY");
    setStartDate(abc);
  };
  const endDateFilter = async (date) => {
    let abc = moment(date).format("MM.DD.YYYY");
    setEndDate(abc);
  };

  const onClick = async () => {
    const query = {
      startDate: startDate,
      endDate: endDate,
    };
    const List = await dispatch(referralCodeList(query));
    if (List) {
      setUsersHours(List);
    }
  };
  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username),
      render: renderContent,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: renderContent,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,
      render: renderContent,
    },
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
      sorter: (a, b) => a.referral_code.localeCompare(b.referral_code),
      render: renderContent,
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      sorter: (a, b) => a.count - b.count,
      render: renderContent,
    },
  ];

  return (
    <>
      <PageHeader ghost title="Referral Code List" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Row gutter={10} align="middle" style={{ marginBottom: 10 }}>
                  <Col xxl={8} lg={8} xs={24}>
                    <DatePicker
                      placeholder="Select Start Date"
                      onChange={startDateFilter}
                    />
                  </Col>
                  <Col xxl={8} lg={8} xs={24}>
                    <DatePicker
                      placeholder="Select End Date"
                      onChange={endDateFilter}
                    />
                  </Col>
                  <Col xxl={4} lg={4} xs={24}>
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        padding: "12px",
                        height: "inherit",
                      }}
                      htmlType="submit"
                      onClick={onClick}
                    >
                      Filter Data
                    </Button>
                  </Col>
                  <Col xxl={4} lg={4} xs={24}>
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        padding: "12px",
                        height: "inherit",
                      }}
                      onClick={() => {
                        const excel = new Excel();
                        excel
                          .addSheet("test")
                          .addColumns(columns)
                          .addDataSource(usersHoursList)
                          .saveAs("file.xlsx");
                      }}
                    >
                      Export Excel
                    </Button>
                  </Col>
                </Row>
                <Table
                  dataSource={usersHoursList}
                  pagination={true}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default referralCode;
