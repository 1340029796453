import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox, Radio, Button, Form, Table, Select } from "antd";
// import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
// import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPermissionById } from "../../../redux/permission/actionCreator";
import { format } from "date-fns";
import { UpdatePermission } from "../../../redux/permission/actionCreator";
const { API } = require("../../../config/api/index");

const permission = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { role_id } = useParams();
  const [adminPermissionDetails, setAdminPermissionDetails] = useState({});

  useEffect(() => {
    roleManagementButton();
  }, []);

  const roleManagementButton = async () => {
    const GetPermission = await dispatch(getPermissionById(role_id));
    setAdminPermissionDetails(GetPermission);
  };

  const onSubmit = async (value) => {
    let payload = {
      resource: value,
    };
    let Update = await dispatch(
      UpdatePermission(adminPermissionDetails._id, payload)
    );
    if (Update) {
      roleManagementButton();
    }
  };

  return (
    <>
      <PageHeader ghost title="" />
      <Main>
        <Form form={form} onFinish={onSubmit}>
          <Cards title="Permission Setting">
            <br></br>
            <div>
              {Object.keys(
                adminPermissionDetails && adminPermissionDetails.resource
                  ? adminPermissionDetails.resource
                  : ""
              ).map((key, i) => (
                <div className="mainBorder_perm">
                  <Row gutter={10} className="border_perm">
                    <Col xxl={6} lg={6} md={6} xs={24}>
                      <Form.Item className="per_name mb_pert">{key}</Form.Item>
                    </Col>
                    <Col xxl={18} lg={18} md={18} xs={24}>
                      <Form.Item
                        className="mb_pert rightMb_part"
                        name={key}
                        initialValue={adminPermissionDetails.resource[key]}
                      >
                        <Radio.Group
                          defaultValue={adminPermissionDetails.resource[key]}
                          name={key}
                        >
                          <Radio value="view_all">View Only</Radio>
                          <Radio value="all_access">Access All</Radio>
                          <Radio value="none">None</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>

            <Col xxl={12} lg={12} md={12} xs={24}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  size="default"
                  type="primary"
                  key="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Cards>
        </Form>
      </Main>
    </>
  );
};

export default permission;
