const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const permissionList = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.permission.permissionGet);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const getPermissionById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.permission.getPermissionById + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const UpdatePermission = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.permission.UpdatePermission + "/" + id, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};