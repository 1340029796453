const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import actions from "./actions";
import { message } from "antd";

const { testListSuccess } = actions;

export const QuestionList = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.testManagement.questionList);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const QuestionAdd = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.testManagement.addQuestion,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const DeleteList = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.testManagement.deleteQuestion + "/" + id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const updateQuestion = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.testManagement.questionUpdate + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};