import React, { useState, useEffect } from "react";
import { Row, Col, Table, Input, Avatar, Button, Switch } from "antd";
import FeatherIcon from "feather-icons-react";
import {
  NavLink,
  useRouteMatch,
  useParams,
  useHistory,
  Link,
  Route,
} from "react-router-dom";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { CardToolbox, Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../../utility/localStorageControl";
import {
  blockedUsers,
  updateUserStatus,
} from "../../../redux/admin/actionCreator";
import {
  getRandomColor,
  GetOnlyDateFormat,
} from "../../../utility/commonFunction";

const InactiveUserList = () => {
  let history = useHistory();

  let userdetails = getItem("userDetails");
  const { Search } = Input;
  const { path } = useRouteMatch();
  const [searchValue, setSearchValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    userList();
  }, []);

  let blockedUsersList = useSelector((state) => state.Admin.blockedUsers);

  const userList = async () => {
    let payload = { role: "user", status: "inactive" };
    await dispatch(blockedUsers(payload));
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  blockedUsersList =
    blockedUsersList && blockedUsersList.length > 0
      ? blockedUsersList.filter((item) =>
          item && item.first_name
            ? item.first_name.toLowerCase().includes(searchValue.toLowerCase())
            : "" || (item && item.last_name)
            ? item.last_name.toLowerCase().includes(searchValue.toLowerCase())
            : "" || (item && item.username)
            ? item.username.toLowerCase().includes(searchValue.toLowerCase())
            : "" || (item && item.email)
            ? item.email.toLowerCase().includes(searchValue.toLowerCase())
            : ""
        )
      : "";

  const onChange = async (value) => {
    let payload = {
      status: "active",
    };
    let update = await dispatch(updateUserStatus(value, payload));
    if (update) {
      userList();
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    setDefaultPageSize(pageSize);
  };

  const columns = [
    {
      title: "Profile Picture",
      dataIndex: "profile_pic",
      key: "profile_pic",
      render: (logo, record) => {
        if (record.profile_pic != "") {
          return (
            <Avatar
              size="large"
              crossOrigin="anonymous"
              src={record.profile_pic}
            />
          );
        } else {
          return (
            <Avatar
              style={{
                backgroundColor: getRandomColor(),
                verticalAlign: "middle",
              }}
              size="large"
              icon=""
            ></Avatar>
          );
        }
      },
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },

    {
      title: "Registration Date",
      dataIndex: "created_at",
      render: (key, value) => (
        <>
          <span>
            {value && value.created_at
              ? GetOnlyDateFormat(value.created_at)
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      render: (key, value) => (
        <>{`${value.countryDetails.countryCode} ${value.mobile}`}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // userdetails?.permission.resource.inactiveUserList == "view_all"
    //   ? {}
    //   : {
    //       title: "Update Status",
    //       key: "action",
    //       render: (text, record) => (
    //         <Switch
    //           unCheckedChildren="Active"
    //           checkedChildren="Active"
    //           onChange={() => onChange(record._id)}
    //         />
    //       ),
    //     },
  ];

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            title={`Inactive Users List`}
            subTitle={
              <>
                <span
                  className="title-counter"
                  style={{ paddingRight: "20px" }}
                >
                  {blockedUsersList.length} Total
                </span>
                <div className="table-search-box">
                  <Search
                    className="custom-search"
                    placeholder="Search"
                    onChange={(e) => onSearch(e)}
                  />
                </div>
              </>
            }
          />
        </CardToolbox>

        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards headless>
              <div className="table-order table-responsive">
                <Table
                  dataSource={blockedUsersList}
                  columns={columns}
                  onRow={(r) => ({
                    onClick: () => window.open(`${path}/${r._id}`),
                  })}
                  pagination={{
                    pageSize: defaultPageSize,
                    showSizeChanger: true,
                    total: blockedUsersList.length,
                    onShowSizeChange: onShowSizeChange,
                  }}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default InactiveUserList;
