import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Modal,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../MasterAdmin/styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getItem } from "../../../utility/localStorageControl";

import {
  skillget,
  createSkill,
  skillDelete,
  updateskill,
} from "../../../redux/skill/actionCreator";
import { format } from "date-fns";

const { API } = require("../../../config/api/index");
const skill = () => {
  let userdetails = getItem("userDetails");
  const dispatch = useDispatch();
  const [skilldatalist, setskillDatalist] = useState([]);
  const [skillId, setSkillId] = useState();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [skilleditmodel, setskillEditmodel] = useState(false);

  useEffect(() => {
    skilltabellist();
  }, []);
  const showSkillModal = (id, value) => {
    setSkillId(id);
    setskillEditmodel(true);
    form1.setFieldsValue({
      skillName: value,
    });
  };

  const handleOk = async (value) => {
    const update = await dispatch(updateskill(skillId, value));
    if (update) {
      skilltabellist();
    }
    setskillEditmodel(false);
  };
  const handlecancel = () => {
    setskillEditmodel(false);
  };

  // Get Url api

  const skilltabellist = async () => {
    const List = await dispatch(skillget());
    if (List) {
      setskillDatalist(List);
    }
  };

  const onsubmitskill = async (value) => {
    let create = await dispatch(createSkill(value));
    if (create) {
      skilltabellist();
    }
    form.resetFields();
  };

  // Deletelist api
  const Deletelist = async (id) => {
    const deleted = await dispatch(skillDelete(id));
    if (deleted) {
      skilltabellist();
    }
  };

  const columns = [
    {
      title: "Skill Name",
      dataIndex: "skillName",
      key: "skillName",
      width: "80%",
    },
    userdetails?.permission.resource.skills == "view_all"
      ? {}
      : {
          title: "Action",
          key: "action",
          render: (value) => (
            <div className="table-actions">
              <>
                <a
                  className="btn-icon"
                  to="#"
                  shape="circle"
                  onClick={() => showSkillModal(value._id, value.skillName)}
                >
                  <FeatherIcon icon="edit" size={16} />
                </a>
                <Popconfirm
                  outlined
                  type="warning"
                  title="Are you sure to delete this List ?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => Deletelist(value._id)}
                >
                  <a href="#">
                    {" "}
                    <FeatherIcon size={14} icon="trash-2" />
                  </a>
                </Popconfirm>
              </>
            </div>
          ),
        },
  ];
  return (
    <>
      <PageHeader ghost title="Skill List" />
      <Main>
        {userdetails?.permission.resource.skills == "view_all" ? (
          ""
        ) : (
          <>
            <Row gutter={10}>
              <Col xxl={24} lg={24} md={24} xs={24}>
                <Cards title="Add Your Skill">
                  <Form onFinish={onsubmitskill} form={form}>
                    <Form.Item
                      name="skillName"
                      rules={[
                        { required: true, message: "Please input your Skill" },
                      ]}
                    >
                      <Input placeholder="Add Links" />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        size="default"
                        type="primary"
                        key="submit"
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </Form>
                </Cards>
              </Col>
            </Row>
          </>
        )}

        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Table
                  dataSource={skilldatalist}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          title="Skill Update"
          visible={skilleditmodel}
          onCancel={handlecancel}
          okText="Update"
          onOk={form1.submit}
        >
          <Form form={form1} onFinish={handleOk}>
            <Form.Item name="skillName">
              <Input placeholder="Add Links" />
            </Form.Item>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default skill;
