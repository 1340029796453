import moment from "moment";

export const getDateFormate = (date) => {
  return moment(date).format("YYYY.MM.DD / HH:MM");
};

export const GetOnlyDateFormat = (date) => {
  return moment(date).format("YYYY.MM.DD");
};

export const GetOnlyTimeFormat = (date) => {
  return moment(date).format("HH:MM");
};

export const truncate = (input, string = 20) => {
  if (input.length > string) {
    return input.substring(0, string) + "...";
  }
  return input;
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const GetMinutes = (min) => {
  console.log("min : ", min);
  if (min > 60) {
    let minutes = min / 60;
    return Math.round(minutes);
  }
  return min;
};
