import Cookies from "js-cookie";
import actions from "./actions";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { setItem, removeItem } from "../../utility/localStorageControl";

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = (payloads) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const loggedIn = await DataService.post(API.auth.login, payloads);
      if (!loggedIn.data.error) {
        setItem("access_token", loggedIn.data.data.token);
        setItem("userDetails", loggedIn.data.data);
        Cookies.set("logedIn", true);
        dispatch(loginSuccess(true));
        window.location.reload();
      } else {
        dispatch(loginErr(loggedIn));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const changePassword = (payloads) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.auth.changePassword, payloads);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    const resp = await DataService.post(API.auth.logout);
    if (!resp.data.error) {
      dispatch(logoutBegin());
      removeItem("access_token");
      Cookies.remove("logedIn");
      dispatch(logoutSuccess(null));
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

export { login, logOut, changePassword };
