const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";
import action from "./action";
const { updateUser } = action;

//activity page list api call
export const ActivityList = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.contentmyactivity.activitylist);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

//active page table data api call
export const List = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });
    const resp = await DataService.get(
      API.contentmyactivity.getactivitydata + queryString
    );
    if (!resp.data.error) {
      dispatch(updateUser(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

//add user data non active api call
export const nonactiveAdd = (data) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.contentmyactivity.Create, data);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

// delete user api call
export const userDeleteId = (_id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.contentmyactivity.dataDelete + "/" + _id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

//update user api call
export const userUpdateId = (_id, data) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.contentmyactivity.dataUpdate + "/" + data,
      _id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

//mood list api call
export const userMoodId = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.addmood.moodlist);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
