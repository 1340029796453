import React from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { getItem } from "../../../utility/localStorageControl";

const userDetails = getItem("userDetails");

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  // const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );
  const onOpenChange = (keys) => {
    setOpenKeys([keys.length && keys[keys.length - 1]]);
  };
  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const { SubMenu } = Menu;
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1
              ? "home"
              : mainPathSplit.length === 2
                ? mainPathSplit[1]
                : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        icon={!topMenu && <FeatherIcon icon="activity" />}
        key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      <SubMenu
        key="products"
        icon={!topMenu && <FeatherIcon icon="user" />}
        title="User Management"
      >
        <Menu.Item key="userlist">
          <NavLink to={`${path}/usermanagment`}>User List </NavLink>
        </Menu.Item>
        <Menu.Item key="userfeedback">
          <NavLink to={`${path}/userfeedback`}>User Feedback List</NavLink>
        </Menu.Item>
        <Menu.Item key="letitallout">
          <NavLink to={`${path}/letitallout`}>Let It All Out</NavLink>
        </Menu.Item>
        <Menu.Item key="blockuser">
          <NavLink to={`${path}/blocked_users`}>Block Users List </NavLink>
        </Menu.Item>
        <Menu.Item key="inactive">
          <NavLink to={`${path}/inactiveuserlist`}>Inactive User List </NavLink>
        </Menu.Item>
        <Menu.Item key="deletuser">
          <NavLink to={`${path}/deleteuserlist`}>Delete User</NavLink>
        </Menu.Item>
        <Menu.Item key="userredeem">
          <NavLink to={`${path}/userredeem`}>User Redeem </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Admin"
        icon={!topMenu && <FeatherIcon icon="user" />}
        title="Admin Management"
      >
        <Menu.Item key="adminManagement">
          <NavLink to={`${path}/adminrolemanagement`}>Admin List</NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="contentmanagement"
        icon={!topMenu && <FeatherIcon icon="book" />}
        title="Content Management"
      >
        <Menu.Item key="activity">
          <NavLink to={`${path}/activity`}>Activity </NavLink>
        </Menu.Item>
        <Menu.Item key="nonactivity">
          <NavLink to={`${path}/nonactivity`}>Non Activity </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Subscriptions"
        icon={!topMenu && <FeatherIcon icon="dollar-sign" />}
        title="Subscription Settings"
      >
        <Menu.Item key="subscription">
          <NavLink to={`${path}/subscription`}>Add Subscription </NavLink>
        </Menu.Item>
        <Menu.Item key="listusersubscription">
          <NavLink to={`${path}/listusersubscription`}>
            Subscription User List
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="examsetting"
        icon={!topMenu && <FeatherIcon icon="book" />}
        title="Exam Setting"
      >
        <Menu.Item key="testmanagement">
          <NavLink onClick={toggleCollapsed} to={`${path}/testmanagement`}>
            Test Management
          </NavLink>
        </Menu.Item>

        <Menu.Item key="questiongroup">
          <NavLink onClick={toggleCollapsed} to={`${path}/questiongroup`}>
            Question Group
          </NavLink>
        </Menu.Item>

        <Menu.Item key="competitiveexam">
          <NavLink onClick={toggleCollapsed} to={`${path}/competitiveexam`}>
            Competitive Exam
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="Global"
        icon={!topMenu && <FeatherIcon icon="tag" />}
        title="Global Settings"
      >
        <Menu.Item key="globalsetting">
          <NavLink to={`${path}/globalsetting`}>Global Setting</NavLink>
        </Menu.Item>
        <Menu.Item key="soundmaster">
          <NavLink to={`${path}/soundmaster`}>Sound Master</NavLink>
        </Menu.Item>

        <Menu.Item key="faq">
          <NavLink to={`${path}/faqlist`}>Faq Setting</NavLink>
        </Menu.Item>

        <Menu.Item key="feedbackmaster">
          <NavLink to={`${path}/feedbackmaster`}>Feedback Setting</NavLink>
        </Menu.Item>

        <Menu.Item key="broadcast">
          <NavLink to={`${path}/broadcast`}>Broadcast</NavLink>
        </Menu.Item>

        <Menu.Item key="redeem">
          <NavLink to={`${path}/redeem`}>Redeem Setting</NavLink>
        </Menu.Item>

        <Menu.Item key="exercisemaster">
          <NavLink to={`${path}/exercisemaster`}>Exercise Setting</NavLink>
        </Menu.Item>

        <Menu.Item key="interest">
          <NavLink to={`${path}/interest`}>Interest Setting</NavLink>
        </Menu.Item>

        <Menu.Item key="skill">
          <NavLink to={`${path}/skill`}>Skill Setting</NavLink>
        </Menu.Item>

        <Menu.Item key="rewardsmaster">
          <NavLink to={`${path}/rewardsmaster`}>Rewardsmaster Setting</NavLink>
        </Menu.Item>

        <Menu.Item key="mood">
          <NavLink to={`${path}/mood`}>Mood Setting</NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="setting"
        icon={!topMenu && <FeatherIcon icon="settings" />}
        title="Settings"
      >
        <Menu.Item key="myprofile">
          <NavLink to={`${path}/myprofile`}>My Profile </NavLink>
        </Menu.Item>
        <Menu.Item key="myactivity">
          <NavLink to={`${path}/myactivity`}>My Activity </NavLink>
        </Menu.Item>

        <Menu.Item key="policy">
          <NavLink to={`${path}/policy`}>Privacy policy </NavLink>
        </Menu.Item>
        <Menu.Item key="terms">
          <NavLink to={`${path}/terms`}>Terms and Services </NavLink>
        </Menu.Item>
        <Menu.Item key="aboutus">
          <NavLink to={`${path}/aboutus`}> Referral Program </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="download"
        icon={!topMenu && <FeatherIcon icon="download" />}
        title="Download Data"
      >
        <Menu.Item key="usershours">
          <NavLink to={`${path}/usershours`}>Users Hours </NavLink>
        </Menu.Item>
        <Menu.Item key="getpsychometric">
          <NavLink to={`${path}/getpsychometric`}>Self Study Analysis </NavLink>
        </Menu.Item>
        <Menu.Item key="referralcode">
          <NavLink to={`${path}/referralcode`}>Referral Code </NavLink>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
