import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Modal,
  Popconfirm,
  Tabs,
  Switch,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../AdminRole/styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  userSubscriptionDetails,
  SubscriptionUpdate,
} from "../../../redux/usersubscriptionList/actionCreator";
import { getDateFormate } from "../../../utility/commonFunction";

const { API } = require("../../../config/api/index");
const ListuserSubscription = () => {
  const [dataSource, SetDataSource] = useState([]);
  const { TabPane } = Tabs;
  // const [changeValue, setChangeValue] = useState({ status: "active" });
  const dispatch = useDispatch();
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [sizeOfData, setSize] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [changePage, setChangePage] = useState(1);
  const [statusKey, setStatusKey] = useState("active");

  useEffect(() => {
    onPageChange();
  }, []);

  // const changeClick = async (key) => {

  //   setStatusKey(key);

  //   let query = {
  //     status: key,
  //     page: changePage
  //   };
  //   const List = await dispatch(userSubscriptionDetails(query));
  //   if (List) {
  //     SetDataSource(List);
  //   }

  // };

  const onPageChange = async (key = statusKey, pageNumber = 1) => {
    console.log("key : ", key);
    console.log("pageNumber : ", pageNumber);

    if (key != statusKey) {
      setStatusKey(key);
    }
    setCurrentPageNumber(pageNumber);
    let query = {
      status: key,
      page: pageNumber,
    };
    const List = await dispatch(userSubscriptionDetails(query));
    console.log("listname ::: >", List);
    if (List) {
      SetDataSource(List);
    }
  };

  const onUpdateChange = async (value) => {
    let updateStatus = {
      subscriptionId: value,
    };
    const Update = await dispatch(SubscriptionUpdate(updateStatus));

    if (Update) {
      onPageChange("active");
    }
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "first_name",
      render: (key, value) => (
        <span>{`${value.userId ? value.userId.first_name : "-"} ${
          value.userId ? value.userId.last_name : "-"
        }`}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "first_name",
      render: (key, value) => (
        <span>{value.userId ? value.userId.email : "-"}</span>
      ),
    },
    {
      title: "Package Name",
      dataIndex: "name",
      render: (key, value) => (
        <span>{value.subscriptionId ? value.subscriptionId.name : "-"}</span>
      ),
    },
    {
      title: "Duration",
      dataIndex: "name",
      render: (key, value) => (
        <span>{`${value.subscriptionId ? value.subscriptionId.duration : "-"} 
      ${value.subscriptionId ? value.subscriptionId.timing : "-"}`}</span>
      ),
    },

    {
      title: "Status",
      dataIndex: "first_name",
      render: (key, value) => <span>{value.status}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "Phone Number",
      render: (key, value) => (
        <>
          {`${value.userId.countryDetails.countryCode} ${value.userId.mobile}`}
        </>
      ),
    },
  ];
  if (statusKey == "active") {
    columns.push({
      title: "Expired On",
      dataIndex: "dates",
      render: (key, value) => (
        <>{`${value && getDateFormate(value.validTill)}`}</>
      ),
    });
  }
  if (statusKey == "inactive") {
    columns.push({
      title: "School Name",
      render: (key, value) => (
        <span>
          {value.userId && value.userId.institute
            ? value.userId.institute
            : "-"}
        </span>
      ),
    });
  }
  if (statusKey == "inactive") {
    columns.push({
      title: "Action",
      dataIndex: "first_name",
      render: (key, value) => (
        <Switch
          unCheckedChildren="Active"
          checkedChildren="Active"
          onChange={() => onUpdateChange(value._id)}
        />
      ),
    });
  }
  if (statusKey == "expired") {
    columns.push({
      title: "Expired On",
      dataIndex: "dates",
      render: (key, value) => (
        <>{`${value && getDateFormate(value.validTill)}`}</>
      ),
    });
  }

  const pagination = {
    onChange: (page) => onPageChange(statusKey, page),
    total: dataSource.total_counts,
    current: currentPageNumber,
    pageSize: 10,
  };

  return (
    <>
      <PageHeader ghost title="List User Subscription" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <Tabs defaultActiveKey="1" onChange={onPageChange}>
                <TabPane tab="Active Subscription" key="active">
                  <div className="table-order table-responsive">
                    <Table
                      dataSource={dataSource.list}
                      columns={columns}
                      pagination={pagination}
                      // pagination={{
                      //   pageSize: defaultPageSize,
                      //   showSizeChanger: true,
                      //   total: dataSource.total_counts,
                      //   onShowSizeChange: onShowSizeChange,
                      // }}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Subscription Request" key="inactive">
                  <div className="table-order table-responsive">
                    <Table
                      dataSource={dataSource.list}
                      pagination={pagination}
                      columns={columns}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Cancelled" key="cancelled">
                  <div className="table-order table-responsive">
                    <Table
                      dataSource={dataSource.list}
                      pagination={pagination}
                      columns={columns}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Expired" key="expired">
                  <div className="table-order table-responsive">
                    <Table
                      dataSource={dataSource.list}
                      pagination={pagination}
                      columns={columns}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default ListuserSubscription;
