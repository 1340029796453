import React, { useState, useEffect } from "react";
import { Row, Col, Table, Input, Avatar, Button, Switch } from "antd";
import FeatherIcon from "feather-icons-react";
import {
  NavLink,
  useRouteMatch,
  useParams,
  useHistory,
  Link,
  Route,
} from "react-router-dom";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { CardToolbox, Main } from "../../MasterAdmin/styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { DeletedUserList } from "../../../redux/admin/actionCreator";
import {
  getRandomColor,
  GetOnlyDateFormat,
} from "../../../utility/commonFunction";
const { Search } = Input;

const deleteUserlist = () => {
  const [dataSource, setDataSource] = useState([]);
  const [FilterList, setFilterList] = useState([]);

  const [searchTxt, setSearchTxt] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    deletedUserList();
  }, []);

  const deletedUserList = async () => {
    let payload = {
      role: "user",
    };
    const List = await dispatch(DeletedUserList(payload));
    if (List) {
      setDataSource(List);
    }
  };

  const onSearch = (e) => {
    setSearchTxt(e.target.value);
    setFilterList(dataSource.filter((val) => val.first_name == e.target.value));
  };

  const columns = [
    {
      title: "Profile Picture",
      dataIndex: "profile_pic",
      key: "profile_pic",
      render: (logo, record) => {
        if (record.profile_pic != "") {
          return (
            <Avatar
              size="large"
              crossOrigin="anonymous"
              src={record.profile_pic}
            />
          );
        } else {
          return (
            <Avatar
              style={{
                backgroundColor: getRandomColor(),
                verticalAlign: "middle",
              }}
              size="large"
              icon=""
            ></Avatar>
          );
        }
      },
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Registration Date",
      dataIndex: "created_at",
      render: (key, value) => (
        <>
          <span>
            {value && value.created_at
              ? GetOnlyDateFormat(value.created_at)
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      render: (key, value) => (
        <>{`${value.countryDetails.countryCode} ${value.mobile}`}</>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            title="Deleted Users List"
            subTitle={
              <>
                <span
                  className="title-counter"
                  style={{ paddingRight: "20px" }}
                >
                  {dataSource.length} Total
                </span>
                <div className="table-search-box">
                  <Search
                    className="custom-search"
                    placeholder="Search"
                    onChange={(e) => onSearch(e)}
                  />
                </div>
              </>
            }
          />
        </CardToolbox>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards title="Delete User ">
              <div className="table-order table-responsive">
                <Table
                  dataSource={dataSource.filter(
                    (item) =>
                      (item &&
                        item.first_name &&
                        item.first_name.toLowerCase().includes(searchTxt)) ||
                      (item.last_name &&
                        item.last_name.toLowerCase().includes(searchTxt)) ||
                      (item.username &&
                        item.username.toLowerCase().includes(searchTxt)) ||
                      (item.email &&
                        item.email.toLowerCase().includes(searchTxt))
                  )}
                  pagination={true}
                  columns={columns}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default deleteUserlist;
