import Cookies from 'js-cookie';
import actions from './actions';

const { MINDFUL_COUNT, ACTIVE_USER_GRAPH_COUNT, REGISTER_USER_GRAPH_COUNT, PSYCHOMETRIC_GRAPH_COUNT } = actions;

const initState = {
  MindFulCount: {},
  ActiveUserCount: {},
  RegisterUserCount: {},
  psychometricGraphCount: {},
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const DashboardGraphCount = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MINDFUL_COUNT:
      return {
        ...state,
        MindFulCount: data,
      };

    case ACTIVE_USER_GRAPH_COUNT:
      return {
        ...state,
        ActiveUserCount: data,
      };

    case REGISTER_USER_GRAPH_COUNT:
      return {
        ...state,
        RegisterUserCount: data,
      };
    case PSYCHOMETRIC_GRAPH_COUNT:
      return {
        ...state,
        psychometricGraphCount: data,
      };
    default:
      return state;
  }
};
export default DashboardGraphCount;
