import React, { useState, useEffect } from "react";
import { Row, Col, Table, Input, Avatar, Button, Popconfirm } from "antd";
import FeatherIcon from "feather-icons-react";
import {
  NavLink,
  useRouteMatch,
  useParams,
  useHistory,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { CardToolbox, Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../../utility/localStorageControl";
import {
  AllUsersListData,
  getUsersById,
  deleteById,
} from "../../../redux/admin/actionCreator";
import { getRandomColor } from "../../../utility/commonFunction";
import { GetOnlyDateFormat } from "../../../utility/commonFunction";
const userManagement = () => {
  let userdetails = getItem("userDetails");
  const { Search } = Input;
  const { path } = useRouteMatch();

  let history = useHistory();

  let userListData = useSelector((state) => state.Admin.users);
  const [searchValue, setSearchValue] = useState("");
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    userList();
  }, []);

  const userList = async () => {
    let payload = { role: "user", status: "active" };
    let list = await dispatch(AllUsersListData(payload));
    console.log();
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  userListData =
    userListData && userListData.length > 0
      ? userListData.filter((item) =>
          item && item.first_name
            ? item.first_name.toLowerCase().includes(searchValue.toLowerCase())
            : "" || (item && item.last_name)
            ? item.last_name.toLowerCase().includes(searchValue.toLowerCase())
            : "" || (item && item.username)
            ? item.username.toLowerCase().includes(searchValue.toLowerCase())
            : "" || (item && item.email)
            ? item.email.toLowerCase().includes(searchValue.toLowerCase())
            : ""
        )
      : "";

  function onShowSizeChange(current, pageSize) {
    setDefaultPageSize(pageSize);
  }

  const DeleteUser = async (id) => {
    const deleted = await dispatch(deleteById(id));
    if (deleted) {
      userList();
    }
  };

  const columns = [
    {
      title: "Profile Picture",
      dataIndex: "profile_pic",
      key: "profile_pic",
      render: (logo, record) => {
        if (record.profile_pic != "") {
          return (
            <Avatar
              size="large"
              crossOrigin="anonymous"
              src={record.profile_pic}
            />
          );
        } else {
          return (
            <Avatar
              style={{
                backgroundColor: getRandomColor(),
                verticalAlign: "middle",
              }}
              size="large"
              icon=""
            ></Avatar>
          );
        }
      },
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Registration Date",
      dataIndex: "created_at",
      render: (key, value) => (
        <>
          <span>
            {value && value.created_at
              ? GetOnlyDateFormat(value.created_at)
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Expire Date",
      dataIndex: "created_at",
      render: (key, value) => (
        <>
          <span>
            {value && value.subscription && value.subscription.validTill
              ? getDateFormate(value.subscription.validTill)
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      render: (key, value) => (
        <>{`${value.countryDetails.countryCode} ${value.mobile}`}</>
      ),
    },
    // {
    //   title: "Subscription From",
    //   dataIndex: "subscriptionfrom",

    //   render: (key, value) => (
    //     <>
    //       <span>
    //         {value &&
    //           value.subscriptionDetails &&
    //           GetOnlyDateFormat(
    //             value.subscriptionDetails.validFrom !== null
    //               ? value.subscriptionDetails.validFrom
    //               : "-"
    //           )}
    //       </span>
    //     </>
    //   ),
    // },
    // {
    //   title: "Subscription To",
    //   dataIndex: "subscriptionto",

    //   render: (key, value) => (
    //     <>
    //       <span>
    //         {value &&
    //           value.subscriptionDetails &&
    //           GetOnlyDateFormat(
    //             value.subscriptionDetails.validTill !== null
    //               ? value.subscriptionDetails.validTill
    //               : "-"
    //           )}
    //       </span>
    //     </>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {userdetails?.permission.resource.users == "view_all" ? (
            <NavLink to={`${path}/${record._id}`}>
              <FeatherIcon icon="eye" size={16} />
            </NavLink>
          ) : (
            <>
              <NavLink to={`${path}/${record._id}`}>
                <FeatherIcon icon="eye" size={16} />
              </NavLink>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            title={`Active Users List`}
            subTitle={
              <>
                <span
                  className="title-counter"
                  style={{ paddingRight: "20px" }}
                >
                  {userListData.length} Total
                </span>
                <div className="table-search-box">
                  <Search
                    className="custom-search"
                    placeholder="Search"
                    onChange={(e) => onSearch(e)}
                  />
                </div>
              </>
            }
          />
        </CardToolbox>

        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards headless>
              <div className="table-order table-responsive">
                <Table
                  className="userTab-cus"
                  dataSource={userListData}
                  columns={columns}
                  onRow={(r) => ({
                    onClick: () => window.open(`${path}/${r._id}`),
                  })}
                  pagination={{
                    pageSize: defaultPageSize,
                    showSizeChanger: true,
                    total: userListData.length,
                    onShowSizeChange: onShowSizeChange,
                  }}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default userManagement;
