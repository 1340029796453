import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Dashboard from "./dashboard";

import AboutUs from "../../container/MasterAdmin/Aboutus/aboutus";
import myActivity from "../../container/MasterAdmin/Activity/myActivity";

import Adminrolemanagement from "../../container/MasterAdmin/AdminRoleManagement/Adminrolemanagement";
import AdminDetails from "../../container/MasterAdmin/AdminRoleManagement/AdminDetails";
import BlockedUsers from "../../container/MasterAdmin/BlockedUsers/BlockedUsers";
import Broadcast from "../../container/MasterAdmin/broadcast/broadcast";
import ExerciseMaster from "../../container/MasterAdmin/Exercise-master/exercise-master";
import faqList from "../../container/MasterAdmin/faq/faqList";
import FeedbackMaster from "../../container/MasterAdmin/Feedback-master/feedback-master";
import GlobalSetting from "../../container/MasterAdmin/global-setting/global-setting";
import InactiveUserList from "../../container/MasterAdmin/InactiveUserList/InactiveUserList";
import interest from "../../container/MasterAdmin/interest/interest";
import LetItAllOut from "../../container/MasterAdmin/LetItAllOut/LetItAllOut";
import addMood from "../../container/MasterAdmin/mood/addMood";
import myProfile from "../../container/MasterAdmin/myProfile/myProfile";
import permission from "../../container/MasterAdmin/Permission/permission";
import Policy from "../../container/MasterAdmin/policy/policy";
import Redeem from "../../container/MasterAdmin/Redeem-master/redeem";
import rewardsMaster from "../../container/MasterAdmin/Rewardsmaster/rewardsMaster";
import skill from "../../container/MasterAdmin/skill/skill";
// import socialMedia from "../../container/MasterAdmin/socialMedia/socialMedia";
import SoundMaster from "../../container/MasterAdmin/Soundmaster/soundmaster";
import Subscription from "../../container/MasterAdmin/Subscription/Subscription";
import Terms from "../../container/MasterAdmin/Terms/terms";
import testManagement from "../../container/MasterAdmin/testManagement/testManagement";
import userFeedback from "../../container/MasterAdmin/userFeedback/userFeedback";
import deleteUserlist from "../../container/MasterAdmin/userManagement/deleteUserlist";
import userManagement from "../../container/MasterAdmin/userManagement/userManagementList";
import userProfile from "../../container/MasterAdmin/userManagement/userProfile";
import withAdminLayout from "../../container/MasterAdmin/layout/withAdminLayout";
import questionGroup from "../../container/MasterAdmin/questionGroupType/questionGroup";
import Activity from "../../container/MasterAdmin/ContentManagment/Activity/Activity";
import nonActivity from "../../container/MasterAdmin/ContentManagment/nonActivity/nonActivity";
import userRedeem from "../../container/MasterAdmin/userRedeem/userRedeem";
import competitiveExam from "../../container/MasterAdmin/competitiveExam/competitiveExam";
import ListuserSubscription from "../../container/MasterAdmin/ListuserSubscription/ListuserSubscription";
import Usershours from "../../container/MasterAdmin/Usershours/Usershours";
import getpsychometric from "../../container/MasterAdmin/getpsychometric/getpsychometric";
import referralCode from "../../container/MasterAdmin/referralcode/referralcode";

const masterAdmin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}`} component={Dashboard} />
        <Route
          exact
          path={`${path}/adminrolemanagement`}
          component={Adminrolemanagement}
        />
        <Route
          path={`${path}/adminrolemanagement/:role_id`}
          component={AdminDetails}
        />
        <Route
          exact
          path={`${path}/usermanagment`}
          component={userManagement}
        />
        <Route
          path={`${path}/usermanagment/:user_id`}
          component={userProfile}
        />
        <Route exact path={`${path}/inactiveuserlist`} component={InactiveUserList} />
        <Route exact path={`${path}/inactiveuserlist/:user_id`} component={userProfile} />
        <Route path={`${path}/aboutus`} component={AboutUs} />
        <Route path={`${path}/terms`} component={Terms} />
        <Route path={`${path}/blocked_users`} component={BlockedUsers} />
        <Route path={`${path}/redeem`} component={Redeem} />
        <Route path={`${path}/userfeedback`} component={userFeedback} />
        <Route path={`${path}/subscription`} component={Subscription} />
        <Route path={`${path}/deleteuserlist`} component={deleteUserlist} />
        <Route path={`${path}/globalsetting`} component={GlobalSetting} />
        <Route path={`${path}/permission/:role_id`} component={permission} />
        <Route path={`${path}/faqlist`} component={faqList} />


        <Route path={`${path}/myprofile`} component={myProfile} />
        <Route path={`${path}/feedbackmaster`} component={FeedbackMaster} />
        <Route path={`${path}/testmanagement`} component={testManagement} />
        <Route path={`${path}/broadcast`} component={Broadcast} />
        <Route path={`${path}/myactivity`} component={myActivity} />
        <Route path={`${path}/mood`} component={addMood} />
        <Route path={`${path}/rewardsmaster`} component={rewardsMaster} />
        <Route path={`${path}/skill`} component={skill} />
        <Route path={`${path}/interest`} component={interest} />
        <Route path={`${path}/exercisemaster`} component={ExerciseMaster} />
        <Route path={`${path}/policy`} component={Policy} />
        <Route path={`${path}/letitallout`} component={LetItAllOut} />
        <Route path={`${path}/soundmaster`} component={SoundMaster} />
        <Route path={`${path}/questiongroup`} component={questionGroup} />
        <Route path={`${path}/competitiveexam`} component={competitiveExam} />
        <Route path={`${path}/activity`} component={Activity} />
        <Route path={`${path}/nonactivity`} component={nonActivity} />
        <Route path={`${path}/userredeem`} component={userRedeem} />

        <Route
          path={`${path}/listusersubscription`}
          component={ListuserSubscription}
        />
        <Route path={`${path}/usershours`} component={Usershours} />
        <Route path={`${path}/getpsychometric`} component={getpsychometric} />
        <Route path={`${path}/referralcode`} component={referralCode} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(masterAdmin);
