import React, { useState } from "react";
import { Avatar, Modal, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import { Popover } from "../../popup/popup";
import {
  logOut,
  changePassword,
} from "../../../redux/authentication/actionCreator";
import Heading from "../../heading/heading";
import { getItem } from "../../../utility/localStorageControl";
import { BasicFormWrapper } from "../../../container/MasterAdmin/styled";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const userDetails = getItem("userDetails");

const AuthInfo = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    flag: "english",
  });
  const { flag } = state;

  const [form] = Form.useForm();
  const [modalVisible, setModelVisible] = useState(false);

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  const onSubmit = async (values) => {
    let chnagePassword = await dispatch(changePassword(values));
    if (chnagePassword) {
      setModelVisible(false);
      form.resetFields();
      dispatch(logOut());
    }
  };

  const ChangePasswordModal = () => {
    setModelVisible(true);
  };

  const handleCancel = (e) => {
    setModelVisible(false);
    form.resetFields();
  };


  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            src={require("../../../static/img/avatar/chat-auth.png")}
            alt=""
          />
          <figcaption>
            <Heading as="h5">
              {userDetails && userDetails.role === "master_admin"
                ? "Master Admin"
                : "Admin"}
            </Heading>
          </figcaption>
        </figure>
        {userDetails && userDetails.role === "master_admin" ? (
          <Link
            className="user-dropdwon__bottomAction"
            onClick={() => ChangePasswordModal()}
            to="#"
          >
            <FeatherIcon icon="lock" /> Change password
          </Link>
        ) : (
          ""
        )}
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value) => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle("english")} to="#">
        <img src={require("../../../static/img/flag/english.png")} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("germany")} to="#">
        <img src={require("../../../static/img/flag/germany.png")} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("spain")} to="#">
        <img src={require("../../../static/img/flag/spain.png")} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("turky")} to="#">
        <img src={require("../../../static/img/flag/turky.png")} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>

      <Modal
        title="Change Password"
        visible={modalVisible}
        okText="Submit"
        onOk={form.submit}
        onCancel={handleCancel}
        width={600}
      >
        <BasicFormWrapper>
          <Form form={form} name="add_organizer" onFinish={onSubmit}>
            <Form.Item
              label="Old Password"
              name="old_password"
              rules={[{ message: "Old Password is required", required: true }]}
            >
              <Input.Password
                placeholder="Old Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="new_password"
              rules={[{ message: "New Password is required", required: true }]}
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              rules={[
                { message: "Confirm Password is required", required: true },
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
          </Form>
        </BasicFormWrapper>
      </Modal>
    </InfoWraper>
  );
};

export default AuthInfo;
