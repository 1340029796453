/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: "admin/login",
    signUp: "users/signup",
    changePassword: "users/change-password",
    logout: "users/logout",
  },
  admin: {
    userList: "admin/get-by-role",
    UserGetById: "admin/get",
    getAdminAndUserByStatus: "admin/get-by-status",
    getAdminAndUserByActiveList: "admin/get-active-users",
    UpdateUserStatus: "admin/update-status",
    DeletedUsersList: "admin/get-deleted-user",
    deleteUserById: "admin/delete",
  },
  PermissionRole: {
    RoleCheck: "permission/get",
  },
  faq: {
    list: "faq/",
    createfaq: "faq/add",
    Deletefaq: "faq/delete",
    Updatefaq: "faq/update",
    getByid: "faq/getById",
  },
  socialmedia: {
    UrlLits: "media",
    Deleteurl: "media/delete",
    createurl: "media/add",
    Updateurls: "media/update",
    getByid: "media/getById",
  },
  myactivity: {
    activitylist: "activity",
    Deleteurlactivity: "activity/delete",
    activityadd: "activity/add",
  },
  addmood: {
    moodlist: "mood",
    moodadd: "mood/add",
    deletemood: "mood/delete",
    upatemood: "mood/update",
  },
  rewardsmaster: {
    rewardslist: "reward",
    rewardsadd: "reward/add",
    deleterewards: "reward/delete",
    updatereward: "reward/update",
  },
  skill: {
    skilllist: "skill",
    skilladd: "skill/add",
    skilldelete: "skill/delete",
    skillupdate: "skill/update",
  },
  interest: {
    interestList: "interest",
    interestAdd: "interest/add",
    interestDelete: "interest/delete",
    interestUpdate: "interest/update",
  },
  testManagement: {
    questionList: "question",
    addQuestion: "question/add",
    deleteQuestion: "question/delete",
    questionUpdate: "question/update",
  },
  GroupDetails: {
    GroupList: "questionGroupType",
    createGroup: "questionGroupType/add",
    DeleteGroupList: "questionGroupType/delete",
    GroupUpdate: "questionGroupType/update",
  },
  competitiveExam: {
    addExamList: "competitiveExam/add",
    ExamList: "competitiveExam/list",
    DeleteExamList: "competitiveExam/delete/",
    ExamUpdate: "competitiveExam/update/",
  },
  exerciseMaster: {
    exerciseList: "exercise",
    exerciseAdd: "exercise/add",
    exerciseDelete: "exercise/delete",
    ExerciseUpdate: "exercise/update",
  },
  policy: {
    policyView: "policy",
    Update: "policy/add",
  },
  terms: {
    termsView: "termsAndServices",
    Update: "termsAndServices/add",
  },
  about: {
    aboutView: "about-us",
    Update: "about-us/add",
  },
  feedbackMaster: {
    feedbackList: "feedbackMaster/list",
    feedbackAdd: "feedbackMaster/add",
    feedbackDelete: "feedbackMaster/delete",
    feedbackUpdate: "feedbackMaster/update",
  },
  masterAdminDetailsByID: {
    getById: "admin/get",
  },
  subscription: {
    subscriptionGet: "subscription/allSubscriptions",
    subscriptionAdd: "subscription/addSubscription",
    deleteSubscription: "subscription/deleteSubscription",
    subscriptionUpdate: "subscription/updateSubscription",
  },
  globalSetting: {
    GetGlobalSetting: "settings/get",
    GlobalAdd: "settings/add",
  },
  Redeem: {
    GetRedeem: "redeemMaster",
    RedeemAdd: "redeemMaster/add",
  },
  permission: {
    permissionGet: "admin/resource-list",
    getPermissionById: "permission/get",
    UpdatePermission: "permission/update",
  },
  dashboard: {
    topTen: "admin/psychometric-users-list",
    HighestRank: "admin/highest-points-users-list",
    HighestEngagement: "admin/Highest-engagement",
    PsychometricGraph: "admin/psychometric-analyst-graph",
    MindfulCount: "admin/mindful-graph",
    UserGraph: "admin/date/get-users",
    RegisterUserGraph: "admin/register-users",
  },
  AdminRoleManagement: {
    RoleList: "admin/get-by-role",
    RoleGet: "admin/get",
    GoalsPending: "admin/get-user-goal",
    Registration: "admin/addAdmin",
    Delete: "admin/delete",
    UpdateById: "admin/update",
  },
  soundMaster: {
    audioList: "soundMaster",
    audioAdd: "soundMaster/add",
    AudioDelete: "soundMaster/delete",
    updateSound: "soundMaster/update",
  },
  broadcast: {
    getGroup: "userGroups/all/list",
    getUser: "admin/get-by-status",
    addBroadcast: "broadcast/add",
    GetBroadcastList: "broadcast/get",
  },
  userFeedback: {
    feedbackList: "userFeedback/list",
  },
  LetItAllOut: {
    LetItList: "letItAllOut/list",
  },
  contentmyactivity: {
    activitylist: "activity",
    Deleteurlactivity: "activity/delete",
    activityadd: "activity/add",
    getactivitydata: "content/getByQuery/",
    Create: "content/add",
    dataDelete: "content/delete",
    dataUpdate: "content/update",
  },
  RedeemTable: {
    data: "/userRedeem/get",
    update: "/userRedeem/verify",
  },
  userSubscription: {
    userSubscriptionList: "userSubscription/list",
    userSubscriptionUpdate: "userSubscription/update",
  },
  usersHours: {
    usersHoursList: "admin/users-hours",
    usersPsychometric: "admin/user-psychometric-analysis",
    referralCode: "admin/referral-code",
  },
};

export { API };
