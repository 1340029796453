import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader/root";
import { Provider, useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import store from "./redux/store";
import Admin from "./routes/admin";
import MasterAdmin from "./routes/MasterAdmin";
import Auth from "./routes/auth";
import "./static/css/style.css";
import "../src/container/css/style.css";
import config from "./config/config";
import { getItem } from "./utility/localStorageControl";
import ProtectedRoute from "./components/utilities/protectedRoute";
import IdleTimer from "react-idle-timer";
import { logOut } from "./redux/authentication/actionCreator";
const { theme } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { rtl, isLoggedIn, topMenu, darkMode, userRole } = useSelector(
    (state) => {
      const userDetails = getItem("userDetails");

      return {
        darkMode: state.ChangeLayoutMode.data,
        rtl: state.ChangeLayoutMode.rtlData,
        topMenu: state.ChangeLayoutMode.topMenu,
        isLoggedIn: state.auth.login,
        userRole: userDetails ? userDetails.role : "",
      };
    }
  );

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  const onActive = () => {
    console.log("user is active");
  };

  const onIdle = () => {
    console.log("user is onIdle");
    dispatch(logOut());
  };

  const onAction = () => {
    console.log("user is onAction");
  };

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>

          {!isLoggedIn ? (
            <Route path="/" component={Auth} />
          ) : userRole === "master_admin" ? (
            <ProtectedRoute path="/master_admin" component={MasterAdmin} />
          ) : userRole === "admin" ? (
            <ProtectedRoute path="/admin" component={Admin} />
          ) : (
            <ProtectedRoute path="/admin" component={Admin} />
          )}
          <IdleTimer
            onActive={onActive}
            onIdle={onIdle}
            onAction={onAction}
            debounce={250}
            timeout={3600000}
          />
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/` ||
              path === `${process.env.PUBLIC_URL}/login`) &&
            (userRole === "admin" ? (
              <Redirect to="/admin" />
            ) : userRole === "master_admin" ? (
              <Redirect to="/master_admin" />
            ) : (
              <Redirect to="/master_admin" />
            ))}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
