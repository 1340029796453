import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Card, DatePicker } from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { GetMinutes } from "../../../utility/commonFunction";
import { userListHours } from "../../../redux/usershours/actionCreator";
import { format } from "date-fns";
import { Excel } from "antd-table-saveas-excel";
import moment from "moment";
const { API } = require("../../../config/api/index");
const Usershours = () => {
  const dispatch = useDispatch();
  const [usersHoursList, setUsersHours] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { RangePicker } = DatePicker;

  useEffect(() => {
    userDataHours();
  }, []);

  // Get Url api

  const renderContent = (value, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index === 4) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const startDateFilter = async (date) => {
    let abc = moment(date).format("YYYY-MM-DD");
    setStartDate(abc);
  };

  const endDateFilter = async (date) => {
    let abc = moment(date).format("YYYY-MM-DD");
    setEndDate(abc);
  };

  const userDataHours = async () => {
    const query = {
      startDate: startDate,
      endDate: endDate,
    };
    const List = await dispatch(userListHours(query));
    console.log("Listat :::>", List);
    if (List) {
      console.log("List", List);
      setUsersHours(List);
    }
  };
  const onClick = async () => {
    const query = {
      startDate: startDate,
      endDate: endDate,
    };
    const List = await dispatch(userListHours(query));

    if (List) {
      setUsersHours(List);
    }
  };

  const columns = [
    // {
    //   title: "Total Minutes",
    //   dataIndex: "totalHours",
    //   key: "totalHours",
    //   sorter: (a, b) => a.totalHours - b.totalHours,
    //   render: renderContent,
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   sorter: (a, b) => a.type.localeCompare(b.type),
    //   render: renderContent,
    // },
    {
      title: "First Name",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) =>
        (a.users ? a.users.username : "").localeCompare(
          b.users ? b.users.username : ""
        ),

      render: (key, value) => renderContent(value.users.first_name),
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
      sorter: (a, b) =>
        (a.users ? a.users.username : "").localeCompare(
          b.users ? b.users.username : ""
        ),

      render: (key, value) => renderContent(value.users.last_name),
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      sorter: (a, b) =>
        (a.users ? a.users.age : "").localeCompare(b.users ? b.users.age : ""),

      render: (key, value) => renderContent(value.users.age),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) =>
        (a.users ? a.users.email : "").localeCompare(
          b.users ? b.users.email : ""
        ),

      render: (key, value) => renderContent(value.users.email),
    },
    {
      title: "State",
      dataIndex: "state",
      render: (key, value) =>
        renderContent(value.users?.state ? value.users.state : "-"),
    },
    {
      title: "Competitive",
      dataIndex: "competitive",
      render: (key, value) =>
        renderContent(
          value.users?.competitive?.exam_name
            ? value.users.competitive.exam_name
            : "-"
        ),
    },
    {
      title: "Distraction Time",
      dataIndex: "Distraction",
      key: "Distraction",
      // sorter: (a, b) => a.type.localeCompare(b.type),
      render: (key, value) => {
        return (
          <>
            {/* {console.log(
              "value",
              value.users && value.users.HoursData === "Distraction"
                ? HoursData.totalMinutes
                : value
            )} */}
            {value.HoursData.map((value) => {
              let minutes;
              minutes = `${value.totalMinutes} `;
              return (
                <div>
                  {value.hourWise === "Distraction" ? `${minutes}` : ""}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Focus Time",
      dataIndex: "Focus",
      key: "Focus",
      // sorter: (a, b) => a.type.localeCompare(b.type),
      render: (key, value) => {
        return (
          <>
            {value.HoursData.map((value) => {
              let minutes;
              minutes = `${value.totalMinutes} `;
              return (
                <div>{value.hourWise === "Focus" ? `${minutes}` : ""}</div>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Anxiety Time",
      dataIndex: "Anxiety",
      key: "Anxiety",
      // sorter: (a, b) => a.type.localeCompare(b.type),
      render: (key, value) => {
        return (
          <>
            {value.HoursData.map((value) => {
              let minutes;
              minutes = `${value.totalMinutes} `;
              return (
                <div>{value.hourWise === "Anxiety" ? `${minutes}` : ""}</div>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Stretching Study Time",
      dataIndex: "stretchingstudy",
      key: "stretchingstudy",
      // sorter: (a, b) => a.type.localeCompare(b.type),
      render: (key, value) => {
        return (
          <>
            {value.HoursData.map((value) => {
              let minutes;
              minutes = `${value.totalMinutes} `;
              return (
                <div>
                  {value.hourWise === "Stretching Study" ? `${minutes}` : ""}
                </div>
              );
            })}
          </>
        );
      },
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) =>
        (a.users ? a.users.mobile : "") - (b.users ? b.users.mobile : ""),
      render: (key, value) =>
        renderContent(
          `${value.users.countryDetails.countryCode} ${value.users.mobile}`
        ),
    },

    {
      title: "User Points",
      dataIndex: "user_points",
      key: "user_points",

      sorter: (a, b) =>
        (a.users ? a.users.user_points : "") -
        (b.users ? b.users.user_points : ""),
      render: (key, value) => renderContent(value.users.user_points),
    },
    {
      title: "User Amount",
      dataIndex: "user_amount",
      key: "user_amount",
      sorter: (a, b) =>
        (a.users ? a.users.user_amount : "") -
        (b.users ? b.users.user_amount : ""),
      render: (key, value) => renderContent(value.users.user_amount),
    },
    {
      title: "Skill",
      dataIndex: "skillName",
      key: "skills",
      render: (key, value) => renderContent(value ? value.skills : "-"),
    },
    {
      title: "Interests",
      dataIndex: "interests",
      key: "interests",

      // render: (key, value) =>
      //   renderContent(
      //     value.users && value.users.interests
      //       ? value.users.interests.interestName
      //       : "-"
      //   ),
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",

      sorter: (a, b) =>
        a.users &&
        a.users.institute &&
        a.users.institute.localeCompare(
          b.users && b.users.institute && b.users.institute
        ),

      render: (key, value) =>
        renderContent(value.users ? value.users.institute : "-"),
    },
    {
      title: "last percentage",
      dataIndex: "last_percentage",
      key: "last_percentage",
      sorter: (a, b) =>
        (a.users ? a.users.last_percentage : "") -
        (b.users ? b.users.last_percentage : ""),
      render: (key, value) => renderContent(value.users.last_percentage),
    },
  ];

  return (
    <>
      <PageHeader ghost title="Users Hours List" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Row gutter={10} align="middle" style={{ marginBottom: 10 }}>
                  <Col xxl={8} lg={8} xs={24}>
                    <DatePicker
                      placeholder="Select Start Date"
                      onChange={startDateFilter}
                    />
                  </Col>
                  <Col xxl={8} lg={8} xs={24}>
                    <DatePicker
                      placeholder="Select End Date"
                      onChange={endDateFilter}
                    />
                  </Col>
                  <Col xxl={4} lg={4} xs={24}>
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        padding: "12px",
                        height: "inherit",
                      }}
                      htmlType="submit"
                      onClick={onClick}
                    >
                      Filter Data
                    </Button>
                  </Col>
                  <Col xxl={4} lg={4} xs={24}>
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        padding: "12px",
                        height: "inherit",
                      }}
                      onClick={() => {
                        const excel = new Excel();
                        excel
                          .addSheet("test")
                          .addColumns(columns)
                          .addDataSource(usersHoursList)
                          .saveAs("file.xlsx");
                      }}
                    >
                      Export Excel
                    </Button>
                  </Col>
                </Row>

                <Table
                  dataSource={usersHoursList}
                  pagination={true}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Usershours;
