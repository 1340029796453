import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  Avatar,
  Select,
  Table,
  Alert,
  Modal,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import EyeOutlined from "feather-icons-react";
import { BasicFormWrapper } from "../styled";
import { Button } from "../../../components/buttons/buttons";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Action } from "./style";
import { Main, CardToolbox } from "../styled";
import { ProfileAuthorBox } from "../../profile/settings/overview/style";

import { useDispatch } from "react-redux";
import { Dropdown } from "../../../components/dropdown/dropdown";
import {
  adminManagementRoleList,
  Registration,
  DeleteAdmin,
  updateById,
} from "../../../redux/Adminrolemanagement/actionCreator";
import { UserOutlined, CloseCircleFilled } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import useStateRef from "react-usestateref";
import _ from "lodash";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
const { API } = require("../../../config/api/index");
const { Option } = Select;
import { getItem } from "../../../utility/localStorageControl";

const userDetails = getItem("userDetails");

const Adminrolemanagement = () => {
  const { path } = useRouteMatch();
  const [form] = Form.useForm();
  const { Search } = Input;
  const [modalVisible, setModelVisible] = useState(false);
  const [modalTitle, setModelTitle] = useState("Add New Admin");
  const [adminRoleList, setAdminRoleList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedFile, setSelectedFile, setSelectedFileRef] = useStateRef("");
  const [previewMedia, setPreviewMedia] = useState("");
  const [adminId, setAdminId, adminIdRef] = useStateRef("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [deleteIconClick, setDeleteIconClick] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    RoleAdminList();
  }, []);

  const RoleAdminList = async () => {
    let payload = { role: "admin" };
    const List = await dispatch(adminManagementRoleList(payload));

    if (List) {
      setDataSource(List);
      setAdminRoleList(List);
    }
  };

  function cancel(e) {
    console.log(e);
  }

  const onSearch = (e) => {
    const filterDataList = e.target.value
      ? adminRoleList.filter((item) =>
        item && item.first_name
          ? item.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
          : "" || (item && item.last_name)
            ? item.last_name
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
            : "" || (item && item.username)
              ? item.username.toLowerCase().includes(e.target.value.toLowerCase())
              : "" || (item && item.email)
                ? item.email.toLowerCase().includes(e.target.value.toLowerCase())
                : ""
      )
      : adminRoleList;
    setDataSource(filterDataList);
  };

  const showModal = () => {
    setModelTitle("Add New Admin");
    setModelVisible(true);
    setPreviewMedia("");
    setAdminId("");
    form.resetFields();
  };

  const changeHandler = (event) => {
    if (event.target.files[0].type.includes("image")) {
      setPreviewMedia(
        <img
          height="50"
          width="50"
          src={URL.createObjectURL(event.target.files[0])}
        />
      );
      setSelectedFile(event.target.files[0]);
      setDeleteIconClick(false);
    } else {
      form.setFieldsValue({ profile_pic: "" });
      message.error("You can only upload IMAGE/GIF file!");
    }
  };

  const handleCancel = (e) => {
    setModelVisible(false);
    setSelectedFile("");
    form.resetFields();
  };

  const onSubmit = async (values) => {
    setButtonDisabled(true);

    if (values.first_name == undefined || values.first_name == "undefined") {
      delete values.first_name;
    }
    if (values.last_name == undefined || values.last_name == "undefined") {
      delete values.last_name;
    }
    if (values.profile_pic == undefined || values.profile_pic == "undefined") {
      delete values.profile_pic;
    }

    var form_data = new FormData();

    if (setSelectedFileRef.current != "") {
      if (deleteIconClick && deleteIconClick === true) {
        // delete values.logo;
        setSelectedFileRef.current == "";
        form_data.append("profile_pic", "");
      } else {
        form_data.append("profile_pic", setSelectedFileRef.current);
      }
    } else {
      if (deleteIconClick && deleteIconClick === true) {
        values.profile_pic = "";
      } else {
        delete values.profile_pic;
      }
    }

    for (var key in values) {
      form_data.append(key, values[key]);
    }

    if (adminIdRef.current == "") {
      let add = await dispatch(Registration(form_data));
      if (add) {
        setSelectedFile("");
        RoleAdminList();
      }
    } else {
      let update = await dispatch(updateById(adminIdRef.current, form_data));
      if (update) {
        setSelectedFile("");
        RoleAdminList();
      }
    }

    setModelVisible(false);
    setButtonDisabled(false);
  };

  const handleUpdateModel = (id = "") => {
    if (id != "") {
      let AdminInfo = _.find(dataSource, { _id: id });

      setAdminId(id);
      form.setFieldsValue({
        first_name: AdminInfo.first_name,
        last_name: AdminInfo.last_name,
        username: AdminInfo.username,
        email: AdminInfo.email,
        email: AdminInfo.email,
        status: AdminInfo.status,
      });

      if (AdminInfo.profile_pic && AdminInfo.profile_pic != "") {
        setPreviewMedia(
          <img
            crossOrigin="anonymous"
            width="50"
            height="50"
            src={AdminInfo.profile_pic}
          />
        );
        setDeleteIconClick(false);
      }
      setModelTitle("Edit Admin");
      setModelVisible(true);
    }
  };

  const DeleteRecord = async (id) => {
    const deleted = await dispatch(DeleteAdmin(id));

    if (deleted) {
      RoleAdminList();
    }
  };

  const columns = [
    {
      title: "Profile Picture",
      dataIndex: "profile_pic",
      key: "profile_pic",
      render: (key, record) => {
        if (record.profile_pic != "") {
          return (
            <Avatar
              size="large"
              crossOrigin="anonymous"
              src={record.profile_pic}
            />
          );
        } else {
          return <Avatar size="large" icon={<UserOutlined />} />;
        }
      },
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      width: 50,
      align: "center",
      render: (_id, record) => {
        // <div className="table-actions">
        //   <>
        //     <NavLink to={`${path}/${record._id}`}>
        //       <EyeOutlined icon="eye" size={16} />
        //     </NavLink>
        //   </>
        // </div>
        return (
          <span>
            {" "}
            <Action>
              <Dropdown
                content={
                  <>
                    <NavLink to={`${path}/${record._id}`}>
                      <span>Details</span>
                    </NavLink>
                    {userDetails && userDetails.role === "master_admin" ? (
                      <NavLink
                        onClick={() => handleUpdateModel(record._id)}
                        to="#"
                      >
                        <span>Edit</span>
                      </NavLink>
                    ) : (
                      ""
                    )}

                    {userDetails && userDetails.role === "master_admin" ? (
                      <Popconfirm
                        outlined
                        type="warning"
                        title="Are you sure to delete this Admin?"
                        onCancel={cancel}
                        onConfirm={() => DeleteRecord(record._id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#">Delete</a>
                      </Popconfirm>
                    ) : (
                      ""
                    )}
                  </>
                }
                action={["click"]}
              >
                <Button
                  style={{ border: "none" }}
                  className="btn-icon"
                  type="dashed"
                  to="#"
                >
                  <FeatherIcon icon="more-vertical" size={16} />
                </Button>
              </Dropdown>
            </Action>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            title={`Active Admin List`}
            subTitle={
              <>
                <span
                  className="title-counter"
                  style={{ paddingRight: "20px" }}
                >
                  {adminRoleList.length} Total
                </span>
                <div className="table-search-box">
                  <Search
                    className="custom-search"
                    placeholder="Search"
                    onChange={(e) => onSearch(e)}
                  />
                </div>
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions">
                <NavLink
                  to="#"
                  onClick={() => showModal()}
                  className="ant-btn ant-btn-primary ant-btn-md"
                >
                  <FeatherIcon icon="plus" size={14} />
                  Add New
                </NavLink>
              </div>,
            ]}
          />
        </CardToolbox>
        <Row gutter={25}>
          <Col xxl={24} lg={24} md={24}>
            <Table dataSource={dataSource} columns={columns} />
          </Col>
        </Row>

        <Modal
          title={modalTitle}
          visible={modalVisible}
          okText="Submit"
          onOk={form.submit}
          okButtonProps={{ disabled: buttonDisabled }}
          onCancel={handleCancel}
          width={600}
        >
          <BasicFormWrapper>
            <Form
              form={form}
              autocomplete="off"
              name="add_admin"
              onFinish={onSubmit}
            >
              <Form.Item label="Profile pic" name="profile_pic">
                <Input.Group size="large">
                  <Row style={{ marginBottom: 25 }} gutter={25}>
                    <Col span={12}>
                      <Input
                        type="file"
                        placeholder="Profile Pic"
                        title=""
                        accept="image/*"
                        onChange={changeHandler}
                      />
                    </Col>
                    <Col span={12}>
                      <div className="img_prev_game">
                        {deleteIconClick == false && previewMedia}

                        {modalTitle == "Add New Admin" &&
                          deleteIconClick == false &&
                          previewMedia ? (
                          <span>
                            {" "}
                            <CloseCircleFilled
                              onClick={() => setDeleteIconClick(true)}
                            />{" "}
                          </span>
                        ) : (
                          ""
                        )}

                        {modalTitle == "Edit Admin" &&
                          deleteIconClick == false &&
                          previewMedia ? (
                          <span>
                            {" "}
                            <CloseCircleFilled
                              onClick={() => setDeleteIconClick(true)}
                            />{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ message: "First Name is required", required: true }]}
              >
                <Input placeholder="Input First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ message: "Last Name is required", required: true }]}
              >
                <Input placeholder="Input Last Name" />
              </Form.Item>
              {modalTitle == "Add New Admin" ? (
                <Form.Item
                  label="User Name"
                  name="username"
                  rules={[{ message: "User Name is required", required: true }]}
                >
                  <Input placeholder="Input User Name" />
                </Form.Item>
              ) : (
                <Form.Item label="User Name" name="username">
                  <Input placeholder="Input Email" disabled />
                </Form.Item>
              )}
              {modalTitle == "Add New Admin" ? (
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ message: "Email is required", required: true }]}
                >
                  <Input placeholder="Input Email" />
                </Form.Item>
              ) : (
                <Form.Item label="Email" name="email">
                  <Input placeholder="Input Email" disabled />
                </Form.Item>
              )}
              {modalTitle == "Add New Admin" ? (
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ message: "Password is required", required: true }]}
                >
                  <Input placeholder="Input Password" />
                </Form.Item>
              ) : (
                ""
              )}
            </Form>
          </BasicFormWrapper>
        </Modal>
      </Main>
    </>
  );
};

export default Adminrolemanagement;
