import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Avatar, Select, Table, Alert } from "antd";
import { Link } from "react-router-dom";
import EyeOutlined from "feather-icons-react";
import { BasicFormWrapper } from "../styled";
import { Button } from "../../../components/buttons/buttons";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main } from "../styled";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { useRouteMatch, useParams } from "react-router-dom";
const { API } = require("../../../config/api/index");
const { Option } = Select;
import { GetDetailsRole } from "../../../redux/AdminDetails/actionCreator";

const AdminDetails = () => {
  const { path } = useRouteMatch();
  const { role_id } = useParams();
  const [form] = Form.useForm();
  const [AdminDetails, setAdminDetails] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    AdminDetailsList();
  }, []);

  const AdminDetailsList = async () => {
    let adminId = { id: role_id };
    const List = await dispatch(GetDetailsRole(adminId));
    if (List) {
      form.setFieldsValue(List);
      setAdminDetails(List);
      // setAdminDetailsId(List._id);
    }
  };

  return (
    <>
      <PageHeader ghost title="Role List" />
      <Main>
        <Row gutter={25}>
          <Cards>
            <Col xxl={24} lg={24} md={24}>
              <Form form={form} layout="vertical">
                <img
                  style={{ width: "100px" }}
                  crossOrigin="anonymous"
                  src={AdminDetails.profile_pic}
                />

                <br></br>
                <br></br>
                <Row gutter={10}>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <Form.Item label="First Name" name="first_name">
                      <Input type="text" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <Form.Item label="Last Name" name="last_name">
                      <Input type="text" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <Form.Item name="username" label="Username">
                      <Input type="text" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <Form.Item name="email" label="Email">
                      <Input type="text" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <Form.Item name="role" label="Role">
                      <Input type="text" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xxl={8} lg={8} md={8} xs={24}>
                    <Form.Item name="email" label="Email">
                      <Input type="text" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Cards>
          <NavLink to={`/master_admin/permission/${role_id}`}>
            <Button type="primary">Admin Role Management</Button>
          </NavLink>
        </Row>
      </Main>
    </>
  );
};

export default AdminDetails;
