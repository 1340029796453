import React, { useState, useEffect } from "react";
import { Row, Col, Table, Input, Select, Form, Popconfirm } from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../MasterAdmin/styled";
import { Modal, Button, Radio } from "antd";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import useStateRef from "react-usestateref";

import {
  pointlist,
  rewardaddpoint,
  deleterewardsdata,
  Update,
} from "../../../redux/rewardsmaster/actionCreator";
import { Activitylist } from "../../../redux/myActivity/actionCreator";
import _ from "lodash";

const rewardsMaster = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rewardlist, setrewardlist] = useState([]);
  const [modelTitle, setModelTitle] = useState("Create Reward");

  const [rewardupdate, setRewardupdate, setsetRewardupdateRef] = useStateRef(
    ""
  );
  const [activitylist, setActivitylist] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    rewmainlist();
    myactiveList();
  }, []);

  function cancel(e) {
    console.log(e);
  }

  const showModal = () => {
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const myactiveList = async () => {
    const List = await dispatch(Activitylist());
    if (List) {
      setActivitylist(List);
    }
  };

  // Get Url api
  const rewmainlist = async () => {
    const List = await dispatch(pointlist());
    if (List) {
      setrewardlist(List);
    }
  };
  // add Url api
  const onsubmit = async (value) => {
    if (setsetRewardupdateRef.current !== "") {
      const update = await dispatch(
        Update(setsetRewardupdateRef.current, value)
      );
      if (update) {
        rewmainlist();
      }
    } else {
      const create = await dispatch(rewardaddpoint(value));

      if (create) {
        rewmainlist();
      }
    }

    setIsModalVisible(false);
    form.resetFields();
  };

  const handelUpdate = async (id) => {
    setRewardupdate(id);
    const pointslist = _.find(rewardlist, { _id: id });
    form.setFieldsValue({
      points: pointslist.points,
      limit: pointslist.limit,
      type_of_activity:
        pointslist && pointslist.type_of_activity
          ? pointslist.type_of_activity._id
          : "",
      conversion_rate: pointslist.conversion_rate,
      flip_phone_points: pointslist.flip_phone_points,
      dnd_points: pointslist.dnd_points,
    });
    setModelTitle("Update Rewards");
    setIsModalVisible(true);
  };
  // Deletelist api
  const deletpoint = async (id) => {
    const deleted = await dispatch(deleterewardsdata(id));
    if (deleted) {
      rewmainlist();
    }
  };

  const columns = [
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
    },
    // {
    //   title: "Limit",
    //   dataIndex: "limit",
    //   key: "limit",
    // },
    {
      title: "Activity",
      dataIndex: "type_of_activity",
      key: "type_of_activity",
      render: (text, record) => (
        <div className="table-actions">
          {record && record.type_of_activity
            ? record.type_of_activity.type
            : "-"}
        </div>
      ),
    },

    // {
    //   title: "Conversion Rate",
    //   dataIndex: "conversion_rate",
    //   key: "conversion_rate",
    // },
    // {
    //   title: "flip phone points",
    //   dataIndex: "flip_phone_points",
    //   key: "flip_phone_points",
    // },
    // {
    //   title: "Dnd Points",
    //   dataIndex: "dnd_points",
    //   key: "dnd_points",
    // },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <div className="table-actions">
          <>
            <a
              className="btn-icon"
              to="#"
              shape="circle"
              onClick={() => handelUpdate(text._id)}
            >
              <FeatherIcon icon="edit" size={16} />
            </a>
            <Popconfirm
              outlined
              type="warning"
              title="Are you sure to delete this List ?"
              // onConfirm={confirm}
              onCancel={cancel}
              onConfirm={() => deletpoint(text._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageHeader ghost title="Rewards master" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards
              title="Rewards Master List"
              isbutton={
                <div className="card-radio">
                  <Button htmlType="submit" type="primary" onClick={showModal}>
                    + Add Rewards
                  </Button>
                </div>
              }
            >
              <div className="table-order table-responsive">
                <Table
                  dataSource={rewardlist}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Cards>
          </Col>
        </Row>
        <Modal
          onOk={form.submit}
          title={modelTitle}
          visible={isModalVisible}
          onCancel={handleCancel}
          okText="Submit"
        >
          <Form layout="vertical" form={form} onFinish={onsubmit}>
            <Row gutter={10}>
              <Col xxl={12} lg={12} xs={24}>
                <Form.Item
                  label="Points"
                  name="points"
                  rules={[{ required: true, message: "Please input Points!" }]}
                >
                  <Input type="number" placeholder="Add Points" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} xs={24}>
                <Form.Item
                  name="type_of_activity"
                  label="Activity Select"
                  initialValue=""
                  rules={[
                    { required: true, message: "Please select Activity!" },
                  ]}
                >
                  <Select style={{ width: "100%" }}>
                    <Select.Option value="">Select Activity</Select.Option>
                    {activitylist.map((data) => {
                      return (
                        <Select.Option value={data._id}>
                          {data.type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default rewardsMaster;
