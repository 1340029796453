// import Cookies from 'js-cookie';
import actions from "./actions";

const { TEST_LIST_SUCCESS } = actions;

const initState = {
  users: [],
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const testManagementReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TEST_LIST_SUCCESS:
      return {
        ...state,
        TestData: data,
      };
    default:
      return state;
  }
};
export default testManagementReducer;
