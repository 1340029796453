const actions = {
  MINDFUL_COUNT: 'MINDFUL_COUNT',
  ACTIVE_USER_GRAPH_COUNT: "ACTIVE_USER_GRAPH_COUNT",
  REGISTER_USER_GRAPH_COUNT: "REGISTER_USER_GRAPH_COUNT",
  PSYCHOMETRIC_GRAPH_COUNT: "PSYCHOMETRIC_GRAPH_COUNT",

  mindfulGraphData: data => {
    return {
      type: actions.MINDFUL_COUNT,
      data,
    };
  },

  ActiveUserGraphData: data => {
    return {
      type: actions.ACTIVE_USER_GRAPH_COUNT,
      data,
    };
  },

  RegisterUserGraphData: data => {
    return {
      type: actions.REGISTER_USER_GRAPH_COUNT,
      data,
    };
  },

  PsychometricGraphCount: data => {
    return {
      type: actions.PSYCHOMETRIC_GRAPH_COUNT,
      data,
    };
  },
};

export default actions;
