import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Modal,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { userFeedbackList } from "../../../redux/userfeedback/actionCreator";
import { format } from "date-fns";

const { API } = require("../../../config/api/index");

const userFeedback = () => {
  const dispatch = useDispatch();
  const [userFeedback, setUserFeedback] = useState([]);
  const [QuestionStore, setQuestionStore] = useState([]);
  const [showQuestionModel, setShowQuestionModel] = useState(false);

  useEffect(() => {
    FeedbackList();
  }, []);

  const showModal = (value) => {
    // const Questions = _.find(QuestionStore, { _id: value });
    setQuestionStore(value.userFeedback);
    setShowQuestionModel(true);
  };

  const handleCancel = () => {
    setShowQuestionModel(false);
  };

  const FeedbackList = async (value) => {
    const List = await dispatch(userFeedbackList());
    if (List) {
      setUserFeedback(List);
    }
  };

  const columns = [
    {
      title: "Users",
      dataIndex: "userId",
      // key: "userId",
      width: "80%",
      render: (key, value) => (
        <>
          <span>
            {value && value.userId !== null ? value.userId.username : "-"}
          </span>
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (key, value) => (
        <div className="table-actions">
          <>
            <a
              className="btn-icon"
              to="#"
              shape="circle"
              onClick={() => showModal(value)}
            >
              <FeatherIcon icon="eye" size={16} />
            </a>
          </>
        </div>
      ),
    },
  ];
  return (
    <>
      <PageHeader ghost title="User Feedback" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Table
                  dataSource={userFeedback}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          title="Question List"
          visible={showQuestionModel}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          <div>
            {QuestionStore && QuestionStore.length > 0
              ? QuestionStore.map((value, index) => {
                return (
                  <>
                    <h3>
                      <span style={{ marginRight: "10px" }}>
                        (Q{index + 1})
                      </span>
                      {value.question}
                    </h3>
                    <p>
                      <span style={{ marginRight: "10px" }}>Answer:</span>
                      {value.rating}
                    </p>
                  </>
                );
              })
              : ""}
          </div>
        </Modal>
      </Main>
    </>
  );
};

export default userFeedback;
