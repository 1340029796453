const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const exerciseGet = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.exerciseMaster.exerciseList);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const createExercise = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.exerciseMaster.exerciseAdd,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const exerciseDeleteList = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.exerciseMaster.exerciseDelete + "/" + id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const updateExercise = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.exerciseMaster.ExerciseUpdate + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
