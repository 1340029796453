const actions = {
    MASTER_ADMIN_DETAILS: "MASTER_ADMIN_DETAILS",

    DetailsByID: data => {
        return {
            type: actions.MASTER_ADMIN_DETAILS,
            data,
        };
    },
};

export default actions;
