import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Input,
  Table,
  form,
  Modal,
  Button,
  Form,
  Popconfirm,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../MasterAdmin/styled";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  QuestionList,
  QuestionAdd,
  DeleteList,
  updateQuestion,
} from "../../../redux/testmanagement/actionCreator";
import { truncate } from "../../../utility/commonFunction";
import { skillget } from "../../../redux/skill/actionCreator";
import { interestGet } from "../../../redux/interest/actionCreator";
import _ from "lodash";
import useStateRef from "react-usestateref";
import styled from "styled-components";
import { GroupDetailsUrlLits } from "../../../redux/questiongroup/actionCreator";

const testManagement = () => {
  const dispatch = useDispatch();
  const [questionList, setQuestionStore] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [addQuestion, setaAdQuestion] = useState(false);
  const [form] = Form.useForm();
  const [getSkill, setGetSkill] = useState([]);
  const [GroupList, setGroupList] = useState([]);

  const [getGroup, setGetGroup] = useState([]);

  const [getInterest, setGetInterest] = useState([]);
  // const [skillId, setSkillId] = useState("");
  // const [interestId, setInterestId] = useState("");
  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");

  const [modelTitle, setModelTitle] = useState("Create Question");

  useEffect(() => {
    questionsList();
    GetQuestionGroup();
  }, []);

  const showModal = () => {
    setEditModel(true);
  };

  const showQue = () => {
    setaAdQuestion(true);
    setUpdateId("");
    form.resetFields();
  };

  const handleCancelQuestion = () => {
    setaAdQuestion(false);
    form.resetFields();
    setUpdateId("");
  };

  // Get skil Url api

  const GetQuestionGroup = async () => {
    const List = await dispatch(GroupDetailsUrlLits());
    if (List) {
      setGroupList(List);
    }
  };
  // Get Url api
  const questionsList = async () => {
    const List = await dispatch(QuestionList());
    if (List) {
      setQuestionStore(List.questions);
      setQuestionCount(List.count);
    }
  };

  // add data api

  const onsubmit = async (value) => {
    let optionArray = [
      {
        option: value.A,
        points: value.pointA,
      },
      {
        option: value.B,
        points: value.pointB,
      },
      {
        option: value.C,
        points: value.pointC,
      },
      {
        option: value.D,
        points: value.pointD,
      },
    ];
    let qunObj = {
      question: value.questions,
      options: optionArray,
    };
    let finalObj = {
      groupTypeId: value.groupTypeId,
      qna: qunObj,
    };
    if (setUpdateIdRef.current != "") {
      const updateQue = await dispatch(
        updateQuestion(setUpdateIdRef.current, finalObj)
      );
      if (updateQue) {
        setUpdateId("");
        questionsList();
      }
    } else {
      const Add = await dispatch(QuestionAdd(finalObj));
      if (Add) {
        questionsList();
      }
    }

    setaAdQuestion(false);
    form.resetFields();
  };

  const handUpdateQuestion = async (id) => {
    setUpdateId(id);
    let QuestionList = _.find(questionList, { _id: id });

    form.setFieldsValue({
      questions: QuestionList.qna.question,

      groupTypeId:
        QuestionList &&
          QuestionList.groupTypeId &&
          QuestionList.groupTypeId.groupName
          ? QuestionList.groupTypeId._id
          : "",

      A:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[0].option
          : "-",
      B:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[1].option
          : "-",
      C:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[2].option
          : "-",
      D:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[3].option
          : "-",
      pointA:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[0].points
          : "-",
      pointB:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[1].points
          : "-",
      pointC:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[2].points
          : "-",
      pointD:
        QuestionList && QuestionList.qna.options
          ? QuestionList.qna.options[3].points
          : "-",
    });

    setaAdQuestion(true);
    setModelTitle("Edit Question");
  };

  // Deletelist api
  const DeleteQuestions = async (id) => {
    const deleted = await dispatch(DeleteList(id));
    if (deleted) {
      questionsList();
    }
  };
  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",

      render: (data, value) => (
        <span>
          {value.qna && value.qna && value.qna.question !== ""
            ? truncate(value.qna.question)
            : "-"}
        </span>
      ),
    },
    {
      title: "Option",
      key: "option",
      width: "250px",

      render: (data, value) => (
        <Select style={{ width: "100%" }} placeholder="Options / Points ">
          {value.qna.options.map((data) => {
            return (
              <Select.Option
                disabled
              >{`${data.option} / ${data.points}`}</Select.Option>
            );
          })}
        </Select>
      ),
    },

    {
      title: "Group Name",
      dataIndex: "answer",
      key: "answer",
      render: (data, value) => <span>{value.groupTypeId.groupName}</span>,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="table-actions">
          <>
            <a
              className="btn-icon"
              onClick={() => handUpdateQuestion(record._id)}
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="edit" size={16} />
            </a>
            <Popconfirm
              outlined
              type="warning"
              title="Are you sure to delete this Question?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => DeleteQuestions(record._id)}
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageHeader ghost title="Test Management" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards
              title="Question List"
              isbutton={
                <div className="card-radio">
                  <Button htmlType="submit" type="primary" onClick={showQue}>
                    Add Question
                  </Button>
                </div>
              }
            >
              <div className="table-order table-responsive">
                <Table
                  dataSource={questionList}
                  columns={columns}
                  pagination={{
                    showSizeChanger: true,
                    total: questionCount,
                    pageSizeOptions: ["10", "50", "100", "500", "1000"],
                  }}
                />
              </div>
            </Cards>
          </Col>

          {/* Add question model */}
          <Modal
            title={modelTitle}
            onOk={form.submit}
            visible={addQuestion}
            onCancel={handleCancelQuestion}
            okText="Submit"
          >
            <div className="questionedit">
              <Form layout="vertical" form={form} onFinish={onsubmit}>
                <Row gutter={10}>
                  <Col xxl={24} lg={24} xs={24}>
                    <Form.Item
                      name="questions"
                      label="Add Question"
                      rules={[
                        {
                          required: true,
                          message: "Please input Question!",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Question" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xxl={24} lg={24} xs={24}>
                    <Form.Item
                      name="groupTypeId"
                      label="Select Group"
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: "Select Group!",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }}>
                        <Select.Option value="">Select Group</Select.Option>
                        {GroupList.map((data) => {
                          return (
                            <Select.Option value={data._id}>
                              {data.groupName}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={10} align="middle">
                  <Col
                    xxl={24}
                    lg={24}
                    xs={24}
                    style={{ marginBottom: "10px" }}
                  >
                    <label style={{ fontWeight: "500" }}>
                      Add Your Option{" "}
                    </label>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      label="Option A"
                      name="A"
                      rules={[
                        {
                          required: true,
                          message: "Please input Option!",
                        },
                      ]}
                    >
                      <Input placeholder="Input Option A" />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      label="Point A"
                      name="pointA"
                      rules={[
                        {
                          required: true,
                          message: "Please input points A!",
                        },
                      ]}
                    >
                      <Input placeholder="Input points" type="number" />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      name="B"
                      label="Option B"
                      rules={[
                        {
                          required: true,
                          message: "Please input Option!",
                        },
                      ]}
                    >
                      <Input placeholder="Input Option B" />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      label="Point B"
                      name="pointB"
                      rules={[
                        {
                          required: true,
                          message: "Please input points B!",
                        },
                      ]}
                    >
                      <Input placeholder="Input points" type="number" />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      label="Option C"
                      name="C"
                      rules={[
                        {
                          required: true,
                          message: "Please input Option!",
                        },
                      ]}
                    >
                      <Input placeholder="Input Option C" />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      label="Point C"
                      name="pointC"
                      rules={[
                        {
                          required: true,
                          message: "Please input points C!",
                        },
                      ]}
                    >
                      <Input placeholder="Input points" type="number" />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      label="Option D"
                      name="D"
                      rules={[
                        {
                          required: true,
                          message: "Please input Option!",
                        },
                      ]}
                    >
                      <Input placeholder="Input Option D" />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} lg={12} xs={24}>
                    <Form.Item
                      label="Point D"
                      name="pointD"
                      rules={[
                        {
                          required: true,
                          message: "Please input points D!",
                        },
                      ]}
                    >
                      <Input placeholder="Input points" type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
          {/* Add question model */}
        </Row>
      </Main>
    </>
  );
};

export default testManagement;
