import Cookies from 'js-cookie';
import actions from './actions';


const { MASTER_ADMIN_DETAILS } = actions;

const initState = {
    Details: {},
    loading: false,
    error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const MasterAdminReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case MASTER_ADMIN_DETAILS:
            return {
                ...state,
                Details: data
            };
        default:
            return state;
    }
};
export default MasterAdminReducer;
