import React from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { getItem } from "../../../utility/localStorageControl";

// import versions from '../demoData/changelog.json';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();
  const userDetails = getItem("userDetails");
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  // const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );
  const onOpenChange = (keys) => {
    setOpenKeys([keys.length && keys[keys.length - 1]]);
  };
  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const { SubMenu } = Menu;
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1
              ? "home"
              : mainPathSplit.length === 2
                ? mainPathSplit[1]
                : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        icon={!topMenu && <FeatherIcon icon="activity" />}
        key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      <SubMenu
        key="products"
        icon={!topMenu && <FeatherIcon icon="user" />}
        title="User Management"
      >
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.users != "none" && (
            <Menu.Item key="userlist">
              <NavLink to={`${path}/usermanagment`}>User List </NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.userFeedback != "none" && (
            <Menu.Item key="userfeedback">
              <NavLink to={`${path}/userfeedback`}>User Feedback List</NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.letitallout != "none" && (
            <Menu.Item key="letitallout">
              <NavLink to={`${path}/letitallout`}>Let It All Out</NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.admin != "none" && (
            <Menu.Item key="blockuser">
              <NavLink to={`${path}/blocked_users`}>Block Users List </NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.admin != "none" && (
            <Menu.Item key="inactive">
              <NavLink to={`${path}/inactiveuserlist`}>
                Inactive User List{" "}
              </NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.admin != "none" && (
            <Menu.Item key="deletuser">
              <NavLink to={`${path}/deleteuserlist`}>Delete User</NavLink>
            </Menu.Item>
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.userRedeemHistory != "none" && (
            <Menu.Item key="userredeem">
              <NavLink to={`${path}/userredeem`}>User Redeem </NavLink>
            </Menu.Item>
          )}
      </SubMenu>

      <SubMenu
        key="contentmanagement"
        icon={!topMenu && <FeatherIcon icon="book" />}
        title="Content Management"
      >
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.contentManagement != "none" && (
            <>
              <Menu.Item key="activity">
                <NavLink to={`${path}/activity`}>Activity </NavLink>
              </Menu.Item>
              <Menu.Item key="nonactivity">
                <NavLink to={`${path}/nonactivity`}>Non Activity </NavLink>
              </Menu.Item>
            </>
          )}
      </SubMenu>

      <SubMenu
        key="examsetting"
        icon={!topMenu && <FeatherIcon icon="book" />}
        title="Exam Setting"
      >
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.questionMaster != "none" && (
            <Menu.Item key="testmanagement">
              <NavLink onClick={toggleCollapsed} to={`${path}/testmanagement`}>
                Test Management
              </NavLink>
            </Menu.Item>
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.questionGroupType != "none" && (
            <Menu.Item key="questiongroup">
              <NavLink onClick={toggleCollapsed} to={`${path}/questiongroup`}>
                Question Group
              </NavLink>
            </Menu.Item>
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.competitiveExam != "none" && (
            <Menu.Item key="competitiveexam">
              <NavLink onClick={toggleCollapsed} to={`${path}/competitiveexam`}>
                Competitive Exam
              </NavLink>
            </Menu.Item>
          )}
      </SubMenu>
      <SubMenu
        key="Global"
        icon={!topMenu && <FeatherIcon icon="tag" />}
        title="Global Settings"
      >
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.globalSetting != "none" && (
            <Menu.Item key="globalsetting">
              <NavLink onClick={toggleCollapsed} to={`${path}/globalsetting`}>
                Global Setting
              </NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.soundMaster != "none" && (
            <Menu.Item key="soundmaster">
              <NavLink to={`${path}/soundmaster`}>Sound Master</NavLink>
            </Menu.Item>
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.faq != "none" && (
            <Menu.Item key="faq">
              <NavLink to={`${path}/faqlist`}>Faq Setting</NavLink>
            </Menu.Item>
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.feedbackMaster != "none" && (
            <Menu.Item key="feedbackmaster">
              <NavLink to={`${path}/feedbackmaster`}>Feedback Setting</NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.broadcast != "none" && (
            <Menu.Item key="broadcast">
              <NavLink to={`${path}/broadcast`}>Broadcast</NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.redeemMaster != "none" && (
            <Menu.Item key="redeem">
              <NavLink to={`${path}/redeem`}>Redeem Setting</NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.exercise != "none" && (
            <Menu.Item key="exercisemaster">
              <NavLink to={`${path}/exercisemaster`}>Exercise Setting</NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.interests != "none" && (
            <Menu.Item key="interest">
              <NavLink to={`${path}/interest`}>Interest Setting</NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.skills != "none" && (
            <Menu.Item key="skill">
              <NavLink to={`${path}/skill`}>Skill Setting</NavLink>
            </Menu.Item>
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.rewardsManagement != "none" && (
            <Menu.Item key="rewardsmaster">
              <NavLink to={`${path}/rewardsmaster`}>
                Rewardsmaster Setting
              </NavLink>
            </Menu.Item>
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.moods != "none" && (
            <Menu.Item key="mood">
              <NavLink to={`${path}/mood`}>Mood Setting</NavLink>
            </Menu.Item>
          )}
      </SubMenu>
      <SubMenu
        key="setting"
        icon={!topMenu && <FeatherIcon icon="settings" />}
        title="Settings"
      >

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.activity != "none" && (
            <Menu.Item key="myactivity">
              <NavLink to={`${path}/myactivity`}>My Activity </NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.policy != "none" && (
            <Menu.Item key="policy">
              <NavLink to={`${path}/policy`}>Privacy policy </NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.termsandservice != "none" && (
            <Menu.Item key="terms">
              <NavLink to={`${path}/terms`}>Terms and Services </NavLink>
            </Menu.Item>
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.aboutUs != "none" && (
            <Menu.Item key="aboutus">
              <NavLink to={`${path}/aboutus`}> Referral Program </NavLink>
            </Menu.Item>
          )}
      </SubMenu>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
