import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Select,
  Input,
  message,
  Table,
  Modal,
  Button,
  Form,
  Popconfirm,
} from "antd";
import { Main } from "../../styled";
import { PageHeader } from "antd";
import useStateRef from "react-usestateref";
import {
  List,
  nonactiveAdd,
  userDeleteId,
  userUpdateId,
  userMoodId,
  ActivityList,
} from "../../../../redux/ContentManagment/actionCreator";
import FeatherIcon from "feather-icons-react";
import { TreeSelect, Switch, Space } from "antd";
import { Cards } from "../../../../components/cards/frame/cards-frame";
import ReactAudioPlayer from "react-audio-player";

const Activity = () => {
  //define states
  const { TreeNode } = TreeSelect;
  const [EditMindValue, setEditMindValue] = useState("");
  const [MindValue, setMindValue] = useState("");
  const [IDValue, setIDValue] = useState("");
  const [IDEditValue, setEditIDValue] = useState("");
  const [form1] = Form.useForm();
  const [Mindful, setMindful] = useState("");
  const [EditMindful, setEditMindful] = useState("");
  const [userList, setUserList] = useState([]);
  const [previewMedia, setPreviewMedia] = useState("");
  const [previewEditMedia, setPreviewEditMedia] = useState("");
  const [type, setType] = useState();
  const [content, setContent] = useState();
  const [updateId, setUpdateId] = useState("");
  const [form] = Form.useForm();
  const [ColValue, setColValue] = useState();
  const [ColEditValue, setColEditValue] = useState();
  const [storeMenu, setMenu] = useState({});
  const [storeEditMenu, setEditMenu] = useState({ storeMenu: "" });
  const [selectedFile, setSelectedFile, setSelectedFileRef] = useStateRef("");
  const [
    selectedEditFile,
    setSelectedEditFile,
    setSelectedEditFileRef,
  ] = useStateRef("");
  const [visible, setVisible] = useState(false);
  const [ApiMoodList, setApiMoodList] = useState([]);
  const [ApiEditMoodList, setApiEditMoodList] = useState([]);
  const [EditApiId, setEditApiId] = useState([]);
  const [ApiId, setApiId] = useState([]);
  const [activitySelect, setActivity] = useState();
  const { Option } = Select;
  const dispatch = useDispatch();

  //id change function
  const changeActivityId = async (value) => {
    setIDValue(value);
  };

  //edit form id change function
  const changeEditActivityId = async (value) => {
    setEditIDValue(value);
  };

  //change id function
  const changeMindMenu = async (value) => {
    setMindValue(value);
  };

  //change edit menu function
  const changeEdiMindMenu = async (value) => {
    setEditMindValue(value);
  };
  //menu store validation function

  const changeStoreMenu = async (value) => {
    setActivity(value);
    if (value == "Mindfulness") {
      setMindful(value);
      var colValue = 6;
      setColValue(colValue);

      //Activity api data
      const ActivityId = await dispatch(ActivityList());
      setApiId(ActivityId);

      //mood list api data
      const moodList = await dispatch(userMoodId());

      var moodHide = moodList.filter(function (ApiMoodList) {
        return ApiMoodList.mood_type !== "Motivated";
      });
      setApiMoodList(moodHide);
    } else {
      setMindful();
      setColValue();
    }
    setMenu(value);
  };

  //edit form menu validation function
  const changeEditStoreMenu = async (value) => {
    if (value == "Mindfulness") {
      setEditMindful(value);
      var colValue = 6;
      setColEditValue(colValue);
    } else {
      setEditMindful();
    }
    setEditMenu(value);
  };

  //useEffect Table data show
  useEffect(() => {
    tableData();
  }, []);

  //table api call
  const tableData = async () => {
    const tableList = await dispatch(List({ type: "Activity" }));
    setUserList(tableList);
    // setMenu("");
  };

  //Delete api Call
  const deleteApiData = async (id) => {
    const tableList = await dispatch(userDeleteId(id));
    tableData();
  };

  //open popup det api and data
  const update = async (e) => {
    setType(e.type);
    setContent(e.content);
    setUpdateId(e._id);
    setVisible(true);
    form1.setFieldsValue({
      type: e.type,
      activityId: e.activityId,
      content: e.content,
      moodId: e.moodId,
    });

    if (e.type == "Mindfulness") {
      setEditMindful(e.type);
    } else {
      setEditMindful();
    }

    // Edit time Mood List Api Call
    const EditMoodList = await dispatch(userMoodId());

    var moodHide = EditMoodList.filter(function (ApiMoodList) {
      return ApiMoodList.mood_type !== "Motivated";
    });
    setApiEditMoodList(moodHide);

    //Edit Time Activity List Api Call
    const ActivityEditId = await dispatch(ActivityList());
    setEditApiId(ActivityEditId);
  };

  //delete confirmation cancel function
  const cancel = (e) => {
    form.resetFields();
  };

  //image change function
  // const imageChange = (event) => {
  //   var reader = new FileReader();
  //   var media = new Audio(reader.result);
  //   media.onloadedmetadata = function() {};
  //   if (event.target.files[0].type.includes("audio/mpeg")) {
  //     var media = new Audio(reader.result);
  //     reader.onload = function(e) {
  //       audio.src = e.target.result;
  //       audio.addEventListener(
  //         "loadedmetadata",
  //         function() {
  //           var duration = audio.duration;
  //         },
  //         false
  //       );
  //     };
  //     var audio = new Audio();
  //     setPreviewMedia(
  //       <audio style={{ width: "100%" }} controls id="myAudio">
  //         <source src={URL.createObjectURL(event.target.files[0])} />{" "}
  //       </audio>
  //     );
  //     setSelectedFile(event.target.files[0]);
  //   } else {
  //     form.setFieldsValue({ audio: "" });

  //     message.error("You can only upload Audio file!");
  //   }
  // };

  function computeLength(file) {
    return new Promise((resolve) => {
      var objectURL = URL.createObjectURL(file);
      var mySound = new Audio([objectURL]);
      mySound.addEventListener(
        "canplaythrough",
        () => {
          URL.revokeObjectURL(objectURL);
          resolve({
            file,
            duration: mySound.duration,
          });
        },
        false
      );
    });
  }

  function imageChange(file) {
    setSelectedFile(file.target.files[0]);
    computeLength(file.target.files[0]).then((value) => {
      if (
        activitySelect &&
        activitySelect == "Mindfulness" &&
        value.duration > 300
      ) {
        message.error("Upload Audio size max 5 min");
      } else if (
        activitySelect &&
        activitySelect == "Sleep" &&
        value.duration > 1800
      ) {
        message.error("Upload Audio size max");
      } else {
        message.success("Audio Upload");
      }
      value.duration;
    });
  }

  //image change function
  const imageEditChange = (event) => {
    if (event.target.files[0].type.includes("audio/mpeg")) {
      setPreviewEditMedia(
        <audio style={{ width: "100%" }} controls>
          <source src={URL.createObjectURL(event.target.files[0])} />{" "}
        </audio>
      );
      setSelectedEditFile(event.target.files[0]);
    } else {
      form1.setFieldsValue({ audio: "" });
      message.error("You can only upload Audio file!");
    }
  };

  //form submit function
  const submitForm = async (values) => {
    setColValue();
    if (values.type == "Mindfulness") {
      setMindful();
    }
    var form_data = new FormData();
    if (setSelectedFileRef.current != "") {
      form_data.append("content", setSelectedFileRef.current);
    }
    for (var key in values) {
      form_data.append(key, values[key]);
    }

    let create = await dispatch(nonactiveAdd(form_data));
    if (create) {
      tableData();
    }
    form.resetFields();
    setPreviewMedia("");
  };

  //form Edit submit function
  const submitEditForm = async (values) => {
    form1.resetFields();
    setVisible(true);
    var form_data = new FormData();
    if (setSelectedEditFileRef.current != "") {
      form_data.append("content", setSelectedEditFileRef.current);
    }
    for (var key in values) {
      form_data.append(key, values[key]);
    }

    let create = await dispatch(userUpdateId(form_data, updateId));
    if (create) {
      tableData();
    } else {
    }
    form1.resetFields();
    setPreviewEditMedia("");
    form1.resetFields();
    setVisible(false);
  };

  //table column define
  const columns = [
    {
      title: "Title",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Mood",
      dataIndex: "type",
      render: (key, value) => (
        <>
          <span>
            {value && value.moodId && value.moodId.mood_type !== null
              ? value.moodId.mood_type
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Audio",
      key: "content",
      render: (key, value) => (
        <>
          <div>
            {value && value.content !== undefined ? (
              // <audio controls crossOrigin="anonymous">
              //   <source src={value.content} />
              // </audio>
              <ReactAudioPlayer src={value.content} controls />
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (key) => (
        <div className="table-actions">
          <>
            <a
              className="btn-icon"
              to="#"
              shape="circle"
              onClick={() => update(key)}
            >
              <FeatherIcon icon="edit" size={16} />
            </a>
            <Popconfirm
              outlined
              type="warning"
              title="Are you sure to delete this List ?"
              onCancel={cancel}
              onConfirm={() => deleteApiData(key._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ];

  //Main Function return call
  return (
    <>
      {/* edit popup */}
      <Modal
        title="Update Content"
        centered
        visible={visible}
        onCancel={() => {
          form1.resetFields();
          setVisible(false);
        }}
        footer={false}
      >
        {/* edit form */}
        <Form
          onFinish={submitEditForm}
          form={form1}
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Row gutter={25}>
            <Col xl={12} lg={12} xs={24}>
              <Form.Item
                name="type"
                label="Select "
                hasFeedback
                rules={[
                  { required: true, message: "Please select your country!" },
                ]}
              >
                <Select
                  placeholder="Please select a Activity"
                  onChange={changeEditStoreMenu}
                >
                  <Option value="Sleep">Sleep</Option>
                  <Option value="Mindfulness">Mindfulness</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} xs={24}>
              <Form.Item
                label="Select Audio"
                rules={[
                  { required: true, message: "Please select your Audio!" },
                ]}
              >
                <Input
                  id="content"
                  type="file"
                  accept="audio/*"
                  name="file"
                  onChange={imageEditChange}
                />
              </Form.Item>
            </Col>
            {EditMindful ? (
              <Col xl={12} lg={12} xs={24}>
                <Form.Item
                  name="moodId"
                  label="Mood Select"
                  hasFeedback
                  rules={[
                    { required: true, message: "Please select your country!" },
                  ]}
                >
                  <Select
                    placeholder="Please select a Mood"
                    onChange={changeEdiMindMenu}
                  >
                    {ApiEditMoodList.map((val) => {
                      return <Option value={val._id}>{val.mood_type}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row gutter={25}>
            <Col xl={12} lg={12} xs={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} xs={24}>
              <Form.Item>
                <div className="img_prev_game">{previewEditMedia}</div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* page head title */}
      <PageHeader title=" Activity Content " />
      <Main>
        <Row gutter={25}>
          <Col xl={24} lg={24} xs={24}>
            <Cards title="Choice Song">
              <Form
                form={form}
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={submitForm}
                autoComplete="off"
              >
                <Row gutter={25}>
                  <Col xl={ColValue ? 6 : 12} lg={ColValue ? 6 : 12} xs={24}>
                    <Form.Item
                      name="type"
                      label="Select "
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please select a Activity"
                        onChange={changeStoreMenu}
                      >
                        <Option value="Sleep">Sleep</Option>
                        <Option value="Mindfulness">Mindfulness</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {Mindful ? (
                    <Col xl={8} lg={8} xs={24}>
                      <Form.Item
                        name="moodId"
                        label="Mood Select"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Mood!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select a Mood"
                          onChange={changeMindMenu}
                        >
                          {ApiMoodList.map((val) => {
                            return (
                              <Option value={val._id}>{val.mood_type}</Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col xl={ColValue ? 8 : 12} lg={ColValue ? 8 : 12} xs={24}>
                    <Form.Item
                      label="Select Audio"
                      rules={[
                        {
                          required: true,
                          message: "Please select your Audio!",
                        },
                      ]}
                      name="content"
                    >
                      <Input
                        id="content"
                        type="file"
                        accept="audio/*"
                        name="file"
                        onChange={imageChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={25}>
                  <Col xl={ColValue ? 8 : 12} lg={ColValue ? 8 : 12} xs={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                  {previewMedia != "" && (
                    <Col xl={ColValue ? 12 : 12} lg={ColValue ? 6 : 12} xs={24}>
                      <Form.Item>
                        <div className="img_prev_game">{previewMedia}</div>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            </Cards>
          </Col>
        </Row>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards title=" Activity List">
              <div className="table-order table-responsive">
                <Table
                  dataSource={userList}
                  columns={columns}
                  pagination={true}
                // pagination={{
                //   showSizeChanger: true,
                //   pageSizeOptions: ["10", "50", "100", "500", "1000"],
                // }}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Activity;
