const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const Activitylist = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.myactivity.activitylist);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const Deleteactivity = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.myactivity.Deleteurlactivity + "/" + id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const AddActivity = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.myactivity.activityadd, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
