import React, { useState, useEffect } from "react";
import {
  NavLink,
  useRouteMatch,
  useParams,
  useHistory,
  Link,
  Route,
} from "react-router-dom";
// import { Button } from "../../../components/buttons/buttons";
import {
  Row,
  Col,
  Image,
  Form,
  Popconfirm,
  Table,
  Tabs,
  Collapse,
  DatePicker,
  Button,
} from "antd";
import Heading from "../../../components/heading/heading";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../MasterAdmin/styled";
import {
  GetUsersDetailsById,
  getGoal,
} from "../../../redux/users/actionCreator";
import FeatherIcon from "feather-icons-react";
import { updateUserStatus } from "../../../redux/admin/actionCreator";
import {
  GetOnlyDateFormat,
  GetOnlyTimeFormat,
  getDateFormate,
} from "../../../utility/commonFunction";
import {
  SearchOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import moment from "moment";
import "./style.css";

const staticImage = require("../../../static/img/logominffull.png");

const userProfile = () => {
  const { user_id } = useParams();
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [Goalpending, setGoalpending] = useState({});
  const [QuestionGroup, setQuestionGroup] = useState({ key: "", value: "" });
  const [ques, setQues] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Goal, setGoals] = useState([]);
  const [currentHistory, setCurrentHistory] = useState("daily");

  const userDetailsById = async () => {
    let payload = { id: user_id };
    const List = await dispatch(GetUsersDetailsById(payload, currentHistory));
    console.log("List", List);
    if (List) {
      let newObject = List.questions;
      for (const groupName in newObject) {
        setQuestionGroup({ key: groupName, value: newObject[groupName] });
      }

      setUserDetails(List);
    }
  };

  useEffect(() => {
    userDetailsById();
    // UserGolPending();
  }, [currentHistory]);

  const onChangeBlockUser = async () => {
    let payload = { status: "blocked" };
    const Blog = await dispatch(updateUserStatus(user_id, payload));
    if (Blog) {
      window.history.back();
    }
  };

  const onChangeInactive = async () => {
    let payload = { status: "inactive" };
    const inactive = await dispatch(updateUserStatus(user_id, payload));
    if (inactive) {
      window.history.back();
    }
  };

  const selectStartDate = async (date) => {
    let abc = moment(date).format("YYYY-MM-DD");
    setStartDate(abc);
  };

  const selectEndDate = async (date) => {
    let abc = moment(date).format("YYYY-MM-DD");
    setEndDate(abc);
  };

  const filterDateWise = async () => {
    let query = {
      startDate: startDate,
      endDate: endDate,
      day: currentHistory,
    };

    let payload = { id: user_id };
    const List = await dispatch(getGoal(payload, query));
    if (List) {
      setGoals(List.userGoals);
    }
  };

  const TableColumn = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (key, value) => (
        <>
          <span>
            {value && value.created_at
              ? GetOnlyDateFormat(value.created_at)
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "start_date_time",
      key: "start_date_time",
      render: (key, value) => (
        <>
          <span>
            {value && value.created_at
              ? GetOnlyTimeFormat(value.start_date_time)
              : "-"}
          </span>
        </>
      ),
    },
    {
      title: "End Time",
      dataIndex: "end_date_time",
      key: "end_date_time",
      render: (key, value) => (
        <>
          <span>
            {value && value.created_at
              ? GetOnlyTimeFormat(value.end_date_time)
              : "-"}
          </span>
        </>
      ),
    },
    { title: "Chapter Name", dataIndex: "chapter_name", key: "chapter_name" },

    { title: "Subject Name", dataIndex: "subject_name", key: "subject_name" },
    { title: "Status", dataIndex: "completionStatus", key: "completionStatus" },
  ];

  return (
    <>
      <PageHeader ghost />
      <Main>
        <Row>
          <Col xxl={24} lg={24} xs={24}>
            {/* <Cards title="Privacy policy"> */}
            <Cards
              title={`${userDetails.first_name} ${userDetails.last_name}`}
              isbutton={
                <div>
                  <Popconfirm
                    outlined
                    type="warning"
                    title="Are you sure to Block this User?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => onChangeBlockUser(user_id)}
                  >
                    <Button
                      size="default"
                      style={{ backgroundColor: "#FFF0F0", color: "red" }}
                      transparented
                    >
                      Block User
                    </Button>
                  </Popconfirm>
                </div>
              }
            >
              <Row>
                <Col xxl={8} lg={8} xs={24}>
                  <Image
                    preview={false}
                    width={150}
                    crossOrigin="anonymous"
                    alt="user"
                    src={
                      userDetails && userDetails.profile_pic
                        ? userDetails.profile_pic
                        : staticImage
                    }
                  />
                  <br></br>
                  <br></br>
                  <div className="info">
                    <h3 style={{ margin: "5px 0" }}>
                      <UserOutlined />
                      {`${
                        userDetails && userDetails.first_name
                          ? userDetails.first_name
                          : ""
                      } ${
                        userDetails && userDetails.last_name
                          ? userDetails.last_name
                          : ""
                      }`}
                    </h3>
                    <p style={{ margin: "5px 0" }}>
                      {userDetails && userDetails.mobile ? (
                        <p style={{ margin: " 0" }}>
                          <PhoneOutlined />{" "}
                          <span>
                            {`${userDetails.countryDetails.countryCode} ${userDetails.mobile}`}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                    </p>
                    {userDetails && userDetails.email ? (
                      <p>
                        <MailOutlined /> <span>{userDetails.email}</span>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xxl={16} lg={16} xs={24}>
                  <Row>
                    {userDetails && userDetails.age ? (
                      <Col xxl={6} lg={6} xs={24}>
                        <div className="profile_name">
                          <label>Age</label>

                          <p>{userDetails.age}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {userDetails && userDetails.gender ? (
                      <Col xxl={10} lg={10} xs={24}>
                        <div className="profile_name">
                          <label>Gender</label>
                          <p>{userDetails.gender}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {userDetails && userDetails.stream ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Stream</label>
                          <p>{userDetails.stream}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row>
                    {userDetails && userDetails.status ? (
                      <Col xxl={6} lg={6} xs={24}>
                        <div className="profile_name">
                          <label>Status</label>
                          <p>{userDetails.status}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {userDetails && userDetails.institute ? (
                      <Col xxl={10} lg={10} xs={24}>
                        <div className="profile_name">
                          <label>College/Institute/school Name </label>
                          <p>{userDetails.institute}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {userDetails && userDetails.coming_exam ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Exam Selection </label>
                          <p>{userDetails.coming_exam}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row>
                    {userDetails && userDetails.year_of_exam ? (
                      <Col xxl={6} lg={6} xs={24}>
                        <div className="profile_name">
                          <label>Year of Exam</label>
                          <p>{userDetails.year_of_exam}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {userDetails && userDetails.last_percentage ? (
                      <Col xxl={10} lg={10} xs={24}>
                        <div className="profile_name">
                          <label>Last Percentage </label>
                          <p>{userDetails.last_percentage}%</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {userDetails &&
                    userDetails.competitiveExamId &&
                    userDetails.competitiveExamId.exam_name ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Competitive Exam Name</label>
                          <p>{userDetails.competitiveExamId.exam_name}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row>
                    {userDetails &&
                    userDetails.subscriptionDetails &&
                    userDetails.subscriptionDetails.subscriptionId &&
                    userDetails.subscriptionDetails.subscriptionId.name ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Subscription Name</label>
                          <p>
                            {
                              userDetails.subscriptionDetails.subscriptionId
                                .name
                            }
                          </p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {userDetails &&
                    userDetails.subscriptionDetails &&
                    userDetails.subscriptionDetails.validFrom ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Subscription From</label>
                          <p>
                            {getDateFormate(
                              userDetails.subscriptionDetails.validFrom
                            )}
                          </p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {userDetails &&
                    userDetails.subscriptionDetails &&
                    userDetails.subscriptionDetails.validTill ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Subscription To</label>
                          <p>
                            {getDateFormate(
                              userDetails.subscriptionDetails.validTill
                            )}
                          </p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row>
                    {userDetails &&
                    userDetails.referrerDetails &&
                    userDetails.referrerDetails.referral_code ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Use Referral Code</label>
                          <p>{userDetails.referrerDetails.referral_code}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {userDetails && userDetails.user_points ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Redeemable Points</label>
                          <p>{userDetails.user_points}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {userDetails && userDetails.total_earned_points ? (
                      <Col xxl={8} lg={8} xs={24}>
                        <div className="profile_name">
                          <label>Earned Point</label>
                          <p>{userDetails.total_earned_points}</p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xxl={24} lg={24} xs={24}>
                  <>
                    {userDetails?.questions &&
                      Object.keys(userDetails.questions).length > 0 &&
                      Object.keys(userDetails.questions).map((key, i) => {
                        return (
                          <>
                            <Collapse className="ques_list">
                              <Panel header={key} key="1">
                                {userDetails.questions[key].length > 0 &&
                                  userDetails.questions[key].map((j) => {
                                    return (
                                      <div className="quDate_main">
                                        <div className="que_list">
                                          <h3>Q: {j.question}</h3>
                                          <p>Ans: {j.answer.option}</p>
                                          <p>Point: {j.answer.points}</p>
                                        </div>
                                        <div className="que_date">
                                          <p>
                                            {GetOnlyDateFormat(
                                              userDetails.question_created_Date
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </Panel>
                            </Collapse>
                          </>
                        );
                      })}
                  </>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xxl={12} lg={12} xs={24}>
                  <Tabs
                    defaultActiveKey="daily"
                    onChange={(value) => setCurrentHistory(value)}
                  >
                    <TabPane tab="Daily" key="daily">
                      {userDetails &&
                      userDetails.rewardsHistory &&
                      userDetails.rewardsHistory.length > 0 ? (
                        <>
                          {userDetails.rewardsHistory.map((i) => {
                            return (
                              <Row>
                                <Col xxl={8} lg={8} xs={24}>
                                  <div className="profile_name">
                                    <label>Name</label>
                                    <p>
                                      {i.activityId && i.activityId.type ? (
                                        i.activityId.type
                                      ) : (
                                        <div style={{ color: "#f00" }}>
                                          Point redeemed
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                </Col>
                                <Col xxl={8} lg={8} xs={24}>
                                  <div className="profile_name">
                                    {i.activityId ? (
                                      <>
                                        {" "}
                                        <label>Earn Points</label>
                                        <p>+{i.points}</p>
                                      </>
                                    ) : (
                                      <>
                                        <label>Redeemed Point </label>
                                        <p style={{ color: "#f00" }}>
                                          -{i.points}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        "Data Not Found"
                      )}
                    </TabPane>

                    <TabPane tab="Weekly" key="weekly">
                      {userDetails &&
                      userDetails.rewardsHistory &&
                      userDetails.rewardsHistory.length > 0 ? (
                        <>
                          {userDetails.rewardsHistory.map((i) => {
                            return (
                              <Row>
                                <Col xxl={8} lg={8} xs={24}>
                                  <div className="profile_name">
                                    <label>Name</label>
                                    <p>
                                      {i.activityId && i.activityId.type ? (
                                        i.activityId.type
                                      ) : (
                                        <div style={{ color: "#f00" }}>
                                          Point redeemed
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                </Col>
                                <Col xxl={8} lg={8} xs={24}>
                                  <div className="profile_name">
                                    {i.activityId ? (
                                      <>
                                        {" "}
                                        <label>Earn Points</label>
                                        <p>+{i.points}</p>
                                      </>
                                    ) : (
                                      <>
                                        <label>Redeemed Point </label>
                                        <p style={{ color: "#f00" }}>
                                          -{i.points}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        "Data Not Found"
                      )}
                    </TabPane>
                    <TabPane tab="monthly" key="monthly">
                      {userDetails &&
                      userDetails.rewardsHistory &&
                      userDetails.rewardsHistory.length > 0 ? (
                        <>
                          {userDetails.rewardsHistory.map((i) => {
                            return (
                              <Row>
                                <Col xxl={8} lg={8} xs={24}>
                                  <div className="profile_name">
                                    <label>Name</label>
                                    <p>
                                      {i.activityId && i.activityId.type ? (
                                        i.activityId.type
                                      ) : (
                                        <div style={{ color: "#f00" }}>
                                          Point redeemed
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                </Col>
                                <Col xxl={8} lg={8} xs={24}>
                                  <div className="profile_name">
                                    {i.activityId ? (
                                      <>
                                        {" "}
                                        <label>Earn Points</label>
                                        <p>+{i.points}</p>
                                      </>
                                    ) : (
                                      <>
                                        <label>Redeemed Point </label>
                                        <p style={{ color: "#f00" }}>
                                          -{i.points}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        "Data Not Found"
                      )}
                    </TabPane>
                  </Tabs>
                </Col>
                <Col xxl={12} lg={12} xs={24}>
                  <div className="profile_name">
                    <label>User Goal</label>
                  </div>
                  <Row gutter={15}>
                    <Col xxl={8} lg={8} xs={24}>
                      <DatePicker onChange={selectStartDate} />
                    </Col>
                    <Col xxl={8} lg={8} xs={24}>
                      <DatePicker onChange={selectEndDate} />
                    </Col>
                    <Col xxl={8} lg={8} xs={24}>
                      <Button
                        type="primary"
                        style={{
                          width: "100%",
                          padding: "12px",
                          height: "inherit",
                        }}
                        htmlType="submit"
                        onClick={filterDateWise}
                      >
                        Filter Data
                      </Button>
                    </Col>
                  </Row>
                  {Goal && Goal.length > 0 ? (
                    <div className="undercoll">
                      <Table
                        columns={TableColumn}
                        pagination={false}
                        dataSource={Goal}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default userProfile;
