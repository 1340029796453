import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Modal,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
// import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import {
  exerciseGet,
  createExercise,
  exerciseDeleteList,
  updateExercise,
} from "../../../redux/exercisemaster/actionCreator";
import { format } from "date-fns";

const { API } = require("../../../config/api/index");
const ExerciseMaster = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [exerciseModel, setExerciseModel] = useState(false);
  const [exerciseId, setExerciseId] = useState();

  const [exerciseMaster, setExerciseMaster] = useState([]);

  useEffect(() => {
    exerciseGetList();
  }, []);

  const showModal = (id, value) => {
    setExerciseId(id);
    setExerciseModel(true);
    form1.setFieldsValue({
      exerciseName: value,
    });
  };

  const handleOk = async (value) => {
    const update = await dispatch(updateExercise(exerciseId, value));
    if (update) {
      exerciseGetList();
    }
    setExerciseModel(false);
  };

  const handleCancel = () => {
    setExerciseModel(false);
  };

  const addExercise = async (value) => {
    let create = await dispatch(createExercise(value));
    if (create) {
      exerciseGetList();
    }
    form.resetFields();
  };
  // Get Url api

  const exerciseGetList = async () => {
    const List = await dispatch(exerciseGet());
    if (List) {
      setExerciseMaster(List);
    }
  };

  // Deletelist api
  const deleteMaster = async (id) => {
    const deleted = await dispatch(exerciseDeleteList(id));
    if (deleted) {
      exerciseGetList();
    }
  };

  const columns = [
    {
      title: "Exercise Name",
      dataIndex: "exerciseName",
      key: "exerciseName",
      width: "80%",
    },
    {
      title: "Action",
      key: "action",
      render: (value) => (
        <div className="table-actions">
          <>
            <a
              className="btn-icon"
              to="#"
              shape="circle"
              onClick={() => showModal(value._id, value.exerciseName)}
            >
              <FeatherIcon icon="edit" size={16} />
            </a>
            <Popconfirm
              outlined
              type="warning"
              title="Are you sure to delete this List ?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteMaster(value._id)}
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ];
  return (
    <>
      <PageHeader ghost title="Exercise List" />
      <Main>
        <Row gutter={10}>
          <Col xxl={24} lg={24} md={24} xs={24}>
            <Cards title="Add Exercise ">
              <Form onFinish={addExercise} form={form}>
                <Form.Item
                  name="exerciseName"
                  rules={[
                    { required: true, message: "Please input your Exercise" },
                  ]}
                >
                  <Input placeholder="Add Exercise" />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Add
                  </Button>
                </Form.Item>
              </Form>
            </Cards>
          </Col>
        </Row>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Table
                  dataSource={exerciseMaster}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {/* <Modal
          title="Skill Update"
          visible={handleOk}
          onCancel={handleCancel}
          okText="Update"
        >
          <Form>
            <Form.Item name="skillName">
              <Input placeholder="Add Links" />
            </Form.Item>
          </Form>
        </Modal> */}
        <Modal
          title="Edit Exercise"
          visible={exerciseModel}
          onCancel={handleCancel}
          okText="Update"
          onOk={form1.submit}
        >
          <Form form={form1} onFinish={handleOk}>
            <Form.Item name="exerciseName">
              <Input placeholder="" />
            </Form.Item>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default ExerciseMaster;
