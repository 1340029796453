import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Select,
  Form,
  Popconfirm,
  message,
  Tabs,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { CardToolbox, Main } from "../../MasterAdmin/styled";
import { Modal, Button, Radio } from "antd";
import { NavLink } from "react-router-dom";
import { getItem } from "../../../utility/localStorageControl";
import { useDispatch } from "react-redux";
import useStateRef from "react-usestateref";
import _ from "lodash";
import ReactAudioPlayer from "react-audio-player";

import {
  audioGet,
  CreateAudio,
  updateSound,
  SoundDeleteById,
} from "../../../redux/soundmaster/actionCreator";
const { TextArea } = Input;

const SoundMaster = () => {
  let userdetails = getItem("userDetails");
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modelTitle, setModelTitle] = useState("Create Sound");
  const [soundData, setSoundData] = useState([]);
  const [changeTabValue, setChangeTabValue] = useState("Tick tok");
  const [previewMedia, setPreviewMedia] = useState("");
  const [soundId, setSoundId, setSoundIdRef] = useStateRef("");
  const { TabPane } = Tabs;
  const [selectedFile, setSelectedFile, setSelectedFileRef] = useStateRef("");
  const [deleteIconClick, setDeleteIconClick] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    // soundList();
    ChangeTab(changeTabValue);
  }, []);

  const ChangeTab = async (key) => {
    setChangeTabValue(key);
    const List = await dispatch(audioGet({ type: key }));
    if (List) {
      setSoundData(List);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const showModal = () => {
    setIsModalVisible(true);
    form.resetFields();
    setSoundId("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setPreviewMedia("");
  };

  const changeHandler = (event) => {
    if (event.target.files[0].type.includes("audio/mpeg")) {
      setPreviewMedia(
        // <audio controls>
        //   <source src={URL.createObjectURL(event.target.files[0])} />{" "}
        // </audio>
        <ReactAudioPlayer
          src={URL.createObjectURL(event.target.files[0])}
          controls
        />
      );
      setSelectedFile(event.target.files[0]);
    } else {
      form.setFieldsValue({ audio: "" });
      message.error("You can only upload IMAGE/GIF file!");
    }
  };

  const onsubmit = async (values) => {
    if (values.audio == undefined || values.audio == "undefined") {
      delete values.audio;
    }
    var form_data = new FormData();
    if (
      setSelectedFileRef.current != "" &&
      setSelectedFileRef.current != "" &&
      setSelectedFileRef.current != undefined
    ) {
      form_data.append("audio", setSelectedFileRef.current);
      delete values.audio;
    }

    for (var key in values) {
      form_data.append(key, values[key]);
    }

    if (setSoundIdRef.current !== "") {
      let update = await dispatch(
        updateSound(setSoundIdRef.current, form_data)
      );
      if (update) {
        setSelectedFile("");
        ChangeTab(changeTabValue);
      }
    } else {
      let create = await dispatch(CreateAudio(form_data));
      if (create) {
        ChangeTab(changeTabValue);
      }
    }
    setIsModalVisible(false);
    form.resetFields();
    setPreviewMedia("");
  };

  const handelUpdate = async (id) => {
    if (id != "") {
      let Data = _.find(soundData, { _id: id });
      form.setFieldsValue({
        title: Data.title,
      });
      form.setFieldsValue({
        title: Data.title,
        type: Data.type,
      });

      if (Data.audio && Data.audio != "") {
        setPreviewMedia(
          // <audio controls>
          //   <source src={Data.audio} />{" "}
          // </audio>
          <ReactAudioPlayer src={Data.audio} controls />
        );
      }

      setSoundId(id);
      setModelTitle("Update Sound");
      setIsModalVisible(true);
    }
  };

  const deleteSound = async (id) => {
    const deleted = await dispatch(SoundDeleteById(id));
    if (deleted) {
      ChangeTab(changeTabValue);
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Audio",
      dataIndex: "audio",
      // key: "audio",
      render: (key, value) => (
        <>
          <div>
            {value && value.audio !== undefined ? (
              // <audio controls crossOrigin="anonymous">
              //   <source src={value.audio} />
              // </audio>
              <ReactAudioPlayer src={value.audio} controls />
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    userdetails?.permission.resource.soundMaster == "view_all"
      ? {}
      : {
        title: "Action",
        key: "action",
        render: (text) => (
          <div className="table-actions">
            <>
              <a
                className="btn-icon"
                to="#"
                shape="circle"
                onClick={() => handelUpdate(text._id)}
              >
                <FeatherIcon icon="edit" size={16} />
              </a>
              <Popconfirm
                outlined
                type="warning"
                title="Are you sure to delete this List ?"
                // onConfirm={confirm}
                onCancel={cancel}
                onConfirm={() => deleteSound(text._id)}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">
                  {" "}
                  <FeatherIcon size={14} icon="trash-2" />
                </a>
              </Popconfirm>
            </>
          </div>
        ),
      },
  ];

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            title={`Sound List`}
            subTitle={
              <>
                <span
                  className="title-counter"
                  style={{ paddingRight: "20px" }}
                >
                </span>
              </>
            }
          />
        </CardToolbox>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards
              title="Sound Master List"
              isbutton={
                userdetails?.permission.resource.soundMaster ==
                  "view_all" ? null : (
                  <div className="card-radio">
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={showModal}
                    >
                      + Add Sound
                    </Button>
                  </div>
                )
              }
            >
              <Tabs defaultActiveKey="Tick tok" onChange={ChangeTab}>
                <TabPane tab="Tick Tok" key="Tick tok">
                  <div className="table-order table-responsive">
                    <Table dataSource={soundData} columns={columns} />
                  </div>
                </TabPane>
                <TabPane tab="Exercise" key="Exercise">
                  <div className="table-order table-responsive">
                    <Table dataSource={soundData} columns={columns} />
                  </div>
                </TabPane>
              </Tabs>
            </Cards>
          </Col>
        </Row>
        <Modal
          onOk={form.submit}
          title={modelTitle}
          visible={isModalVisible}
          onCancel={handleCancel}
          okText="Submit"
        >
          <Form layout="vertical" form={form} onFinish={onsubmit}>
            <Row gutter={10}>
              <Col xxl={24} lg={24} xs={24}>
                <Form.Item label="Title" name="title" initialValue="">
                  <Input placeholder="Add Title" />
                </Form.Item>
                <Form.Item label="Select Type" name="type">
                  <Select style={{ width: "100%" }}>
                    <Select.Option value="Exercise">Exercise</Select.Option>
                    <Select.Option value="Tick tok">Tick tok</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="audio" label="Select Audio">
                  <Input
                    type="file"
                    placeholder="Image"
                    title=""
                    id="audio"
                    accept="audio/*"
                    onChange={changeHandler}
                  />
                </Form.Item>
                <Form.Item>
                  <Col span={12}>
                    <div className="img_prev_game">{previewMedia}</div>
                  </Col>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default SoundMaster;
