import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Tag,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../MasterAdmin/styled";
import Heading from "../../../components/heading/heading";
import { List } from "../../../components/pricing/pricing";
import _ from "lodash";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import useStateRef from "react-usestateref";
import {
  PricingCard,
  ListGroup,
  Badge,
} from "../../../container/MasterAdmin/Subscription/style";
const { API } = require("../../../config/api/index");
import {
  subscriptionList,
  createSubscription,
  subscriptionDelete,
  updateSubscription,
} from "../../../redux/subscription/actionCreator";
import { number } from "prop-types";
import { format } from "date-fns";

const Subscription = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [editModel, setEditModel] = useState(false);
  const dispatch = useDispatch();
  const [
    subscriptionId,
    setSubscriptionId,
    setSubscriptionIdRef,
  ] = useStateRef();

  const [subscriptionStore, setSubscriptionStore] = useState();

  const [subscriptionModel, setSubscription] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    subscriptionGet();
  }, []);

  const showEditModal = async (id) => {
    setSubscriptionId(id);
    const subscriptionLists = _.find(subscriptionStore, { _id: id });
    setTags(subscriptionLists.features);
    form.setFieldsValue({
      name: subscriptionLists.name,
      price: subscriptionLists.price,
      timing: subscriptionLists.timing,
      duration: subscriptionLists.duration,
      status: subscriptionLists.status,
      features: subscriptionLists.features,
    });
    setEditModel(true);
  };

  const editHandleOk = async (value) => {
    value.features = tags;

    const update = await dispatch(
      updateSubscription(setSubscriptionIdRef.current, value)
    );
    if (update) {
      subscriptionGet();
    }

    setEditModel(false);
  };

  const editHandleCancel = () => {
    setEditModel(false);
  };

  const showModal = () => {
    setSubscription(true);
    form1.resetFields();
    setTags([]);
  };
  const handleOk = async (value) => {
    value.features = tags;
    const Add = await dispatch(createSubscription(value));
    if (Add) {
      subscriptionGet();
    }
    setSubscription(false);
    form1.resetFields();
  };

  const handleCancel = () => {
    setSubscription(false);
    form1.resetFields();
    setTags([]);
  };

  // Deletelist api
  const Deletelist = async (id) => {
    const deleted = await dispatch(subscriptionDelete(id));
    if (deleted) {
      subscriptionGet();
    }
  };

  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setTags([...tags, val]);
      inputValue.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  const subscriptionGet = async () => {
    const List = await dispatch(subscriptionList());
    if (List) {
      setSubscriptionStore(List);
    }
  };

  return (
    <>
      <PageHeader ghost title="Pricing Table" />
      <Main>
        <Cards
          title="Add Pricing Data"
          isbutton={
            <div className="card-radio">
              <Button htmlType="submit" type="primary" onClick={showModal}>
                + Add Subscription Plans
              </Button>
            </div>
          }
        >
          <Row gutter={25}>
            {subscriptionStore && subscriptionStore.length > 0
              ? subscriptionStore.map((value) => {
                return (
                  <>
                    <Col xxl={6} lg={8} sm={12} xs={24}>
                      <PricingCard style={{ marginBottom: 30 }}>
                        <div>
                          <span>
                            {" "}
                            Status:<br></br>
                            <Tag
                              style={{ color: "black", marginBottom: "10px" }}
                              color={
                                value.status && value.status
                                  ? value.status == "active"
                                    ? "#87d068"
                                    : " #ff7875"
                                  : ""
                              }
                            >
                              {value.status}
                            </Tag>
                          </span>
                        </div>

                        <Badge className="pricing-badge" type="primary">
                          {value.name}
                        </Badge>

                        <Heading className="price-amount" as="h3">
                          <sup className="currency">{value.currency}</sup>
                          {value.price}{" "}
                          <sub className="pricing-validity">
                            {" "}
                            {value.timing}
                          </sub>
                        </Heading>

                        <ListGroup>
                          {value.features && value.features.length > 0
                            ? value.features.map((name) => {
                              return (
                                <>
                                  <List text={name} />
                                </>
                              );
                            })
                            : ""}
                        </ListGroup>
                        <Button
                          style={{ padding: "10px", marginRight: "10px" }}
                        >
                          <Popconfirm
                            outlined
                            type="warning"
                            title="Are you sure to delete this List ?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => Deletelist(value._id)}
                          >
                            <a href="#">
                              {" "}
                              <FeatherIcon size={14} icon="trash-2" />
                              Delete
                            </a>
                          </Popconfirm>
                        </Button>
                        <Button
                          outlined
                          type="success"
                          onClick={() => showEditModal(value._id)}
                          style={{ padding: "10px", marginRight: "3px" }}
                        >
                          <FeatherIcon size={14} icon="edit" />
                          Edit
                        </Button>
                      </PricingCard>
                    </Col>
                  </>
                );
              })
              : ""}
          </Row>
        </Cards>
        {/* add data details  */}
        <Modal
          title="Edit Subscription"
          visible={subscriptionModel}
          onOk={form1.submit}
          okText="Add"
          onCancel={handleCancel}
        >
          <Form layout="vertical" onFinish={handleOk} form={form1}>
            <Row gutter={25} align="middle">
              <Col xxl={12} lg={12} sm={12} xs={24}>
                <Form.Item
                  name="name"
                  label="Add Subscription Plan"
                  rules={[{ required: true, message: "Add Plan Name" }]}
                >
                  <Input input="text" placeholder="Add Plan Name" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} sm={12} xs={24}>
                <Form.Item
                  name="price"
                  label="Add Price"
                  rules={[{ required: true, message: "Add Price" }]}
                >
                  <Input type="number" placeholder="Add Price" />
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} sm={8} xs={24}>
                <Form.Item
                  name="timing"
                  label="Add timing"
                  rules={[{ required: true, message: "Add timing" }]}
                >
                  <Select placeholder="Select timing">
                    <Option value="week">week</Option>
                    <Option value="month">month</Option>
                    <Option value="year">year</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} sm={8} xs={24}>
                <Form.Item
                  name="duration"
                  label="Add Duration"
                  rules={[{ required: true, message: "Add Duration" }]}
                >
                  <Input type="number" placeholder="Add Duration" />
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} sm={8} xs={24}>
                <Form.Item
                  name="status"
                  label="Select Status"
                  rules={[{ required: true, message: "Add Duration" }]}
                >
                  <Select placeholder="Select Status">
                    <Option value="active">active</Option>
                    <Option value="cancelled">cancelled</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xxl={24} lg={24} sm={24} xs={24}>
                <label
                  style={{
                    fontWeight: "500",
                    marginBottom: "8px",
                    display: "inline-block",
                  }}
                >
                  Add Features{" "}
                  <span
                    style={{
                      color: "#f00",
                    }}
                  >
                    (press enter after add text)
                  </span>
                </label>
                <div style={{ width: "100%" }}>
                  <div className="input-tag">
                    <ul className="input-tag__tags">
                      {tags.map((tag, i) => (
                        <li key={tag}>
                          {tag}
                          <button
                            className="adFer"
                            type="button"
                            onClick={() => {
                              removeTag(i);
                            }}
                          >
                            +
                          </button>
                        </li>
                      ))}
                      <li className="input-tag__tags__input">
                        <input
                          type="text"
                          onKeyDown={inputKeyDown}
                          ref={(c) => {
                            setInputValue(c);
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
        {/* add data details  */}
        {/* Edit data details  */}
        <Modal
          title="Basic Modal"
          visible={editModel}
          // onOk={editHandleOk}
          onCancel={editHandleCancel}
          onOk={form.submit}
        >
          <Form layout="vertical" onFinish={editHandleOk} form={form}>
            <Row gutter={25} align="middle">
              <Col xxl={12} lg={12} sm={12} xs={24}>
                <Form.Item
                  name="name"
                  label="Add Subscription Plan"
                  rules={[{ required: true, message: "Add Plan Name" }]}
                >
                  <Input input="text" placeholder="Add Plan Name" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} sm={12} xs={24}>
                <Form.Item
                  name="price"
                  label="Add Price"
                  rules={[{ required: true, message: "Add Price" }]}
                >
                  <Input type="number" placeholder="Add Price" />
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} sm={8} xs={24}>
                <Form.Item
                  name="timing"
                  label="Add timing"
                  rules={[{ required: true, message: "Add timing" }]}
                >
                  <Select placeholder="Select timing">
                    <Option value="week">week</Option>
                    <Option value="month">month</Option>
                    <Option value="year">year</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} sm={8} xs={24}>
                <Form.Item
                  name="duration"
                  label="Add Duration"
                  rules={[{ required: true, message: "Add Duration" }]}
                >
                  <Input type="number" placeholder="Add Duration" />
                </Form.Item>
              </Col>
              <Col xxl={8} lg={8} sm={8} xs={24}>
                <Form.Item
                  name="status"
                  label="Select Status"
                  rules={[{ required: true, message: "Add Duration" }]}
                >
                  <Select placeholder="Select Status">
                    <Option value="active">active</Option>
                    <Option value="cancelled">cancelled</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xxl={24} lg={24} sm={24} xs={24}>
                <label
                  style={{
                    fontWeight: "500",
                    marginBottom: "8px",
                    display: "inline-block",
                  }}
                >
                  Add Features{" "}
                  <span
                    style={{
                      color: "#f00",
                    }}
                  >
                    (press enter after add text)
                  </span>
                </label>
                <div style={{ width: "100%" }}>
                  <div className="input-tag">
                    <ul className="input-tag__tags">
                      {tags.map((tag, i) => (
                        <li key={tag}>
                          {tag}
                          <button
                            className="adFer"
                            type="button"
                            onClick={() => {
                              removeTag(i);
                            }}
                          >
                            +
                          </button>
                        </li>
                      ))}
                      <li className="input-tag__tags__input">
                        <input
                          type="text"
                          onKeyDown={inputKeyDown}
                          ref={(c) => {
                            setInputValue(c);
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
        {/* Edit data details  */}
      </Main>
    </>
  );
};

export default Subscription;
