const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const userListHours = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });

    const resp = await DataService.get(
      API.usersHours.usersHoursList + queryString
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const GetPsychometric = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });

    const resp = await DataService.get(
      API.usersHours.usersPsychometric + queryString
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const referralCodeList = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });

    const resp = await DataService.get(
      API.usersHours.referralCode + queryString
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
