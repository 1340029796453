const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";
import actions from "./actions";

const {
    DetailsByID
} = actions;

const getMasterAdminDetails = (id) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.masterAdminDetailsByID.getById, { id: id });
        if (!resp.data.error) {
            dispatch(DetailsByID(resp.data.data))
            return resp.data.data;
        } else {
            message.error(resp.data.message);
        }
    };
};


export { getMasterAdminDetails };
