import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Modal,
} from "antd";
import { Cards } from "../../../components/cards/frame/cards-frame";

import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main } from "../../MasterAdmin/styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  link,
} from "suneditor/src/plugins";

const { API } = require("../../../config/api/index");

import { TermsList, updateTerms } from "../../../redux/terms/actionCreator";

const terms = () => {
  const dispatch = useDispatch();
  const [termsGet, setTermsGet] = useState({});
  const [termsId, setTermsId] = useState({});
  useEffect(() => {
    termsAdd();
  }, []);

  const termsAdd = async () => {
    const List = await dispatch(TermsList());
    if (List) {
      setTermsGet(List);
      setTermsId(List._id);
    }
  };

  const editTerms = async (value) => {
    let payloads = {
      id: termsId,
      terms: value.terms,
    };

    const update = await dispatch(updateTerms(payloads));
    if (update) {
      setTermsGet(update);
      setTermsId(update._id);
    }
  };
  return (
    <>
      <PageHeader ghost />
      <Main>
        <Row gutter={10}>
          <Col xxl={24} lg={24} md={24} xs={24}>
            <Form onFinish={editTerms}>
              <Cards
                title="Terms and services"
                isbutton={
                  <div className="card-radio">
                    <Button htmlType="submit" type="primary">
                      Save
                    </Button>
                  </div>
                }
              >
                <Form.Item name="terms">
                  <SunEditor
                    setContents={termsGet.terms}
                    setOptions={{
                      minHeight: "200px",
                      maxHeight: "200px",
                      plugins: [
                        align,
                        font,
                        fontColor,
                        fontSize,
                        formatBlock,
                        hiliteColor,
                        horizontalRule,
                        lineHeight,
                        list,
                        paragraphStyle,
                        table,
                        template,
                        textStyle,

                        link,
                      ],
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["paragraphStyle"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                        ],
                        ["fontColor", "hiliteColor"],
                        ["removeFormat"],
                        "/",
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["table", "link"],
                      ],
                      formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                      font: [
                        "Arial",
                        "Calibri",
                        "Comic Sans",
                        "Courier",
                        "Garamond",
                        "Georgia",
                        "Impact",
                        "Lucida Console",
                        "Palatino Linotype",
                        "Segoe UI",
                        "Tahoma",
                        "Times New Roman",
                        "Trebuchet MS",
                      ],
                    }}
                  />
                </Form.Item>
                {/* </form> */}
              </Cards>
            </Form>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default terms;
