const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const subscriptionList = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.subscription.subscriptionGet);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const createSubscription = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.subscription.subscriptionAdd,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const subscriptionDelete = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.subscription.deleteSubscription + "/" + id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const updateSubscription = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.subscription.subscriptionUpdate + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
