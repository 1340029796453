const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const Moodlist = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.addmood.moodlist);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const Deletrmood = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.addmood.deletemood + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const Addmoods = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.addmood.moodadd, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const Updatemood = (_id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.addmood.upatemood + "/" + _id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
