import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Modal,
  Select,
  DatePicker,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { GetPsychometric } from "../../../redux/usershours/actionCreator";
import { format } from "date-fns";
import { Excel } from "antd-table-saveas-excel";
import moment from "moment";
const { API } = require("../../../config/api/index");

const getpsychometric = () => {
  const dispatch = useDispatch();
  const [usersHoursList, setUsersHours] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { RangePicker } = DatePicker;

  useEffect(() => {
    userDataHours();
  }, []);

  // Get Url api

  const renderContent = (value, index) => {
    let obj = {
      children: value,
      props: {},
    };
    return obj;
  };

  const userDataHours = async () => {
    const query = {
      startDate: startDate,
      endDate: endDate,
    };
    let List = await dispatch(GetPsychometric(query));
    if (List) {
      let sortData = [];
      for (let customData of List) {
        customData.qna.option1 = "";
        customData.qna.option2 = "";
        customData.qna.option3 = "";
        customData.qna.option4 = "";
        if (customData.qna.options && customData.qna.options.length > 0) {
          let count = 1;
          for (let innerOption of customData.qna.options) {
            customData.qna[
              "option" + count
            ] = `${innerOption.option} / ${innerOption.count}`;
            count = count + 1;
          }
        }
      }
      setUsersHours(List);
    }
  };

  const startDateFilter = async (date) => {
    let abc = moment(date).format("YYYY-MM-DD");
    setStartDate(abc);
  };
  const endDateFilter = async (date) => {
    let abc = moment(date).format("YYYY-MM-DD");
    setEndDate(abc);
  };

  const onClick = async () => {
    const query = {
      startDate: startDate,
      endDate: endDate,
    };
    const List = await dispatch(GetPsychometric(query));
    if (List) {
      let sortData = [];
      for (let customData of List) {
        customData.qna.option1 = "";
        customData.qna.option2 = "";
        customData.qna.option3 = "";
        customData.qna.option4 = "";
        if (customData.qna.options && customData.qna.options.length > 0) {
          let count = 1;
          for (let innerOption of customData.qna.options) {
            customData.qna[
              "option" + count
            ] = `${innerOption.option} / ${innerOption.count}`;
            count = count + 1;
          }
        }
      }
      setUsersHours(List);
    }
  };

  const columns = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",

      sorter: (a, b) =>
        (a.groupTypeId ? a.groupTypeId.groupName : "").localeCompare(
          b.groupTypeId ? b.groupTypeId.groupName : ""
        ),
      render: (key, value) => renderContent(value.groupTypeId.groupName),
    },
    {
      title: "Question",
      dataIndex: "qna",
      key: "qna",
      sorter: (a, b) =>
        (a.qna ? a.qna.question : "").localeCompare(
          b.qna ? b.qna.question : ""
        ),
      render: (key, value) => renderContent(value.qna.question),
    },
    {
      title: "Option/Count A",
      dataIndex: "qna",
      key: "OptionA",
      render: (key, value) => renderContent(value.qna.option1),
    },
    {
      title: "Option/Count B",
      dataIndex: "qna",
      key: "OptionB",
      render: (key, value) => renderContent(value.qna.option2),
    },
    {
      title: "Option/Count C",
      dataIndex: "qna",
      key: "OptionC",
      render: (key, value) => renderContent(value.qna.option3),
    },
    {
      title: "Option/Count D",
      dataIndex: "qna",
      key: "OptionD",
      render: (key, value) => renderContent(value.qna.option4),
    },
  ];

  return (
    <>
      <PageHeader ghost title="Psychometric List" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                {/* <Button
                  style={{
                    marginBottom: 20,
                  }}
                  onClick={() => {
                    const excel = new Excel();
                    excel
                      .addSheet("test")
                      .addColumns(columns)
                      .addDataSource(usersHoursList)
                      .saveAs("file.xlsx");
                  }}
                >
                  Export Excel
                </Button> */}
                <Row gutter={10} align="middle" style={{ marginBottom: 10 }}>
                  <Col xxl={8} lg={8} xs={24}>
                    <DatePicker
                      placeholder="Select Start Date"
                      onChange={startDateFilter}
                    />
                  </Col>
                  <Col xxl={8} lg={8} xs={24}>
                    <DatePicker
                      placeholder="Select End Date"
                      onChange={endDateFilter}
                    />
                  </Col>
                  <Col xxl={4} lg={4} xs={24}>
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        padding: "12px",
                        height: "inherit",
                      }}
                      htmlType="submit"
                      onClick={onClick}
                    >
                      Filter Data
                    </Button>
                  </Col>
                  <Col xxl={4} lg={4} xs={24}>
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        padding: "12px",
                        height: "inherit",
                      }}
                      onClick={() => {
                        const excel = new Excel();
                        excel
                          .addSheet("test")
                          .addColumns(columns)
                          .addDataSource(usersHoursList)
                          .saveAs("file.xlsx");
                      }}
                    >
                      Export Excel
                    </Button>
                  </Col>
                </Row>

                <Table
                  dataSource={usersHoursList}
                  pagination={true}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default getpsychometric;
