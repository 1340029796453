const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const userSubscriptionDetails = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });
    const resp = await DataService.get(
      API.userSubscription.userSubscriptionList + queryString
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const SubscriptionUpdate = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.userSubscription.userSubscriptionUpdate,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
