import Cookies from "js-cookie";
import actions from "./actions";

const {
  USER_LIST_BEGIN,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,
  BLOCKED_USER_LIST_SUCCESS,
  ACTIVE_USER_LIST_SUCCESS,
} = actions;

const initState = {
  users: [],
  activeUsers: [],
  blockedUsers: [],
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AdminReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        users: data,
      };
    case USER_LIST_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ACTIVE_USER_LIST_SUCCESS:
      return {
        ...state,
        activeUsers: data,
      };

    case BLOCKED_USER_LIST_SUCCESS:
      return {
        ...state,
        blockedUsers: data,
      };
    default:
      return state;
  }
};
export default AdminReducer;
