import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Upload, Select, Card, Avatar } from "antd";
import FeatherIcon from "feather-icons-react";
import { BasicFormWrapper } from "../styled";
import { Button } from "../../../components/buttons/buttons";
import { PageHeader } from "../../../components/page-headers/page-headers";
import {
  NavLink,
  useRouteMatch,
  useParams,
  useHistory,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import { Main } from "../styled";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAuthorBox } from "../../profile/settings/overview/style";
import { getItem } from "../../../utility/localStorageControl";
import { getMasterAdminDetails } from "../../../redux/masterAdmin/actionCreator";
import {
  GetOnlyDateFormat,
  getRandomColor,
} from "../../../utility/commonFunction";
import { UserOutlined } from "@ant-design/icons";
import "./style.css";

const { Option } = Select;

const myProfile = () => {
  const dispatch = useDispatch();
  const userDetails = getItem("userDetails");
  const [form] = Form.useForm();

  let AdminDetails = useSelector((state) => state.masterAdminDetails.Details);
  if (AdminDetails) {
    form.setFieldsValue(AdminDetails);
  }

  useEffect(() => {
    getInformation();
  }, []);

  const getInformation = async () => {
    await dispatch(getMasterAdminDetails(userDetails._id));
  };

  return (
    <>
      <PageHeader ghost title="Personal Information" />
      <Main>
        <Row gutter={25}>
          <Col xl={24} md={24} xs={24}>
            <ProfileAuthorBox>
              <Card>
                <div className="user-info-form author-info">
                  <BasicFormWrapper>
                    <Form form={form} name="info">
                      <div>
                        <img
                          className="img_profile"
                          crossOrigin="anonymous"
                          src={
                            AdminDetails && AdminDetails.profile_pic ? (
                              AdminDetails.profile_pic
                            ) : (
                              <Avatar size="large" icon={<UserOutlined />} />
                            )
                          }
                          alt=""
                        />
                      </div>

                      <Row gutter={10}>
                        <Col xl={12} md={12} xs={12}>
                          <Form.Item label="First Name" name="first_name">
                            <Input disabled placeholder="Input First Name" />
                          </Form.Item>
                        </Col>

                        <Col xl={12} md={12} xs={12}>
                          <Form.Item label="Last Name" name="last_name">
                            <Input disabled placeholder="Input Last Name" />
                          </Form.Item>
                        </Col>

                        <Col
                          xl={12}
                          md={12}
                          xs={12}
                          style={{ marginTop: "15px" }}
                        >
                          <Form.Item label="Email Address" name="email">
                            <Input placeholder="name@example.com" disabled />
                          </Form.Item>
                        </Col>

                        {AdminDetails && AdminDetails.mobile ? (
                          <Col xl={12} md={12} xs={12}>
                            <Form.Item name="phone" label="Phone Number">
                              <Input placeholder="+440 2546 5236" />
                            </Form.Item>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col
                          xl={12}
                          md={12}
                          xs={12}
                          style={{ marginTop: "15px" }}
                        >
                          <Form.Item
                            label="Date Of Registration"
                          // name="created_at"
                          >
                            <Input
                              value={
                                AdminDetails && AdminDetails.created_at
                                  ? GetOnlyDateFormat(AdminDetails.created_at)
                                  : ""
                              }
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </BasicFormWrapper>
                </div>
              </Card>
            </ProfileAuthorBox>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default myProfile;
