import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Modal,
  Popconfirm,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getItem } from "../../../utility/localStorageControl";
import {
  GroupDetailsUrlLits,
  AddGroup,
  UpdateGroup,
  GroupDelete,
} from "../../../redux/questiongroup/actionCreator";

const { API } = require("../../../config/api/index");
const questionGroup = () => {
  let userdetails = getItem("userDetails");
  const [dataSource, SetdataSource] = useState([]);

  const dispatch = useDispatch();
  const [form1] = Form.useForm();
  const [form] = Form.useForm();
  const [GroupModal, setGroupModal] = useState(false);
  const [GroupId, setGroupId] = useState();
  useEffect(() => {
    GetQuestionGroup();
  }, []);

  const showModal = (id, value) => {
    setGroupId(id);
    setGroupModal(true);
    form1.setFieldsValue({
      groupName: value,
    });
  };

  const handleOk = async (value) => {
    const update = await dispatch(UpdateGroup(GroupId, value));
    if (update) {
      GetQuestionGroup();
    }
    setGroupModal(false);
  };

  const handleCancel = () => {
    setGroupModal(false);
  };

  // Get Url api
  const GetQuestionGroup = async () => {
    const List = await dispatch(GroupDetailsUrlLits());
    if (List) {
      SetdataSource(List);
    }
  };

  const OnSubmit = async (value) => {
    const create = await dispatch(AddGroup(value));
    if (create) {
      GetQuestionGroup();
    }
    form.resetFields();
  };

  const DeleteGroup = async (value) => {
    const deleted = await dispatch(GroupDelete(value));
    if (deleted) {
      GetQuestionGroup();
    }
  };

  const columns = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      width: "80%",
    },
    userdetails?.permission.resource.questionGroupType == "view_all"
      ? {}
      : {
        title: "Action",
        key: "action",
        render: (ket, value) => (
          <>
            <a
              className="btn-icon"
              to="#"
              shape="circle"
              onClick={() => showModal(value._id, value.groupName)}
            >
              <FeatherIcon icon="edit" size={16} />
            </a>

            <Popconfirm
              outlined
              type="warning"
              title="Are you sure to delete this Link?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => DeleteGroup(value._id)}
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        ),
      },
  ];

  return (
    <>
      <PageHeader ghost title="Question Group Type" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} md={24}>
            {userdetails?.permission.resource.questionGroupType ==
              "view_all" ? (
              ""
            ) : (
              <Cards title="Add Your Question Group">
                <Form onFinish={OnSubmit} form={form}>
                  <Form.Item
                    name="groupName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Question Group",
                      },
                    ]}
                  >
                    <Input placeholder="Add Question Group" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="default"
                      type="primary"
                      key="submit"
                    >
                      Add
                    </Button>
                  </Form.Item>
                </Form>
              </Cards>
            )}
          </Col>
        </Row>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Table
                  dataSource={dataSource}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          title="Edit Group Name"
          visible={GroupModal}
          onCancel={handleCancel}
          okText="Update"
          onOk={form1.submit}
        >
          <Form form={form1} onFinish={handleOk}>
            <Form.Item
              name="groupName"
              rules={[
                {
                  required: true,
                  message: "Please input your Question Group",
                },
              ]}
            >
              <Input placeholder="Edit Question Group" />
            </Form.Item>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default questionGroup;
