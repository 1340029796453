const actions = {

  TEST_LIST_SUCCESS: 'TEST_LIST_SUCCESS',

  testListSuccess: data => {
    return {
      type: actions.TEST_LIST_SUCCESS,
      data,
    };
  },

};

export default actions;
