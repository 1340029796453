const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

//redeem list api call
export const List = () => {
    return async (dispatch) => {
        const resp = await DataService.get(API.RedeemTable.data);
        if (!resp.data.error) {
            return resp.data.data;
        } else {
            message.error(resp.data.message);
        }
    };
};

//redeem Update api call
export const Update = (data) => {
    return async (dispatch) => {
        const resp = await DataService.post(API.RedeemTable.update, data);
        if (!resp.data.error) {
            return resp.data.data;
        } else {
            message.error(resp.data.message);
        }
    };
};