import React, { useState, useEffect } from "react";
import { Row, Col, Table, Input, Checkbox } from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { Modal, Button, Radio, Form, Select, Popconfirm } from "antd";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  getGroupList,
  UserList,
  BroadcastList,
  BroadcastAdd,
} from "../../../redux/broadcast/actionCreator";
const { API } = require("../../../config/api/index");
const Broadcast = () => {
  const dispatch = useDispatch();
  const [editModel, setEditModel] = useState(false);
  const [addQuestion, setaAdQuestion] = useState(false);
  const [selectUser, setSelectUser] = useState(1);
  const [getUserList, setGetUserList] = useState([]);
  const [broadcastTable, SetBroadcastTable] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [getGroupListId, setGetGroupListId] = useState([]);

  const { TextArea } = Input;
  const [form1] = Form.useForm();
  useEffect(() => {
    GroupLists();
    GetUserListRole();
    ListOfBroadcast();
  }, []);

  // Get User Url api

  const GroupLists = async () => {
    const List = await dispatch(getGroupList());

    if (List) {
      setGetGroupListId(List);
    }
  };

  // const onCheckAllChange = async (e) => {
  //   setCheckAll(true);
  // };

  // Get User Url api

  const GetUserListRole = async () => {
    let payloads = {
      status: "active",
      role: "user",
    };

    const List = await dispatch(UserList(payloads));

    if (List) {
      setGetUserList(List);
    }
  };

  // Get Data Url api

  const ListOfBroadcast = async () => {
    const List = await dispatch(BroadcastList());
    if (List) {
      SetBroadcastTable(List);
    }
  };

  const onSubmit = async (value) => {
    let multipleUsersIds = [];

    if (value.selectValue == undefined) {
      delete value.selectValue;
    }
    if (value.users) {
      delete value.groups;
      value.users = JSON.stringify(value.users);
    }

    if (value.groups) {
      delete value.users;
      value.groups = JSON.stringify(value.groups);
    }

    if (selectUser === 1) {
      getUserList.map((data) => {
        multipleUsersIds.push(data._id);
      });
      delete value.groups;
      value.users = JSON.stringify(multipleUsersIds);
    }
    const add = await dispatch(BroadcastAdd(value));
    if (add) {
      ListOfBroadcast();
    }
    form1.resetFields();
    setaAdQuestion(false);
    setCheckAll(false);
  };

  const showQue = () => {
    setSelectUser(1);
    setaAdQuestion(true);
    setCheckAll(false);
  };

  const handleCancelquestion = () => {
    form1.resetFields();
    setSelectUser(1);
    setaAdQuestion(false);
    setCheckAll(false);
  };

  const valueChange = (e) => {
    setSelectUser(e.target.value);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];
  return (
    <>
      <PageHeader ghost title="Notifications" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards
              title="Notifications List"
              isbutton={
                <div className="card-radio">
                  <Button htmlType="submit" type="primary" onClick={showQue}>
                    + Add Notifications
                  </Button>
                </div>
              }
            >
              <div className="table-order table-responsive">
                <Table dataSource={broadcastTable} columns={columns} />
              </div>
            </Cards>
          </Col>

          {/* Add Notifications model */}
          <Modal
            title="Add Notifications"
            visible={addQuestion}
            onCancel={handleCancelquestion}
            okText="Update"
            onOk={form1.submit}
          >
            <Form layout="vertical" onFinish={onSubmit} form={form1}>
              <div className="questionedit">
                <Row gutter={10}>
                  <Col xxl={24} lg={24} xs={24}>
                    <Form.Item
                      name="title"
                      label="Add Title"
                      rules={[
                        {
                          required: true,
                          message: "Please input Title",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Add Title" />
                    </Form.Item>
                  </Col>
                  <Col xxl={24} lg={24} xs={24}>
                    <Form.Item
                      name="description"
                      label="Add Description"
                      rules={[
                        {
                          required: true,
                          message: "Please input Title",
                        },
                      ]}
                    >
                      <TextArea type="text" placeholder="Add Description" />
                    </Form.Item>
                  </Col>
                  <Col xxl={24} lg={24} xs={24}>
                    <Form.Item
                      name="selectValue"
                      label="Select User / Select Group"
                    >
                      <>
                        <Radio.Group onChange={valueChange} value={selectUser}>
                          <Radio value={1}>All Users</Radio>
                          <Radio value={2}>User List</Radio>
                          <Radio value={3}>Group List</Radio>
                        </Radio.Group>
                      </>
                    </Form.Item>
                  </Col>
                  <Col xxl={24} lg={24} xs={24}>
                    {selectUser == 2 ? (
                      <Col xxl={24} lg={24} xs={24}>
                        <Form.Item
                          name="users"
                          label="User List"
                          rules={[
                            {
                              required: true,
                              message: "Please select User",
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder="Select User List"
                          >
                            {getUserList.map((data) => {
                              return (
                                <Select.Option value={data._id}>
                                  {`${
                                    data.first_name ? data.first_name : "-"
                                  } ${data.last_name ? data.last_name : "-"}`}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : selectUser == 3 ? (
                      <Col xxl={24} lg={24} xs={24}>
                        <Form.Item
                          name="groups"
                          label="Group List"
                          rules={[
                            {
                              required: true,
                              message: "Please Group User",
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder="Select Group List"
                          >
                            {getGroupListId.map((data) => {
                              return (
                                <Select.Option value={data._id}>
                                  {data.group_name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <br></br>
                <br></br>
              </div>
            </Form>
          </Modal>
          {/* Add Notifications model */}
        </Row>
      </Main>
    </>
  );
};

export default Broadcast;
