import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Select,
  Popconfirm,
  Modal,
  message,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { useDispatch } from "react-redux";

import {
  feedbackGet,
  createFeedbacks,
  feedbackDeleteList,
  updateFeedback,
} from "../../../redux/feedbackmaster/actionCreator";
// import { format } from "date-fns";

const { API } = require("../../../config/api/index");
const FeedbackMaster = () => {
  const dispatch = useDispatch();
  const [feedbackMaster, setFeedbackMaster] = useState([]);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [feedbackId, setFeedbackId] = useState("");
  const [FeedbackValue, setFeedbackValue] = useState("");
  const [feedbackModel, setFeedbackModel] = useState(false);

  useEffect(() => {
    feedbackList();
  }, []);

  const showModal = (value) => {
    setFeedbackId(value._id);
    setFeedbackModel(true);

    form1.setFieldsValue({
      question: value.question,
      type: value.type,
    });
  };

  const handleOk = async (value) => {
    let update = await dispatch(updateFeedback(feedbackId, value));

    if (update) {
      feedbackList();
    }
    setFeedbackModel(false);
  };

  const handleCancel = () => {
    setFeedbackModel(false);
  };

  const addFeedback = async (value) => {
    let err = false;
    if (FeedbackValue == "rating") {
      let result = feedbackMaster.filter((element) => element.type == "rating");
      if (result.length > 2) {
        err = true;
        message.error("You Are Not Able To Add Rating Question More Then 3");
      }
    } else {
      let result = feedbackMaster.filter((element) => element.type == "answer");
      if (result.length > 0) {
        err = true;
        message.error("You Are Not Able To Add Answer Question More Then 1");
      }
    }
    if (err == false) {
      let create = await dispatch(createFeedbacks(value));

      if (create) {
        feedbackList();
      }
    }
  };

  const feedbackList = async () => {
    const List = await dispatch(feedbackGet());
    if (List) {
      setFeedbackMaster(List);
    }
    form.resetFields();
  };
  const deleteItem = async (id) => {
    const deletes = await dispatch(feedbackDeleteList(id));
    if (deletes) {
      feedbackList();
    }
  };

  const GetTypeValue = (value) => {
    setFeedbackValue(value);
    if (value == "rating") {
      let result = feedbackMaster.filter((element) => element.type == "rating");
      if (result.length > 2) {
        message.error("You Are Not Able To Add Rating Question More Then 3");
      }
    } else {
      let result = feedbackMaster.filter((element) => element.type == "answer");
      if (result.length > 0) {
        message.error("You Are Not Able To Add Answer Question More Then 1");
      }
    }
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      width: "60%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Action",
      key: "action",
      render: (value) => (
        <div className="table-actions">
          <>
            <a
              className="btn-icon"
              to="#"
              shape="circle"
              onClick={() => showModal(value)}
            >
              <FeatherIcon icon="edit" size={16} />
            </a>
            {/* <Popconfirm
              outlined
              type="warning"
              title="Are you sure to delete this List ?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteItem(value._id)}
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm> */}
          </>
        </div>
      ),
    },
  ];
  return (
    <>
      <PageHeader ghost title="Feedback Master" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Table
                  dataSource={feedbackMaster}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          title="Edit Feedback"
          visible={feedbackModel}
          onCancel={handleCancel}
          okText="Update"
          onOk={form1.submit}
        >
          <Form form={form1} onFinish={handleOk}>
            <Form.Item name="question">
              <Input placeholder="" />
            </Form.Item>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default FeedbackMaster;
