import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";

import AboutUs from "../../container/AdminRole/Aboutus/aboutus";
import myActivity from "../../container/AdminRole/Activity/myActivity";
import AdminDetails from "../../container/AdminRole/AdminRoleManagement/AdminDetails";
import BlockedUsers from "../../container/AdminRole/BlockedUsers/BlockedUsers";
import Broadcast from "../../container/AdminRole/broadcast/broadcast";
import ExerciseMaster from "../../container/AdminRole/Exercise-master/exercise-master";
import faqList from "../../container/AdminRole/faq/faqList";
import FeedbackMaster from "../../container/AdminRole/Feedback-master/feedback-master";
import GlobalSetting from "../../container/AdminRole/global-setting/global-setting";
import InactiveUserList from "../../container/AdminRole/InactiveUserList/InactiveUserList";
import interest from "../../container/AdminRole/interest/interest";
import LetItAllOut from "../../container/AdminRole/LetItAllOut/LetItAllOut";
import addMood from "../../container/AdminRole/mood/addMood";
import myProfile from "../../container/AdminRole/myProfile/myProfile";
import permission from "../../container/AdminRole/Permission/permission";
import Policy from "../../container/AdminRole/policy/policy";
import Redeem from "../../container/AdminRole/Redeem-master/redeem";
import rewardsMaster from "../../container/AdminRole/Rewardsmaster/rewardsMaster";
import skill from "../../container/AdminRole/skill/skill";
// import socialMedia from "../../container/AdminRole/socialMedia/socialMedia";
import SoundMaster from "../../container/AdminRole/Soundmaster/soundmaster";

import Terms from "../../container/AdminRole/Terms/terms";
import testManagement from "../../container/AdminRole/testManagement/testManagement";
import userFeedback from "../../container/AdminRole/userFeedback/userFeedback";
import deleteUserlist from "../../container/AdminRole/userManagement/deleteUserlist";
import userManagement from "../../container/AdminRole/userManagement/userManagementList";
import userProfile from "../../container/AdminRole/userManagement/userProfile";
import withAdminLayout from "../../container/AdminRole/layout/withAdminLayout";
import NotFound from "../../container/pages/NotFound";
import { getItem } from "../../utility/localStorageControl";
import questionGroup from "../../container/AdminRole/questionGroupType/questionGroup";
import Activity from "../../container/AdminRole/ContentManagment/Activity/Activity";
import nonActivity from "../../container/AdminRole/ContentManagment/nonActivity/nonActivity";
import userRedeem from "../../container/AdminRole/userRedeem/userRedeem";
import competitiveExam from "../../container/AdminRole/competitiveExam/competitiveExam";

const userDetails = getItem("userDetails");

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}`} component={Dashboard} />
        <Route
          path={`${path}/adminrolemanagement/:role_id`}
          component={AdminDetails}
        />
        <Route path={`${path}/aboutus`} component={AboutUs} />

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.users != "none" && (
            <Route
              exact
              path={`${path}/usermanagment`}
              component={userManagement}
            />
          )}
        <Route
          exact
          path={`${path}/usermanagment/:user_id`}
          component={userProfile}
        />

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.termsandservice != "none" && (
            <Route path={`${path}/terms`} component={Terms} />
          )}

        <Route path={`${path}/blocked_users`} component={BlockedUsers} />

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.redeemMaster != "none" && (
            <Route path={`${path}/redeem`} component={Redeem} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.userFeedback != "none" && (
            <Route path={`${path}/userfeedback`} component={userFeedback} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.globalSetting != "none" && (
            <Route path={`${path}/globalsetting`} component={GlobalSetting} />
          )}
        <Route path={`${path}/deleteuserlist`} component={deleteUserlist} />

        <Route path={`${path}/permission/:role_id`} component={permission} />
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.faq != "none" && (
            <Route path={`${path}/faqlist`} component={faqList} />
          )}

        <Route exact path={`${path}/inactiveuserlist`} component={InactiveUserList} />

        <Route exact path={`${path}/inactiveuserlist/:user_id`} component={userProfile} />

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.users != "none" && (
            <Route
              exact
              path={`${path}/usermanagment`}
              component={userManagement}
            />
          )}
        <Route
          exact
          path={`${path}/usermanagment/:user_id`}
          component={userProfile}
        />
        <Route path={`${path}/myprofile`} component={myProfile} />

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.feedbackMaster != "none" && (
            <Route path={`${path}/feedbackmaster`} component={FeedbackMaster} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.questionMaster != "none" && (
            <Route path={`${path}/testmanagement`} component={testManagement} />
          )}
        <Route path={`${path}/competitiveexam`} component={competitiveExam} />
        <Route path={`${path}/questiongroup`} component={questionGroup} />
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.broadcast != "none" && (
            <Route path={`${path}/broadcast`} component={Broadcast} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.activity != "none" && (
            <Route path={`${path}/myactivity`} component={myActivity} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.moods != "none" && (
            <Route path={`${path}/mood`} component={addMood} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.rewardsManagement != "none" && (
            <Route path={`${path}/rewardsmaster`} component={rewardsMaster} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.skills != "none" && (
            <Route path={`${path}/skill`} component={skill} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.interests != "none" && (
            <Route path={`${path}/interest`} component={interest} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.exercise != "none" && (
            <Route path={`${path}/exercisemaster`} component={ExerciseMaster} />
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.policy != "none" && (
            <Route path={`${path}/policy`} component={Policy} />
          )}
        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.letitallout != "none" && (
            <Route path={`${path}/letitallout`} component={LetItAllOut} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.soundMaster != "none" && (
            <Route path={`${path}/soundmaster`} component={SoundMaster} />
          )}

        {userDetails &&
          userDetails.permission &&
          userDetails.permission.resource.contentManagement != "none" && (
            <>
              <Route path={`${path}/activity`} component={Activity} />
              <Route path={`${path}/nonactivity`} component={nonActivity} />
            </>
          )}

        <Route path={`${path}/userredeem`} component={userRedeem} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
