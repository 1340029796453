const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import actions from "./actions";

const {
  userListBegin,
  usersListSuccessList,
  userListErr,
  blockedUsersList,
} = actions;

export const AllUsersListData = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      queryString += value + "=" + params[value] + "&";
    });

    const resp = await DataService.get(
      API.admin.getAdminAndUserByActiveList + queryString
    );

    // const resp = await DataService.get(
    // API.admin.getAdminAndUserByActiveList + "?" + payload
    // );
    if (!resp.data.error) {
      dispatch(usersListSuccessList(resp.data.data));
      return resp.data.data;
    } else {
      return message.error(resp.data.message);
    }
  };
};
export const AllUsersList = (payload) => {
  return async (dispatch) => {
    dispatch(userListBegin());
    const resp = await DataService.post(
      API.admin.getAdminAndUserByStatus,
      payload
    );
    if (!resp.data.error) {
      dispatch(usersListSuccess(resp.data.data));
      return resp.data.data;
    } else {
      dispatch(userListErr());
      message.error(resp.data.message);
    }
  };
};

export const blockedUsers = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.admin.getAdminAndUserByStatus,
      payload
    );
    if (!resp.data.error) {
      dispatch(blockedUsersList(resp.data.data));
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const getUsersById = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.admin.UserGetById, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const updateUserStatus = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.admin.UpdateUserStatus + "/" + id,
      payload
    );
    if (!resp.data.error) {
      window.location.reload();
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const DeletedUserList = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.admin.DeletedUsersList, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const deleteById = (id) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.admin.deleteUserById + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
