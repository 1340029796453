const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const skillget = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.skill.skilllist);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const createSkill = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.skill.skilladd, payload);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const skillDelete = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.skill.skilldelete + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const updateskill = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.skill.skillupdate + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
