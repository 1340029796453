import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Form, Input } from "antd";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { RedeemList, UpdateRedeem } from "../../../redux/redeem/actionCreator";

import { format } from "date-fns";

const { API } = require("../../../config/api/index");
const Redeem = () => {
  const dispatch = useDispatch();
  const [RedeemData, setRedeemData] = useState({});
  const [RedeemId, setRedeemId] = useState({});
  const [form] = Form.useForm();
  useEffect(() => {
    UpdateRedeemList();
  }, []);

  const UpdateRedeemList = async () => {
    const List = await dispatch(RedeemList());
    if (List) {
      form.setFieldsValue(List);
      setRedeemData(List);
      setRedeemId(List._id);
    }
  };

  const RedeemSubmit = useCallback((value) => {
    value.id = RedeemId;
    dispatch(UpdateRedeem(value));
  });

  return (
    <>
      <PageHeader ghost title="" />
      <Main>
        <Cards title="Redeem / Scratch Setting">
          <Form form={form} layout="vertical" onFinish={RedeemSubmit}>
            <Row gutter={10}>
              <Col xxl={6} lg={6} md={6} xs={24}>
                <Form.Item label="Points Limit" name="points_limit">
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={6} lg={6} md={6} xs={24}>
                <Form.Item name="scratch_card_start" label="Scratch Card Start">
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={6} lg={6} md={6} xs={24}>
                <Form.Item
                  name="max_amount_for_first_scratch_card"
                  label="Max Amount"
                >
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>
              <Col xxl={6} lg={6} md={6} xs={24}>
                <Form.Item
                  name="min_amount_for_first_scratch_card"
                  label="Min Amount"
                >
                  <Input type="number" placeholder="" />
                </Form.Item>
              </Col>

              <Col xxl={12} lg={12} md={12} xs={24}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Cards>
      </Main>
    </>
  );
};

export default Redeem;
