import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Input,
  Card,
  Popconfirm,
  Image,
  Modal,
  Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { truncate } from "../../../utility/commonFunction";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import { LetItAllOutList } from "../../../redux/LetItAllOut/actionCreator";
import { format } from "date-fns";

const { API } = require("../../../config/api/index");

const LetItAllOut = () => {
  const dispatch = useDispatch();
  const [LetItAllOutListTable, setLetItAllOutListTable] = useState([]);

  useEffect(() => {
    AllLetLists();
  }, []);

  const AllLetLists = async (value) => {
    const List = await dispatch(LetItAllOutList());
    if (List) {
      setLetItAllOutListTable(List);
    }
  };

  const columns = [
    {
      title: "User",
      dataIndex: "username",
      render: (key, value) => (
        <>
          <span>
            {value && value.userId !== null ? value.userId.username : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Talk back",
      dataIndex: "isTalk",
      // key: "isTalk",
      render: (key, value) => (
        <>
          <span>{value && value.isTalk ? "Yes" : "No"}</span>
        </>
      ),
    },
    {
      title: "Mood Type",
      dataIndex: "moodtyee",
      // key: "username",

      render: (key, value) => (
        <>
          <span>
            {value && value.moodId !== null ? value.moodId.mood_type : "-"}
          </span>
        </>
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      // key: "username",

      render: (key, value) => (
        <>
          <span>{value && value.message !== null ? value.message : "-"}</span>
        </>
      ),
    },
    {
      title: "Audio",
      dataIndex: "audio",
      // key: "username",

      render: (key, value) => (
        <>
          <div>
            {value && value.audio !== undefined ? (
              <ReactAudioPlayer src={value.audio} controls />
            ) : (
              "-"
            )}
          </div>
        </>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (key, value) => (
        <>
          <div>
            {value && value.image !== undefined ? (
              <Image width={50} crossOrigin="anonymous" src={value.image} />
            ) : (
              <Avatar size="large" crossOrigin="anonymous" />
            )}
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <PageHeader ghost title="Let It All Out" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Card>
              <div className="table-order table-responsive">
                <Table dataSource={LetItAllOutListTable} columns={columns} />
              </div>
            </Card>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default LetItAllOut;
