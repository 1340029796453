import React, { useState, useEffect } from "react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { List, Update } from "../../../redux/userRedeem/actionCreator";
import { useDispatch } from "react-redux";
import { Row, Col, Select, Input, Table, Modal, Button, Form } from "antd";
import { Main } from "../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import FeatherIcon from "feather-icons-react";
import { getItem } from "../../../utility/localStorageControl";
///main function call
const userRedeem = () => {
  //states define
  let userdetails = getItem("userDetails");
  const dispatch = useDispatch();
  const [userTableList, setUserTableList] = useState([]);
  const [form] = Form.useForm();
  const [RedeemID, setRedeemID] = useState();
  const [UserId, setUserId] = useState();
  const [visible, setVisible] = useState(false);

  //useEffect Table data show
  useEffect(() => {
    UserList();
  }, []);

  //table api call
  const UserList = async () => {
    const TableList = await dispatch(List());
    if (TableList) {
      setUserTableList(TableList);
    }
  };

  //open popup det api and data
  const update = async (e) => {
    setRedeemID(e._id);
    setUserId(e.userId);
    setVisible(true);
    form.setFieldsValue({
      upi_id: e.upi_id,
      is_redeem_verified: e.is_redeem_verified == false ? "false" : "true",
    });
  };

  //Submit Form
  const submit = async (values) => {
    form.resetFields();
    setVisible(false);
    const data = {
      redeemId: RedeemID,
      is_redeem_verified: values.is_redeem_verified,
      userId: UserId,
    };
    const UpdateUser = await dispatch(Update(data));
    if (UpdateUser) {
      UserList();
    }
  };

  //table column define
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      render(key, value) {
        return (
          <>
            <span>{value && value.userId && value.userId.email}</span>
          </>
        );
      },
    },
    {
      title: "Upi Name",
      dataIndex: "upi_id",
      key: "upi_id",
    },
    {
      title: "Redeemable Balance",
      dataIndex: "redeemable_balance",
      key: "redeemable_balance",
    },
    {
      title: "Status",
      key: "is_redeem_verified",
      dataIndex: "is_redeem_verified",
      render(key, value) {
        return (
          <>
            <span>{value.is_redeem_verified == false ? "false" : "true"}</span>
          </>
        );
      },
    },
    userdetails?.permission.resource.userRedeemHistory == "view_all"
      ? {}
      : {
        title: "Action",
        key: "action",
        render: (key) => (
          <div className="table-actions">
            <>
              <a
                className="btn-icon"
                to="#"
                shape="circle"
                onClick={() => update(key)}
              >
                <FeatherIcon icon="edit" size={16} />
              </a>
            </>
          </div>
        ),
      },
  ];

  //main function return
  return (
    <>
      {/* edit popup */}
      <Modal
        title="Update Content"
        centered
        visible={visible}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        footer={false}
      >
        {/* edit form */}
        <Form
          onFinish={submit}
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Row gutter={25}>
            <Col xl={24} lg={24} xs={24}>
              <Form.Item
                name="upi_id"
                label="upi_id"
                hasFeedback
                rules={[
                  { required: true, message: "Please select your Status!" },
                ]}
              >
                <Input placeholder="Please select your Status!" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={25}>
            <Col xl={24} lg={24} xs={24}>
              <Form.Item
                name="is_redeem_verified"
                label="Status Select"
                hasFeedback
                rules={[
                  { required: true, message: "Please select your Status!" },
                ]}
              >
                <Select placeholder="Please select a Status">
                  <Option value="true">true</Option>
                  <Option value="false">false</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={25}>
            <Col xl={24} lg={24} xs={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <PageHeader ghost title="User Redeem" />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            <Cards title=" User Redeem List">
              <div className="table-order table-responsive">
                <Table
                  dataSource={userTableList}
                  columns={columns}
                  pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "50", "100", "500", "1000"],
                  }}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default userRedeem;
