import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Modal,
  Button,
  Avatar,
  Input,
  message,
  Popconfirm,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { CloseCircleFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import useStateRef from "react-usestateref";
import {
  Deletrmood,
  Moodlist,
  Addmoods,
  Updatemood,
} from "../../../redux/addMood/actionCreator";
import { CompactPicker } from "react-color";
import { getItem } from "../../../utility/localStorageControl";
const addMood = () => {
  let userdetails = getItem("userDetails");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disabled, setDisable] = useState(true);

  const [stateColor, setStatecolor] = useState(false);
  const [deleteIconClick, setDeleteIconClick] = useState(false);
  const [addMoodstore, setAddmoodstore] = useState({});
  const [moodstoreList, setMoodstoreList] = useState([]);
  const [previewMedia, setPreviewMedia] = useState("");
  const [
    selectedImageFile,
    setSelectedImageFile,
    setSelectedImageFileRef,
  ] = useStateRef("");

  let [editMoodType, setEditMoodtype] = useState();
  let [editColortype, setEditColortype] = useState();
  let [editImage, seteditImage] = useState();
  let [editurl, setediteurl] = useState();
  let [editid, seteditid] = useState();

  useEffect(() => {
    moodsLists();
  }, []);

  const showModal = (value) => {
    seteditid(value._id);
    // form1.setFields([{ mood_type: value.mood_type }]);
    setEditMoodtype(value.mood_type);
    setEditColortype(value.mood_color);

    seteditImage(
      <img
        style={{ width: "100px" }}
        crossOrigin="anonymous"
        src={value.mood_image}
      />
    );

    setIsModalVisible(true);
  };

  const handleChangeComplete = (color) => {
    setEditColortype(color.hex);
  };

  const handelsetColor = (color) => {
    setStatecolor(color.hex);
  };

  const handleOk = async (e) => {
    var form_data = new FormData();
    if (setSelectedImageFileRef.current != "") {
      form_data.append("mood_image", setSelectedImageFileRef.current);
    }

    form_data.append("mood_type", editMoodType);
    form_data.append("mood_color", editColortype);
    const createurl = await dispatch(Updatemood(editid, form_data));
    if (createurl) {
      moodsLists();
    }
    // var form_data = new FormData();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeHandler = (event) => {
    if (addMoodstore && addMoodstore !== "") {
      setDisable(false);
    }
    if (event.target.files[0].type.includes("image")) {
      setPreviewMedia(
        <img
          style={{ width: "100px" }}
          src={URL.createObjectURL(event.target.files[0])}
        />
      );
      setSelectedImageFile(event.target.files[0]);
      setDeleteIconClick(false);
    } else {
      form.setFieldsValue({ content: "" });
      message.error("You can only upload IMAGE/GIF file!");
    }
  };
  //   mood list api

  const moodsLists = async () => {
    const List = await dispatch(Moodlist());
    if (List) {
      setMoodstoreList(List.reverse());
    }
  };

  const onChangemood = (value) => {
    setAddmoodstore(value);
  };
  // add mood api
  const onSubmit = async (values) => {
    if (values.mood_image == undefined || values.mood_image == "undefined") {
      delete values.mood_image;
    }

    var form_data = new FormData();

    // if (setSelectedImageFileRef.current != "") {
    form_data.append("mood_image", setSelectedImageFileRef.current);
    form_data.append("mood_color", stateColor);
    for (var key in values) {
      form_data.append(key, values[key]);
    }

    const createurl = await dispatch(Addmoods(form_data));
    if (createurl) {
      setPreviewMedia("");
      setStatecolor("");
      moodsLists();
      form.resetFields();
    } else {
      message.error(`${addMoodstore} Already exists please try another one`);
    }
    setAddmoodstore("");
    setDisable(true);
  };

  // Deletelist api
  const Deleteactivityurl = async (id) => {
    const deleted = await dispatch(Deletrmood(id));
    if (deleted) {
      moodsLists();
    }
  };
  const editImgChange = (event) => {
    if (event.target.files[0].type.includes("image")) {
      setSelectedImageFile(event.target.files[0]);
      setediteurl(URL.createObjectURL(event.target.files[0]));
      seteditImage(
        <img
          style={{ width: "100px" }}
          src={URL.createObjectURL(event.target.files[0])}
        />
      );
    } else {
      message.error("You can only upload IMAGE/GIF file!");
    }
  };
  const columns = [
    {
      title: "Mood Type",
      dataIndex: "mood_type",
      key: "mood_type",
      width: "50%",
    },
    {
      title: "Color",
      dataIndex: "mood_color",
      key: "mood_color",
    },
    {
      title: "Mood Images",
      dataIndex: "mood_image",
      key: "mood_image",
      render: (avtar, record) => {
        if (record.mood_image) {
          return (
            <Avatar
              crossOrigin="anonymous"
              size="large"
              src={record.mood_image}
            />
          );
        }
      },
    },
    userdetails?.permission.resource.moods == "view_all"
      ? {}
      : {
        title: "Action",
        key: "action",
        render: (text) => (
          <div className="table-actions">
            <>
              <a
                className="btn-icon"
                to="#"
                shape="circle"
                onClick={() => showModal(text)}
              >
                <FeatherIcon icon="edit" size={16} />
              </a>

              <Popconfirm
                outlined
                type="warning"
                title="Are you sure to delete this List ?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => Deleteactivityurl(text._id)}
              >
                <a href="#">
                  {" "}
                  <FeatherIcon size={14} icon="trash-2" />
                </a>
              </Popconfirm>
            </>
          </div>
        ),
      },
  ];

  return (
    <>
      <PageHeader ghost title="Notifications" />
      <Main>
        <Row gutter={25}>
          {userdetails?.permission.resource.moods == "view_all" ? (
            ""
          ) : (
            <Col xxl={24} lg={24} xs={24}>
              <Cards title="Add My Mood List">
                <Form onFinish={onSubmit} onOk={form.submit} form={form}>
                  <Row gutter={25}>
                    <Col xxl={8} lg={8} xs={24}>
                      <div className="addMood-lb">
                        <label>Select Your Mood</label>
                      </div>
                      <Form.Item
                        name="mood_type"
                        initialValue="Choose Your Mood"
                      >
                        <Select
                          style={{ width: "100%" }}
                          onChange={onChangemood}
                        >
                          <Select.Option value="Motivated">
                            Motivated
                          </Select.Option>
                          <Select.Option value="Panicked">
                            Panicked
                          </Select.Option>
                          <Select.Option value="Lazy">Lazy</Select.Option>
                          <Select.Option value="Stress">Stress</Select.Option>
                          <Select.Option value="Tired">Tired</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xxl={8} lg={8} xs={24}>
                      <div className="addMood-lb">
                        <label>Upload Image</label>
                      </div>
                      <Form.Item name="mood_image">
                        <Input
                          type="file"
                          placeholder="Image"
                          title=""
                          accept="image/*"
                          onChange={changeHandler}
                        />

                        <div>
                          {deleteIconClick == false && previewMedia}
                          {deleteIconClick == false && previewMedia ? (
                            <span>
                              <CloseCircleFilled
                                onClick={() => setDeleteIconClick(true)}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xxl={8} lg={8} xs={24}>
                      <div className="addMood-lb">
                        <label>Select Your Color</label>
                      </div>
                      <CompactPicker
                        name="mood_color"
                        color={stateColor}
                        onChangeComplete={handelsetColor}
                      />
                    </Col>

                    <Col xxl={24} lg={24} xs={24}>
                      <Button
                        htmlType="submit"
                        size="default"
                        type="primary"
                        key="submit"
                        disabled={disabled}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>

                  <br />
                  <br />
                </Form>
              </Cards>
            </Col>
          )}

          <Col xxl={24} lg={24} xs={24}>
            <Cards title="Mood List">
              <div className="table-order table-responsive">
                <Table
                  dataSource={moodstoreList}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Cards>
          </Col>
        </Row>
        <Modal
          title="Edit Mood Type"
          visible={isModalVisible}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleOk(moodstoreList._id)}
            >
              Submit
            </Button>,
          ]}
        >
          <Form form={form1}>
            <Form.Item name="mood_type">
              <span style={{ display: "none" }}> {editMoodType}</span>
              <Select
                style={{ width: "100%" }}
                value={editMoodType}
                onChange={(value) => setEditMoodtype(value)}
              >
                <Select.Option value="Motivated">Motivated</Select.Option>
                <Select.Option value="Panicked">Panicked</Select.Option>
                <Select.Option value="Lazy">Lazy</Select.Option>
                <Select.Option value="Stress">Stress</Select.Option>
                <Select.Option value="Tired">Tired</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="mood_image">
              <CompactPicker
                name="mood_color"
                color={editColortype}
                onChangeComplete={handleChangeComplete}
              />
              <Input
                type="file"
                placeholder="Image"
                title=""
                accept="image/*"
                onChange={editImgChange}
              />

              {editImage ? editImage : null}

              <div>
                {deleteIconClick == false && previewMedia}
                {deleteIconClick == false && previewMedia ? (
                  <span>
                    <CloseCircleFilled
                      onClick={() => setDeleteIconClick(true)}
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>
              <br />
            </Form.Item>
          </Form>
        </Modal>
      </Main>
      ;
    </>
  );
};

export default addMood;
