const actions = {
  USER_LIST_BEGIN: "USER_LIST_BEGIN",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_ERROR: "USER_LIST_ERROR",
  BLOCKED_USER_LIST_SUCCESS: "BLOCKED_USER_LIST_SUCCESS",
  ACTIVE_USER_LIST_SUCCESS: "ACTIVE_USER_LIST_SUCCESS",

  userListBegin: () => {
    return {
      type: actions.USER_LIST_BEGIN,
    };
  },
  usersListSuccess: (data) => {
    return {
      type: actions.USER_LIST_SUCCESS,
      data,
    };
  },
  usersListSuccessList: (data) => {
    return {
      type: actions.ACTIVE_USER_LIST_SUCCESS,
      data,
    };
  },

  userListErr: (err) => {
    return {
      type: actions.USER_LIST_ERROR,
      err,
    };
  },

  blockedUsersList: (data) => {
    return {
      type: actions.BLOCKED_USER_LIST_SUCCESS,
      data,
    };
  },
};

export default actions;
