import React, { useState, useEffect } from "react";
import { Row, Col, Table, Select, Form, message, Popconfirm } from "antd";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { Button } from "antd";
import { getItem } from "../../../utility/localStorageControl";
// import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Activitylist,
  Deleteactivity,
  AddActivity,
} from "../../../redux/myActivity/actionCreator";
const myActivity = () => {
  let userdetails = getItem("userDetails");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [activityStore, setActivityStore] = useState([]);

  // add Url api
  const [addactivity, setActivity] = useState({
    addactivity: "",
  });

  //  list api
  useEffect(() => {
    myactiveList();
  }, []);

  const onChangeAddactivity = (value) => {
    setActivity(value);
  };

  const onSubmit = async (value) => {
    const createurl = await dispatch(AddActivity(value));
    if (createurl) {
      myactiveList();
    } else {
      message.error(
        `${addactivity} is already exists please try another one!!`
      );
    }
    setActivity("");
    form.resetFields();
  };

  const myactiveList = async () => {
    const List = await dispatch(Activitylist());
    if (List) {
      setActivityStore(List);
    }
  };

  // Deletelist api
  const Deleteactivityurl = async (id) => {
    const deleted = await dispatch(Deleteactivity(id));
    if (deleted) {
      myactiveList();
    }
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "80%",
    },
    // userdetails?.permission.resource.activity == "view_all"
    //   ? {}
    //   : {
    //       title: "Action",
    //       key: "action",
    //       render: (text, record) => (
    //         <div className="table-actions">
    //           <>
    //             <Popconfirm
    //               outlined
    //               type="warning"
    //               title="Are you sure to delete this Activity List ?"
    //               okText="Yes"
    //               cancelText="No"
    //               onConfirm={() => Deleteactivityurl(text._id)}
    //             >
    //               <a href="#">
    //                 {" "}
    //                 <FeatherIcon size={14} icon="trash-2" />
    //               </a>
    //             </Popconfirm>
    //           </>
    //         </div>
    //       ),
    //     },
    ,
  ];

  return (
    <>
      <PageHeader />
      <Main>
        <Row gutter={25}>
          <Col xxl={24} lg={24} xs={24}>
            {userdetails?.permission.resource.activity == "view_all" ? (
              ""
            ) : (
              <>
                <Cards title="Add My Activity List">
                  <Form onFinish={onSubmit} onOk={form.submit} form={form}>
                    <Form.Item name="type" initialValue="Select My Activity ">
                      <Select
                        style={{ width: "100%" }}
                        onChange={onChangeAddactivity}
                      >
                        <Select.Option value="Exercise">Exercise</Select.Option>
                        <Select.Option value="Mindfulness">
                          Mindfulness
                        </Select.Option>
                        <Select.Option value="Sleep">Sleep</Select.Option>
                        <Select.Option value="Tick tok">Tick tok</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        size="default"
                        type="primary"
                        key="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Cards>
              </>
            )}
          </Col>

          <Col xxl={24} lg={24} xs={24}>
            <Cards title="My Activity List">
              <div className="table-order table-responsive">
                <Table
                  dataSource={activityStore}
                  pagination={false}
                  columns={columns}
                />
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default myActivity;
