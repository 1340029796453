const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const LetItAllOutList = () => {
  return async (dispatch) => {
    const resp = await DataService.get(API.LetItAllOut.LetItList);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
