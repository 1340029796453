const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import { message } from "antd";

export const adminManagementRoleList = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.AdminRoleManagement.RoleList,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const Registration = (payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.AdminRoleManagement.Registration,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const DeleteAdmin = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.AdminRoleManagement.Delete + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const updateById = (id, payload) => {
  return async (dispatch) => {
    const resp = await DataService.post(
      API.AdminRoleManagement.UpdateById + "/" + id,
      payload
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
