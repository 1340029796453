

const action = {


    type: 'nonActivity',


    updateUser: data => {
        return {
            type: action.type,
            data,
        };
    },
}

export default action;
